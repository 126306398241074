import React from 'react'
import {FormattedMessage} from 'react-intl'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Languages from './Languages'

const styles = {
  appBar: {
    justifyContent: 'space-between',
    paddingRight: 70,
    paddingLeft: 0,
  },
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
    fontWeight: 300,
    textTransform: 'uppercase',
    color: 'white',
    marginLeft: 20,
  },
}

const Header = ({name, error, language, allowedLanguages, variant, classes, theme}) => {
  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{backgroundColor: variant === 'danger' ? theme.palette.danger : 'none'}}
      >
        <Toolbar classes={{root: classes.appBar}}>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            {name && <FormattedMessage id={`${name}.gameName`} />}
            {error && <FormattedMessage id={`errors.${error}`} />}
          </Typography>
          {allowedLanguages &&
            <Languages
              name={name}
              allowedLanguages={allowedLanguages}
              language={language}
            />
          }
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default withStyles(styles, {withTheme: true})(Header)