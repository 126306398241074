import blueGrey from '@material-ui/core/colors/blueGrey'
import green from '@material-ui/core/colors/green'
import brown from '@material-ui/core/colors/brown'
import amber from '@material-ui/core/colors/amber'
import cyan from '@material-ui/core/colors/cyan'
import red from '@material-ui/core/colors/red'
import grey from '@material-ui/core/colors/grey'

const theme = {
  palette: {
    notFound: {
      light: blueGrey[300],
      main: blueGrey[500],
      dark: blueGrey[700],
      header: '#000',
    },
    primary: {
      light: cyan[100],
      main: cyan[500],
      dark: cyan[700],
      contrastText: 'white',
    },
    secondary: {
      light: red[100],
      main: red[400],
      dark: red[600],
      contrastText: 'white',
    },
    exit: red[900],
    danger: red[700],
    success: green[600],
    warning: amber[500],
    warningText: brown[500],
    genericButton: green[500],
    table: {
      headBackground: 'rgb(200, 163, 61)',
      tableBackground: 'rgb(246, 228, 178)',
      tableRowBorder: 'rgb(200, 163, 61)',
    }
  },
  typography: {
    useNextVariants: true,
    fontWeightMedium: 500,
    h4: {
      fontSize: '1rem',
      fontWeight: 'bold',
      marginBottom: 10,
      marginTop: 30,
      color: red[900],
    },
    body1: {
      color: grey[900],
    }
  },
}

export default theme
