import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const styles = {
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: 'rgb(33, 35, 42)',
    boxShadow: 'inset 0 0 100px rgba(0, 0, 0, .8)',
  },
  primary: {
    fontWeight: 300,
    color: 'white',
    textShadow: '0 2px 4px rgba(0, 0, 0, .5)',
  },
  secondary: {
    fontWeight: 300,
    color: 'rgba(255,255,255,0.6)',
    textShadow: '0 2px 4px rgba(0, 0, 0, .5)',
  },
}

const ErrorHeader = ({primary, secondary, classes}) => {
  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.primary}>
        {primary}
      </Typography>
      <Typography variant="caption" className={classes.secondary}>
        {secondary}
      </Typography>
    </div>
  )
}

export default withStyles(styles)(ErrorHeader)