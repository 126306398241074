import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {withStyles} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

const styles = theme => ({
  button: {
    backgroundColor: theme.palette.primary.dark,
    color: 'white',
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
})

const languages = {
  en: 'English',
  es: 'Español',
  pt: 'Portugês',
  vi: 'Tiếng Việt',
}

class Footer extends Component {
  state = {
    anchorEl: null,
  }

  handleClick = event => {
    this.setState({anchorEl: event.currentTarget})
  }

  handleClose = () => {
    this.setState({anchorEl: null})
  }
  render() {
    const {anchorEl} = this.state
    const {
      classes,
      language,
      allowedLanguages,
      name
    } = this.props

    return (
      <div>
        <Button
          variant="contained"
          className={classes.button}
          onClick={this.handleClick}>
          {languages[language]}
          <ArrowDownIcon className={classes.rightIcon} />
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}>
          {allowedLanguages.map(x => {
            return (
              <MenuItem key={x} component={Link} to={`/${x}/${name}`} onClick={this.handleClose}>
                {languages[x]}
              </MenuItem>
            )
          })}
        </Menu>
      </div>
    )
  }
}

export default withStyles(styles)(Footer)
