export const EN = 'en'
export const ES = 'es'
export const PT = 'pt'
export const VI = 'vi'
export const BACCARAT = 'baccarat'
export const BLACKJACK = 'blackjack'
export const CASINO_WAR = 'casinoWar'
export const CRAPS = 'craps'
export const CRAZY_POKER = 'crazyPoker'
export const HOOHEYHOW = 'hooHeyHow'
export const KEEP_EM_POKER = 'keepEmPoker'
export const KENO = 'keno'
export const PAIGOW = 'paigow'
export const POKER_THREE = 'pokerThree'
export const RED_DOG = 'redDog'
export const ROULETTE = 'roulette'
export const SIC_BO = 'sicBo'
export const SLOTS = 'slots'
export const SLOT_1_LINE_MAGIC = 'slotOneLineMagic'
export const STUD = 'stud'
export const VIDEOPOKER = 'videoPoker'
export const ZIPTAB = 'zipTabs'
export const SPORTBOOKS = 'sportbooks'
export const SWEEPSTAKES = 'sweepstakes'
export const FLASH = 'flash'
export const H5 = 'h5'
