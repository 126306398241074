import React from 'react'
import { Link } from 'react-router-dom'
import { addLocaleData, IntlProvider, FormattedMessage } from 'react-intl'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import GridListTileBar from '@material-ui/core/GridListTileBar'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { flattenMessages, getParameterByName } from '../utils'
import es from 'react-intl/locale-data/es'
import en from 'react-intl/locale-data/en'
import pt from 'react-intl/locale-data/pt'
import vi from 'react-intl/locale-data/vi'
import messages_sportbooks_en from '../translations/sportbooks.en'
import messages_sportbooks_vi from '../translations/sportbooks.vi'
import messages_sweepstakes_en from '../translations/sweepstakes.en'
import messages_sweepstakes_vi from '../translations/sweepstakes.vi'
import messages_es from '../translations/es'
import messages_pt from '../translations/pt'
import img1 from '../assets/images/home/baccarat.png'
import img3 from '../assets/images/home/blackjack.png'
import img4 from '../assets/images/home/casino-war.png'
import img5 from '../assets/images/home/craps.png'
import img6 from '../assets/images/home/crazy-poker.png'
import img7 from '../assets/images/home/hooheyhow.png'
import img8 from '../assets/images/home/keepem.png'
import img9 from '../assets/images/home/keno.png'
import img10 from '../assets/images/home/paigow.png'
import img11 from '../assets/images/home/poker3.png'
import img12 from '../assets/images/home/reddog.png'
import img13 from '../assets/images/home/roulette.png'
import img14 from '../assets/images/home/scratch.png'
import img15 from '../assets/images/home/sicbo.png'
import img16 from '../assets/images/home/slot-1-line.png'
import img17 from '../assets/images/home/slots.png'
import img18 from '../assets/images/home/stud.png'
import img19 from '../assets/images/home/vp.png'
import {
  BACCARAT,
  BLACKJACK,
  CASINO_WAR,
  CRAPS,
  CRAZY_POKER,
  KENO,
  ROULETTE,
  ZIPTAB,
  SLOTS,
  STUD,
  SLOT_1_LINE_MAGIC,
  EN,
  HOOHEYHOW,
  KEEP_EM_POKER,
  PAIGOW,
  POKER_THREE,
  RED_DOG,
  SIC_BO,
  VIDEOPOKER,
  SPORTBOOKS,
  SWEEPSTAKES,
} from '../constants'

let messages = {
  en: messages_sweepstakes_en,
  es: messages_es,
  pt: messages_pt,
  vi: messages_sweepstakes_vi,
}

addLocaleData([...en, ...es, ...pt, ...vi])

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 1000,
    height: 'auto',
    margin: 'auto',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  paper: {
    padding: 20,
    display: 'flex',
    justifyContent: 'space-around',
  },
  listTitle: {
    textTransform: 'capitalize',
  },
  appBar: {
    justifyContent: 'space-between',
  },
  bar: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
    fontWeight: 300,
    textTransform: 'uppercase',
    color: 'white',
  },
})

const Home = props => {
  const getImage = name => {
    switch (name) {
      case BACCARAT: {
        return img1
      }
      case BLACKJACK: {
        return img3
      }
      case CASINO_WAR: {
        return img4
      }
      case CRAPS: {
        return img5
      }
      case CRAZY_POKER: {
        return img6
      }
      case HOOHEYHOW: {
        return img7
      }
      case KEEP_EM_POKER: {
        return img8
      }
      case KENO: {
        return img9
      }
      case PAIGOW: {
        return img10
      }
      case POKER_THREE: {
        return img11
      }
      case RED_DOG: {
        return img12
      }
      case ROULETTE: {
        return img13
      }
      case SIC_BO: {
        return img15
      }
      case SLOTS: {
        return img17
      }
      case STUD: {
        return img18
      }
      case VIDEOPOKER: {
        return img19
      }
      case ZIPTAB: {
        return img14
      }
      case SLOT_1_LINE_MAGIC: {
        return img16
      }
      default: {
        return null
      }
    }
  }

  const language = EN
  const { allowedGames, classes } = props

  const platform = getParameterByName('platform') || SPORTBOOKS
  const isSweepstakes = platform.toLowerCase() === SWEEPSTAKES
  if (!isSweepstakes) {
    messages = {
      en: messages_sportbooks_en,
      es: messages_es,
      pt: messages_pt,
      vi: messages_sportbooks_vi,
    }
  }

  return (
    <IntlProvider
      locale={language}
      messages={flattenMessages(messages[language])}
    >
      <div>
        <div className={classes.bar}>
          <AppBar position="static">
            <Toolbar className={classes.appBar}>
              <Typography variant="h6" color="inherit" className={classes.flex}>
                Home
              </Typography>
            </Toolbar>
          </AppBar>
        </div>

        <div>
          <Paper square elevation={0} className={classes.paper}>
            <GridList cellHeight={180} className={classes.gridList} cols={4}>
              {allowedGames.map(x => {
                return (
                  <GridListTile
                    key={x}
                    component={Link}
                    to={`/${language}/${x}`}
                  >
                    <img src={getImage(x)} alt={x} />
                    <GridListTileBar
                      title={<FormattedMessage id={`${x}.gameName`} />}
                      className={classes.listTitle}
                    />
                  </GridListTile>
                )
              })}
            </GridList>
          </Paper>
        </div>
      </div>
    </IntlProvider>
  )
}

export default withStyles(styles, { withTheme: true })(Home)
