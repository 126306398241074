export default {
  menu: {
    theGame: 'O jogo',
    theControls: 'Os controls',
    generalInfo: 'Informação geral',
    buttonControl: 'Botão',
    description: 'Descrição',
  },
  baccarat: {
    gameName: 'Baccarat',
    game: {
      introduction: {
        line1:
          "Baccarat é considerado um jogo para 'high–rollers' e geralmente jogado entre portas impossíveis de passar para o jogador regular ou nas áreas restringidas para jogadores VIP nos casinos.",
        line2:
          "Recentemente uma variação chamada 'Mini Baccarat' foi criada para fazer o jogo popular nos Estados Unidos e on-line. Mini Baccarat é jogado numa mesa regular de Blackjack a oferece níveis de apostas mais baixos é mais emocionante que o Bacará regular. Essa é a versão que oferecemos aqui e usa 8 decks.",
        title: 'Introdução',
      },
      basicRules: {
        line1:
          'O objetivo do Bacará é ter uma mão com pontuação total perto de 9. O As tem um valor de 1; cartas de 2 até 9 contam pelo valor da mesma; dez e as demais cartas tem um valor de 0 (veja a tabela ao lado). Se o valor da mão é igual o maior que 10, então subtraia 10 e conte o restante como o valor da mão. Por exemplo, se a mão é 8, 6 terá um valor de Bacará de 4 (8+6=14, 14-10=4); a mão 3,7 terá um valor de Bacará de 0 (3+7=10, 10-10=0).',
        line2:
          'Coloque sua aposta na área do Banqueiro e/ou na área do Jogador e/ou na área do Empate. O jogador e o banqueiro são repartidos dois cartas para cada um. Em alguns casos, uma terceira carta é repartida para o jogador ou para ou banqueiro ou os dois (regras de terceira carta).',
        line3: 'O jogador que esteja mais perto de 9, ganha.',
        line4: 'Se você aposta no Jogador e o Jogador ganha, você recebe o pagamento de 2:1 em sua aposta.',
        line5:
          'Se você aposta no Banqueiro e o Banqueiro ganhar, você recebe o pagamento de 2:1 menos 5% de comissão para o Banco. ( exemplo: ganhar com uma aposta de 10, você recebe de volta seus 10 mais 10 do ganho, menos 5% do Banco (igual a 9.5) baseado no calculo seguinte 10+9.5=19.5).',
        line6:
          'Se ambos a mão do Jogador e a mão do Banqueiro tem um valor total igual, o jogo terá resultado como Empate. Se você apostou no espaço do Empate, seu pago será de 8 por que1. (apostando 10 o ganho é 10 + 80 = 90).',
        title: 'Regras básicas',
        cardTable: {
          card: 'Cartão',
          value: 'Valor',
          faceValue: 'Face Value',
          card1Label: 'As',
          card2Label: 'Cartões 2-9',
          card3Label: '10s',
          card4Label: 'Demais Cartões',
        },
      },
      thirdCardRules: {
        line1:
          'Em alguns casos, depois que duas cartas foram repartidas para o Jogador e o Banqueiro, o dealer reparte uma Terceira Carta para o Jogador e o Banqueiro. As seguintes tabelas mostram quando acontece:',
        line2:
          'Depois que a terceira carta foi repartida, o Banqueiro poderia receber uma terceira carta baseado na seguinte tabela:',
        line3:
          'NOTA: Seja que o Jogador ou O Banqueiro tenham um total de 8 ou 9, então os dois param. Esta regra esta por acima de quaisquer outra.',
        title: 'Regras da Terceira Carta',
        from: 'desde ',
        to: 'até',
        or: 'ou ',
        playerHandTable: {
          value: 'Se o valor da mão do Jogador é:',
          then: 'Então o jogador:',
          playerHand1Then: 'Recebe uma terceira carta.',
          playerHand2Then: 'Para.',
          playerHand3Then: "tem uma mão 'natural' não precisa de mais nada.",
        },
        bankerHandTable: {
          value: 'Valor da mão do Banqueiro:',
          if: 'O Banqueiro recebe se a terceira carta do jogador é:',
          then: 'Banqueiro não recebe si a terceira carta do jogador é:',
          bankerHand1If: 'Quaisquer carta',
          bankerHand6If: 'Para',
          bankerHand7If: 'Mão natural (Para)',
        },
      },
    },
    gameControls: {
      button1Label: 'Jogo novo',
      button1Description: 'Começar um jogo novo.',
      button2Label: 'Limpar apostas',
      button2Description: 'Limpar todas as aposta.',
      button3Label: 'Reapostar',
      button3Description: 'Repetir sua ultima aposta.',
      button4Label: 'Repartir',
      button4Description: 'Diz para o dealer que pode repartir as carta.',
    },
  },
  blackjack: {
    gameName: 'Blackjack',
    game: {
      introduction: {
        line1:
          "A história do Blackjack mesmo é ainda desconhecida, se vem dos jogos franceses de 'chemin de fer' e 'French Ferme. Blackjack começou nos casinos franceses ao redor dos 1700 onde foi chamado 'vingt-et-un'(vinte um) e tem sido jogado nos Estados Unidos desde os anos 1800. Blackjack é chamado assim se um jogador tem um Valete de Espadas a um As de Espadas com as primeiras cartas (A espada sendo preta claro), o jogador é pago de forma adicional. Blackjack também pode ter muitas variações, como quando o jogador pode dobrar, pagamento de um Blackjack, quando o dealer vai bater ou ficar em pé, assim como os outros.",
        title: 'Introdução',
      },
      basicRules: {
        line1:
          'O objetivo do Blackjack é para ter um total na mão de 21 o ficar mais perto de 21 que o dealer sem passar-se de 21.',
        line2:
          'No Blackjack o As conta como 1 ou 11, cartas maiores tem um valor de 10, e as cartas com numero tem o valor do numero da carta.',
        line3:
          'Se você recebe um As e uma carta com um valor de dez, como suas primeiras cartas, você tem Blackjack e ganha uma vez e meia sua aposta (exemplo: se sua aposta foi de 10, você recebe 10 mais 15 para um total de 25). Se o total de suas cartas esta mais perto que 21 que as cartas do dealer, você ganha quanto você apostou (exemplo: se você apostou 10, você recebe 10 mais 10 para um total de 20). Se o total de suas cartas é mais de 21, você passou e perdeu sua aposta. Se você e o dealer tem o mesmo valor nas cartas (17 ou mais) ninguém ganha e sua aposta e devolvida como empate. Blackjack (feito com duas mãos) ganha sobre o valor de 21.',
        title: 'Regras básicas',
      },
      split: {
        line1:
          'Se suas primeiras cartas tem o mesmo valor, você poder as dividir em duas mãos separadas pondo uma segunda aposta que seja igual que sua aposta original.',
        line2:
          'Você então recebe mais cartas. Se você divide sua mão com Ases e uma de suas mãos tem um total de 21, é contado como 21 e não Blackjack. Se dividir ases, apenas uma carta adicional é distribuída. Apenas uma divisão por mão é permitida nestes jogos, sem reclassificação.',
        title: 'Dividir',
      },
      double: {
        line1:
          'Depois de que você recebeu suas cartas e você acha que uma carta de mais poderia lhe ajudar para ganhar a mão do dealer, você pode aumentar sua aposta original como o mesmo montante desta. Sua aposta foi dobrada e você recebe uma carta mais somente. O casino poderia mudar e ter variações de quando você pode dobrar. Exemplo: em quaisquer momento depois de receber as primeiras duas cartas, e o total é entre 8 e 11, ou o total de cartas é 10 ou 11.',
        line2:
          'Por favor, verifique com os parâmetros de informação do jogo quando passar o mouse sobre um ícone do jogo.',
        title: 'Dobro',
      },
      insurance: {
        line1:
          'O seguro é oferecido somente quando a primeira carta do dealer é um As. O seguro é considerado como um jogo dentro do mesmo jogo do Blackjack. Lhe permite tentar proteger sua mão quando você acredite que o dealer tem Blackjack. Quando você usa o seguro, você põe a metade de sua aposta original pressionando o botão de seguro. Se o dealer tem Blackjack, você será pagado 2:1 em seu seguro.',
        line2:
          'Exemplo: se sua aposta é de 10 e 5 de seguro, e o dealer tem BlackJack seja que você empate ou perda contra do dealer, você ganha 5 mais 10 para um total de 15. Se o dealer não tem Blackjack, você perde sua aposta de seguro.',
        title: 'Seguro',
      },
      checkingForBlackJack: {
        line1:
          'O dealer pode verificar a carta que não esta virada se a carta virada é um As. Se a segunda carta do dealer é uma carta maior (Rei, Rainha, Valete) o 10 (Blackjack) a carta será virada, mostrando o valor e o jogo terminara. O dealer ganha. Contudo se a segunda carta do dealer não completa o Blackjack a carta continua sem virar-se e o jogo continua.',
        line2:
          'Se a primeira carta do dealer é um 10 o alguma carta maior, seguro não é oferecido, a segunda carta será verificada é se fosse um As a carta será virada, mostrando o valor e o jogo terminara. O dealer ganha. Contudo se a segunda carta do dealer não completa um Blackjack a carta continua sem virar-se e o jogo continua.',
        title: 'Verificando para o Blackjack',
      },
      variations: {
        line1:
          'Existem muitas variações para o Blackjack e observe o seguinte para os jogos oferecidos para sua diversão aqui:',
        title: 'Variações',
        blackjackSwitch: {
          title: 'Blackjack Switch',
          line1:
            'Paga mesmo recompensas quando o jogador recebe um Blackjack, tem de jogar 2 mãos, a 2ª carta de cada mão pode ser trocada com a outra mão. Se as cartas dos dealers totalizarem 22 e o jogador não estourar, o jogo é um empurrão e o jogador ganha recompensas originais.',
        },
        blackjackDoubleExposure: {
          title: 'Blackjack Double Exposure',
          line1:
            'Os Blackjacks pagam uma quantia igual às recompensas, todas as cartas são distribuídas com a face para cima, o Dealer vai ganhar em todos os empates, exceto se ambas as mãos forem um Blackjack, então será um empate.',
        },
        winningsTable: {
          winnings: 'Ganhos',
          pays: 'Pagamentos',
          win1Label: 'Mão ganhadora',
          win2Label: 'Seguro',
          win3Label: 'Blackjack',
        },
      },
    },
    gameControls: {
      button1Label: 'Jogo novo',
      button1Description: 'Começar um jogo novo.',
      button2Label: 'Limpar apostas',
      button2Description: 'Limpar todas as aposta.',
      button3Label: 'Reapostar',
      button3Description: 'Repetir sua ultima aposta.',
      button4Label: 'Repartir',
      button4Description: 'Diz para o dealer que pode repartir as carta.',
      button5Label: 'Seguro',
      button5Description: 'Ativa o seguro para metade de sua aposta original.',
      button6Label: 'Dividir',
      button6Description: 'Divide sua mão.',
      button7Label: 'Hit',
      button7Description: 'Instrui o dealer para repartir uma carta de mais.',
      button8Label: 'Parar',
      button8Description: 'Diz para o dealer que você não quer mais cartas.',
    },
  },
  casinoWar: {
    gameName: 'Casino War',
    game: {
      introduction: {
        line1:
          'Casino War, também conhecido como Batalha Royal, é um jogo simples baseado no jogo de cartas para crianças, Guerra. O jogador e o dealer recebem cada um uma carta virada. A carta alta ganha e quando houver um empate, o jogador pode render-se ou ir para Guerra pela chance de ganhar.',
        title: 'Introdução',
      },
      howToPlay: {
        line1: 'Casino War usa um baralho de 52 cartas.',
        line2: 'As cartas são classificadas como no Poker, exeito os Ases são sempre altos.',
        line3: 'O naipe não é importante na Casino War.',
        line4:
          "Comece colocando uma aposta na área de apostas na mesa. Apos disso, clique no botão de 'Apostar', o jogador e o dealer terão repartidos uma carta virada cada um. Se a carta do jogador é maior que a carta do dealer, então o jogador ganga 'even money (1:1). Se a carta do dealer é maior que a carta do jogador, o dealer ganha.",
        line5: 'Quando as cartas são iguais, o jogador tem duas opções:',
        title: 'Como Jogar',
      },
      surrender: {
        line1: 'O jogador pode render suas cartas e perde a metade de sua aposta de Ante.',
        title: 'Rendirse',
      },
      goToWar: {
        line1:
          'Se o jogador escolhe ir para guerra, uma aposta adicional é feita igual que a aposta original. O jogador é repartido uma carta de mais virada.',
        line2: "O dealer é repartido três cartas 'Scratch' mais não viradas e uma carta mais virada.",
        line3:
          "Se a segunda carta do jogador é melhor que as cartas do dealer, o jogador é pagado 'even money (1:1)' no aumento somente e na aposta de Ante a aposta é um empate.",
        title: 'Ir para Guerra',
      },
      tiePlays: {
        line1:
          'Uma opção disponível é a aposta de empate, onde o jogador paga 10 para 1 se a primeira carta do jogador e a primeira carta do dealer tem o mesmo valor. Quaisquer outro resultado é uma perda para o jogador.',
        title: 'Aposta de empate',
      },
    },
    gameControls: {
      button1Label: 'Jogo novo',
      button1Description: 'Começar um jogo novo.',
      button2Label: 'Limpar apostas',
      button2Description: 'Limpar todas as aposta.',
      button3Label: 'Reapostar',
      button3Description: 'Repetir sua ultima aposta.',
      button4Label: 'Repartir',
      button4Description: 'Diz para o dealer que pode repartir as carta.',
      button5Label: 'Rendirse',
      button5Description: 'Se rende quando um empate resulta',
      button6Label: 'Guerra',
      button6Description: 'Vai para a guerra quando ocorre um empate.',
    },
  },
  craps: {
    gameName: 'Craps',
    game: {
      introduction: {
        line1: 'Craps, ou jogo de Dados é um jogo de sorte que tem o começo no tempo antigo.',
        title: 'Introdução',
      },
      comeOut: {
        line1:
          'Um jogo novo de Craps sempre começa com o que se chama de Rolada de Come out, que é o primeiro tiro do jogador. O mais comum e básico tipo de aposta no Craps é passar a linha (ou o oposto então não passar a linha).',
        line2:
          "Na rolada de come out, uma aposta de passada de linha ganha somente se o jogador pode obter um 7 ou um 11 (chamado de 'natural') e perde se sua aposta da 2, 3, ou 12 (chamado de 'craps'). Se o jogador tira para 4, 5, 6, 8, 9 ou 10 na rolada de come out, o numero torna-se o ponto de tiro para o jogador, onde o dealer marca na mesa com o 'puck' – uma peça marcada de brando e preto, posta no lado branco marcando o numero do jogador. Agora o novo objetivo para o jogador é rolar/tirar o mesmo numero de novo para ganhar (chamado de passar) antes de tirar o numero 7 (com o qual perde a aposta).",
        line3:
          'A aposta de não passar a linha, e oposta ao passar a linha, 7 e 11 perdem e pode ganhar com 2 ou 3. Um 12 é considerado um empate para as apostas de não passar a linha então a aposta não ganhou nem perdeu. Se o jogador tira um 4, 5, 6, 8, 9, ou 10 agora este é o novo ponto para o jogador de rolar um 7 (para ganhar) antes de tirar para o ponto do numero novamente (com o qual perde sua aposta).',
        title: "Rolada de Come Out / Apostas Pass Line and Don't Pass",
      },
      come: {
        line1:
          "As aposta de Come é praticamente o mesmo tipo que passar a linha. Desta forma a aposta de Don't Come é similar à aposta de não passar a linha. A diferencia é que passar a linha e não passar a linha podem ser feitas somente na rolada de come out, mas Come e Don't Come podem ser feitas até que o ponto tenha sido estabelecido. Se o jogador tira para 4, 5, 6, 8, 9 ou 10 na rolada de come out, a jogada continua mas o jogador não tem a possibilidade de fazer mais aposta de passar a linha ou não passar a linha. Isto é quando apostas de Come e Don't Come podem ser feitas.",
        line2:
          "Uma aposta de Come somente pode ganhar se o jogador tira um 7 ou 11, igual que a rolada de come out para uma aposta de passar a linha. A aposta é perdida se o jogador tira 2.3 ou 12. Quaisquer outro numero faz que a aposta seja removida da área do come grande na mesa para a caixa mais pequena que contem o numero acima da área do come. Uma vez que a aposta foi movida para uma dessas caixas mais pequenas, a aposta ganha quando o jogador tira o mesmo numero de novo e perde se um 7 fosse rolado primeiro. A aposta de Don't come e o oposto, perde com 7 e 11 e ganha com 2 ou 3. Como a aposta de não passar a linha, 12 é considerado um empate e aposta não perdeu nem ganhou. Quaisquer outro numero faz que a aposta seja movida detrás da caixa que contem o numero na área de Don't come. Esta aposta poderá ganhar somente se um 7 é rolado antes que o mesmo numero de novo, e perde se o mesmo numero é rolado novamente antes que o 7.",
        title: "Apostas Come and Don't Come",
      },
      oddsBets: {
        line1:
          "Apostas de odds podem ser feitas somente quando você já fez uma aposta de passar a linha, não passar a linha, come ou don't come e um ponto estabelecido. Uma vez que o ponto foi estabelecido para sua aposta de passar a linha ou aposta de come, você pode pôr uma aposta de odds para o máximo de odds para o numero escolhido (veja embaixo). As apostas de odds ganham ou perdem quando a linha associada (seja passar a linha, não passar, come e don't come) ganham ou perdem. A diferencia é que o jogador é pago com odds reais nas apostas de odds quando ganhar. Por exemplo, odds reais para 4 e 10 são 2:1 (leia 2:1). Suponha que o jogador tem u ponto de 4 estabelecido com uma aposta de $5 para passar a linha e uma aposta de odds de $10 para passar a linha. Se o ponto é feito tirando outro 4, o jogador deve ganhar com 'even money (1:1)' na aposta de passar a linha pero tem odds reais de 2:1 na aposta de odds ($20).",
        point: 'Ponto',
        trueOdds: 'Odds reais',
        and: 'para',
        title: 'Apostas de Odds',
      },
      maximumOdds: {
        line1:
          'Uma aposta de odds máximos pode ser feita quando as apostas de passar a linha e come dependeram do ponto que estejam.',
        line2: "A aposta de odds máximos para não passar a linha e don't come é 6 vezes a base da aposta original.",
        point: 'Point',
        maximumOdds: 'Odds Máximo',
        and: 'e',
        times: 'Vezes',
        title: 'Odds Máximo',
      },
      oddsOnTheDontCome: {
        line1:
          "Quando o jogador quer odds numa aposta de não passar e don't come, e chamado de postura de odds e é oposto a pegar odds para apostas de passar a linha e come. Contudo, como as apostas de don't come e não passar a linha precisam de tirar um 7 antes do ponto, odds reais para não passar e don't come são opostas para as apostas de come e passar a linha. Por exemplo, odds reais para 4 e 10 são 2:1, o que quer dizer que odds para apostas de don't come e não passar pagarão 1:2. Suponha que o jogador tem um ponto de 4 estabelecido com uma aposta de $5 para passar a linha e uma aposta de odds de $10 para não passar. Se o jogador tira um 7 antes de outro 4, o jogador ganha 'even money (1:1)' na aposta de não passar a linha ($5) e ganha 1:2 na aposta de odds ($5).",
        title: "Apostas e OddsDon't Come e Don't Pass",
      },
      placeBets: {
        line1:
          'Apostas de place lhe permitem ao jogador apostar um numero que será tirando antes de 7. Se o 7 é rolado primeiro, a aposta é perdida. É similar que as apostas de come e passar a linha exceto que o numero é escolhido pelo jogador (oposto que o resultado da rolada próxima) e não há chance de ganhar com 7 ou 11 com a próxima rolada, ou poderia perder com 2, 3, ou 12. O único numero onde o jogador pode apostar possíveis pontos são: 4, 5, 6, 8, 9 e 10. Apostas de place são feitas na caixa que esta embaixo da marca com o numero que você quer apostar. Apostas de place devem ser feitas em aumentos de $5 no 4, 5, 9 e 10 e $6 no 6 e 8. A razão é que os odds são de 9:5 para o 4 e 10, 7:5 para o 5 e 9 e 7:6 para o 6 e 8.',
        title: "Apostas Place e Don't Place",
      },
      buyBets: {
        line1:
          'Uma aposta de compra é similar que uma aposta de place. O aposta de compra dize que o numero apostado será tirado antes que o 7. Mas, a aposta de compra oferece odds reais para o jogador em sua aposta, se o jogador paga a comissão de 5%. A comissão é pagada ao mesmo tempo que a aposta é feita. Por exemplo, se o jogador quer fazer uma aposta de compra de $20 no 10, o jogador deve então apostar $20+$1 ($1 = 5% de $20). Se a tirada ganha, o jogador será pagado com odds reais (2:1 para o 10) na aposta de $20 que será então $40. Esta versão de Craps assume que cada aposta de compra inclui o 5% de comissão, o qual é calculado fazendo uma divisão do montante apostado por 1.05 ($21 / 1.5 = $20).',
        title: 'Aposta Buy',
      },
      layBets: {
        line1:
          'Uma aposta de Lay é o oposto de uma aposta de compra. Com uma aposta de lay o jogador espera que um 7 seja tirado antes que o numero que ele apostou. Se o 7 é rolado antes que o numero apostado saia de novo a aposta é ganhada. Se o numero apostado cair de novo antes que o 7, a aposta é perdida. Se quaisquer outro numero é tirado, nada acontece com a aposta do jogador. Mas, como a aposta de compra, uma aposta de lay paga com odds reais e o jogador deve pagar uma comissão de 5% para fazer sua aposta. Uma diferencia é que a comissão é um 5% da montante ganhado pelo jogador se ganhar a aposta. Lembre que odds reais quando aposta contra de algum numero são opostos aos odds reais se aposta para um numero. Por exemplo, assuma que o jogador tem uma aposta de lay de $40 no 10. Se o jogador tira um 7 antes que outro 10; ganha com odds reais (1:2) um total de $20. O 5% de comissão é cobrado no montante ganhado (5% de $20 = $1) então o jogador obtém $19.',
        title: 'Aposta Lay',
      },
      Big6OrBig8: {
        line1:
          "Apostas de Big 6 ou Big são feitas embaixo no lado direito da mesa marcada com letras de Big 6 e Big 8. A aposta trabalha como uma aposta de place no 6 ou 8 exceto que a aposta mínima no big 6 e big 8 é simplesmente o mínimo da mesa, e o big 6 e o big 8 pagam 'even money'. Igual que as apostas de place, esta aposta ganha quando quaisquer das seleções é tirada antes que um 7. Se o 7 é rolado antes, a aposta é perdida.",
        title: 'Apostas Big 6 ou Big 8',
      },
      fieldBet: {
        line1:
          "Esta aposta é feita para a próxima rolada, um dos seguintes 7 números que sejam : 2, 3, 4, 9, 10, 11 ou 12. A aposta é perdida se um 5, 6, 7, ou 8 é tirado. Se a tirada ganhadora é um 2 ou 12 a aposta paga 2:1. Todos os outros números pagam 'even money (1:1)'. A aposta é feita de forma simples, pondo uma quantidade na área marcada de 'Field' entre as linhas de não passar e come.",
        title: 'Aposta Field',
      },
      anySeven: {
        line1:
          'Esta é uma aposta de uma rolada onde o jogador aposta para que um 7 seja tirado na seguinte rolada. Se a próxima rolada é um 7 então o jogador recebe o pago de 4:1 em sua aposta. Se quaisquer outro numero é rolado, a aposta é perdida.',
        title: 'Aposta Any Seven',
      },
      anyCraps: {
        line1:
          'Esta é uma aposta de uma rolada onde o jogador aposta para que um 2, 3, ou 12 seja tirado na seguinte rolada. Se a próxima rolada é um 2, 3, ou 12 então o jogador recebe o pago de 7:1 em sua aposta. Se quaisquer outro numero é rolado, a aposta é perdida.',
        title: 'Aposta Any Craps',
      },
      hornTwelve: {
        line1:
          'Esta é uma aposta de uma rolada onde o jogador aposta para que um 12 seja tirado na seguinte rolada. Se a próxima rolada é um 12 então o jogador recebe um emocionante pago de 30:1 em sua aposta. Se quaisquer outro numero é rolado, a aposta é perdida.',
        title: 'Aposta Horn Twelve',
      },
      hornTwo: {
        line1:
          'Esta é uma aposta de uma rolada onde o jogador aposta para que um 2 seja tirado na seguinte rolada. Se a próxima rolada é um 2 então o jogador recebe um emocionante pago de 30:1 em sua aposta. Se quaisquer outro numero é rolado, a aposta é perdida.',
        title: 'Aposta Horn Two',
      },
      hornEleven: {
        line1:
          'Esta é uma aposta de uma rolada onde o jogador aposta para que um 11 seja tirado na seguinte rolada. Se a próxima rolada é um 11 então o jogador recebe um pago de 15:1 em sua aposta. Se quaisquer outro numero é rolado, a aposta é perdida.',
        title: 'Aposta Horn Eleven',
      },
      hornThree: {
        line1:
          'Esta é uma aposta de uma rolada onde o jogador aposta para que um 3 seja tirado na seguinte rolada. Se a próxima rolada é um 3 então o jogador recebe um pago de 15:1 em sua aposta. Se quaisquer outro numero é rolado, a aposta é perdida.',
        title: 'Aposta Horn Three',
      },
      hardWays: {
        line1:
          'Quando o jogador tira e ambos dados são o mesmo numero, é chamado de hard way. Roladas de 2 e 12 não são consideradas hard way por que o total pode somente ser rolado por uma combinação (dois 1 e dois 6). No caso de outros resultados de apostas de hard way, o total pode ser rolado com outras combinações dos dados. Por exemplo, um hard way de 10 (ou hard 10) é uma combinação de dois 5 rolados nos dados para fazer 10. Mais, 10 pode ser obtido também com 6 e 4.',
        title: 'Aposta Hard Ways',
      },
      hard4: {
        line1:
          'Quando o jogador aposta no hard 4, o jogador espera que um 2 saia nos dois dados antes que quaisquer outra combinação de 4 ou um 7. Se o jogador tira um 1 e 3 ou um 7 a aposta e perdida. O pago para um hard 4 é de 7:1.',
        title: 'Aposta Hard 4',
      },
      hard10: {
        line1:
          'Quando o jogador aposta no hard 10, o jogador espera que um 5 saia nos dois dados antes que quaisquer outra combinação de 10 ou um 7. Se o jogador tira um 6 e 4 ou um 7 a aposta e perdida. O pago para um hard 10 é de 7:1.',
        title: 'Aposta Hard 10',
      },
      hard6: {
        line1:
          'Quando o jogador aposta no hard 6, o jogador espera que um 3 saia nos dois dados antes que quaisquer outra combinação de 6 ou um 7. Se o jogador tira um 1 e 5, 2 e 4 ou um 7 a aposta e perdida. O pago para um hard 6 é de 9:1.',
        title: 'Aposta Hard 6',
      },
      hard8: {
        line1:
          'Quando o jogador aposta no hard 8, o jogador espera que um 4 saia nos dois dados antes que quaisquer outra combinação de 8 ou um 7. Se o jogador tira um 2 e 6, 3 e 5, ou um 7 a aposta e perdida. O pago para um hard 8 é de 9:1.',
        title: 'Aposta Hard 8',
      },
      payouts: {
        line1:
          'Os pagamentos no Craps poderiam oferecer muitas variantes dependendo do que ponto foi estabelecido o qual total o player esta tentando obter na rolada. A tabela embaixo mostra todos os pagamentos disponíveis no Craps. Em alguns casos, o pagamento mostra o numero para qual o pago é aplicável.',
        line2:
          "Por exemplo, 'Odds para passar a linha – 4 e 10' o pagamento correto para apostas feitas nos odds para passar a linha quando o ponto é 4 ou 10. 'Odds de Come – 6 e 8' o pagamento é correto para apostas feitas no Odds para Come é na posição 6 e 8.",
        bet: 'Aposta',
        payout: 'Pagamento',
        and: 'e',
        title: 'Pagamentos',
      },
    },
    gameControls: {
      button1Label: 'Roll',
      button1Description: 'Começa a tirada dos dados.',
      gameTips: {
        title: 'Dicas do Jogo',
        tip1Label: 'CONTROL+CLICK',
        tip1Description: 'Para remover a aposta',
        tip2Label: 'SHIFT+CLICK sobre a aposta',
        tip2Description: "Para passar de 'ligar', 'desligar'.",
      },
    },
  },
  crazyPoker: {
    gameName: 'Crazy Poker',
    game: {
      introduction: {
        line1:
          "Crazy Poker é um jogo de casino o qual é jogado contra do dealer. É similar que o Poker de três cartas mais como o diz o nome, há uma diferencia que faz do jogo algo 'crazy'.",
        title: 'Introdução',
      },
      basicRules: {
        line1:
          'Crazy Poker é baseado em quatro cartas. O jogador e o dealer recebem cada um cinco cartas para assim fazer a melhor mão de quatro cartas. Uma seqüência de 4 cartas é uma seqüência, um flush de 4 cartas é um flush e um seqüência do mesmo naipe com 4 cartas segue sendo uma seqüência do mesmo naipe.',
        title: 'Regras básicas',
      },
      start: {
        line1:
          'Coloque uma quantidade de aposta nos círculos de Ante e Super bônus. Como opção, coloque uma aposta no circulo de Queens up.',
        line2:
          'Uma vez que as apostas foram colocadas, clique no botão de repartir ao final da mesa, você e o dealer receberão5 cartas.',
        title: 'Começo',
      },
      playAndAnteWagers: {
        line1:
          "Uma vez que você viu suas cartas, você pode botar o seguir no jogo fazendo uma aposta de jogo. Uma aposta de jogo deve ser igual que o Ante. Para fazer isto, clique no botão ao final que diz 'Call'.",
        title: 'Apostas de Jogo & Ante',
      },
      tripleDownOption: {
        line1:
          "Se você tem um par de Ases o melhor na sua mão, você poderá ver uma opção que diz triplicar sua aposta. Clique no botão de TRIPLE e assim apostar três vezes seu Ante ('Triple Down').",
        title: 'Opção Triple Down',
      },
      howToWin: {
        line1:
          "Há dois formas de ganhar: (1) quando o dealer não qualifica ou (2) quando um jogador tem uma mão melhor que a mão do dealer. O dealer qualifica com Rei o melhor. Você ganha 'even money' en sua aposta de jogo quando o dealer não pode qualificar e a aposta de Ante é um empate. Você ganha 'even money' em sua aposta de Ante e sua aposta de jogo quando você tem uma mão melhor que a mão com a qual o dealer qualificou. Você perde sua aposta de jogo e seu Ante quando sua mão perde com a mão qualificada do dealer.",
        title: 'Como ganhar',
      },
      superBonus: {
        line1:
          'A aposta de super bônus deve ser igual à quantidade apostada no Ante. Você perde de dois formas (1) se você bota sua mão e (2) você perde com o dealer e sua mão e menor que uma seqüência. Paga baseada na tabela de pagamentos do Super Bônus.',
        title: 'Super Bonus',
      },
      queensUp: {
        line1:
          'A opção Queens Up é uma aposta na mão do jogador contra a tabela de pagamentos mostrada. Precisa de um par de rainhas o melhor para ganhar. Esta aposta não é afetada pela mão do dealer.',
        title: 'Queens Up',
      },
    },
    gameControls: {
      button1Label: 'Jogo novo',
      button1Description: 'Começar um jogo novo.',
      button2Label: 'Limpar apostas',
      button2Description: 'Limpar todas as aposta.',
      button3Label: 'Reapostar',
      button3Description: 'Repetir sua ultima aposta.',
      button4Label: 'Repartir',
      button4Description: 'Diz para o dealer que pode repartir as carta.',
      button5Label: 'Call',
      button5Description: 'Turns the dealer’s cards.',
      button6Label: 'Botar',
      button6Description: 'Botar sua mão.',
      button7Label: 'Triple Down',
      button7Description: 'Triples your Ante.',
    },
  },
  hooHeyHow: {
    gameName: 'Hoo Hey How',
    game: {
      introduction: {
        line1: "Este é um jogo antigo, simples, e é uma variante do jogo de loteria e dados Asiático 'Crown & Anchor'.",
        line2:
          'Há 3 dados e cada lado tem diferentes imagens que são iguais com as imagens na mesa. 6 imagens totais, incluem, um peixe, camarão, galo, caranguejo, garrafa e moeda.',
        title: 'Introdução',
      },
      rulesOfGame: {
        line1:
          'Escolha uma ficha e coloque sua aposta numa imagem o mais na mesa. O único limite de apostas que tem é o limite da mesa. Quando tenha colocado suas apostas, clique no botão de rolar, e a caixa dos dados começará rolar. Quando parar, os dados lhe mostrarão as imagens finais. Se alguma delas é igual que alguma imagem de suas apostas....você ganha!',
        title: 'Regras do Jogo',
        dice: 'dado',
        dicePlural: 'dado',
        match: 'Correspondência',
        payout: 'Pagamento',
      },
    },
    gameControls: {
      button1Label: 'Jogo novo',
      button1Description: 'Começar um jogo novo.',
      button2Label: 'Limpar apostas',
      button2Description: 'Limpar todas as aposta.',
      button3Label: 'Reapostar',
      button3Description: 'Repetir sua ultima aposta.',
      button4Label: 'Rolar',
      button4Description: 'Inicia a gaiola com os dados para virar.',
    },
  },
  keepEmPoker: {
    gameName: "Keep 'em Poker",
    game: {
      introduction: {
        line1:
          "Keep'em Poker é baseado no poker de cinco-cartas Stud Poker. No Keep'em, o jogador não esta competindo contra de algum outro jogador ou o dealer. O objetivo é de fazer a melhor combinação/mão de Poker possível desde as primeiras três cartas repartidas e duas cartas comunitárias que são logo expostas.",
        title: 'Introdução',
      },
      howToPlay: {
        line1: "O jogador coloca três apostas iguais nos círculos marcados com '1', '2' e '3'.",
        line2:
          'O jogador recebe três cartas viradas e duas cartas comunitárias que estão no centro da mesa, sem virar.',
        line3:
          "Depois de ver as três primeiras cartas, o jogador pode tirar a primeira aposta o pode também 'Keep'em', deixando a aposta no jogo.",
        line4: 'A primeira carta das duas cartas comunitárias é virada, sendo assim a quarta carta do jogador.',
        line5: "O jogador poderia tirar a segunda aposta ou pode 'Keep'em'. ",
        line6:
          'Finalmente a segunda carta comunitária é virada sendo a quinta carta do jogador, e é a qual determina o poker do jogador e o resultado. Se o jogador tem pelo menos um par de 10 o maior, o jogador recebe o pagamento (veja pagamentos).',
        line7:
          'Leve em conta que se tira a segunda aposta esta ação é independente de o que ocorre com a primeira aposta. Sem importar sua decisão com a primeira o segunda aposta o jogador não pode tirar a terceira aposta.',
        title: 'Como Jogar',
      },
      bonusGame: {
        line1:
          'Ao colocar as 3 apostas iniciais, o jogador pode fazer uma jogada de bônus opcional 1 (uma jogada paralela). As mãos de pôquer de qualificação podem ganhar até 20.000 (veja abaixo).',
        title: 'Bonus Game',
      },
      payoutTable: {
        playerHand: 'Mão do jogador',
        basicPayout: 'Pagamentos de jogo básico',
        sideBetPayout: 'Pagamentos de Side Bet',
        payout1Label: 'Seqüência real',
        payout2Label: 'Seqüência de mesmo naipe',
        payout3Label: 'Poker',
        payout4Label: 'Full House',
        payout5Label: 'Flush',
        payout6Label: 'Seqüência',
        payout7Label: 'Trio',
        payout8Label: 'Dois Pares',
        payout9Label: '10s ou melhores',
        title: 'Tabela de Pagamento',
      },
    },
    gameControls: {
      button1Label: 'Jogo novo',
      button1Description: 'Começar um jogo novo.',
      button2Label: 'Limpar apostas',
      button2Description: 'Limpar todas as aposta.',
      button3Label: 'Reapostar',
      button3Description: 'Repetir sua ultima aposta.',
      button4Label: 'Repartir',
      button4Description: 'Diz para o dealer que pode repartir as carta.',
      button5Label: 'Keep em',
      button5Description: 'Instrui para o dealer de virar as cartas comunitárias.',
      button6Label: 'Pull out',
      button6Description: 'Reembolsa a primeira e segunda aposta.',
    },
  },
  keno: {
    gameName: 'Keno',
    game: {
      introduction: {
        line1:
          'Keno  e um jogo emocionante que foi introduzido na China ao redor do ano 200 A.C. Chegou para os Estados Unidos quando os imigrantes e com o tempo evolucionou até o jogo que é hoje.',
        title: 'Introdução',
      },
      rules: {
        line1:
          'O jogo Keno é jogado com um bilhete tradicional de 80 números e 20 bolas cuja função é a mesma de uma loteria padrão. O jogador pode escolher de 1 a 15 números ou de 1 a 10 números dependendo de qual jogo de Keno está selecionado ',
        line2:
          'Os valores dos prêmios são exibidos na tabela de pagamento, dependendo de quantos números correspondem. Os números correspondentes são aqueles escolhidos pelo jogador e o que o jogo exibe.',
        line3:
          "Depois de cada jogo você pode escolher ficar com os mesmos números que você escolheu anteriormente (clicando no botão 'Jogue') ou clicar em novos números dentro do jogo.",
        title: 'Regras do jogo',
      },
      superKeno: {
        line1:
          'Num jogo Super Keno, se o número final apresentado do jogo coincidir com um número escolhido pelo jogador, os ganhos são multiplicados por 4.',
        title: 'Super Keno',
      },
    },
    gameControls: {
      button1Description: 'Clique no quadro para escolher os numeros.',
      button2Description: 'Selecione a aposta.',
      button3Label: 'Jogue',
      button3Description: 'Comece o jogo.',
    },
  },
  paigow: {
    gameName: 'Pai Gow Poker',
    game: {
      introduction: {
        line1: 'Pai Gow poker é uma combinação de Poker e o jogo antigo Chinês de Pai Gow.',
        line2:
          'O jogador recebe 7 cartas, as quais são divididas em duas mãos, uma mão de 5 cartas e outra de dois cartas. O objetivo é lhe ganhar ao dealer.',
        title: 'Introdução',
      },
      howToPlay: {
        line1:
          "Primeiro, coloque uma aposta selecionando uma ficha e clicando na área de apostas no meio da mesa. Você pode pôr varias apostas se quiser. Para remover uma ficha da mesa, clique no botão 'Limpar apostas'.",
        line2:
          "Você recebe 7 cartas. Deixe 5 delas na mão alta e selecione dois que irão na segunda mão alta. Para enviar uma carta para a segunda mão alta, somente clique na carta. Para envia-la para a primeira mão alta clique nela de novo. O mão de 5 cartas sempre deve ter uma maior classificação que a mão de 2 cartas. Se você não pode decidir quais cartas você escolher, sempre pode clicar no botão 'House Way' e o computador pode fazer a seleção por você na melhor forma possível.",
        line3:
          "As cartas do dealer serão mostradas. Se ambas mãos do dealer são maiores que as mãos de você, você perde sua aposta. Se uma das mãos de você é maior que as mãos do dealer e a outra e menor, o resultado é um empate e sua aposta é devolvida. Se ambas mãos de você são maiores que as mãos do dealer, você ganha 'even money' em sua aposta (1:1) menos um 5% de comissão para o banco. No caso de empate exato (chamado de 'copia') o empate é para o banqueiro.",
        title: 'Como Jogar',
      },
      houseway: {
        line1: 'O frente que dizer que a mão de 2 cartas e detrás é a mão de 5 cartas.',
        houseway1Label: 'Nenhum par',
        houseway1Line1: 'Coloca a carta mais alta detrás ao lado das dois cartas mais altas no frente.',
        houseway2Label: 'Um par',
        houseway2Line1: 'Coloca um par detrás ao lado das dois cartas mais altas ao frente.',
        houseway3Label: 'Dois pares',
        houseway3Line1: 'Use os grupos seguintes para determinar como jogar seus pares:',
        houseway3Line2: '2 até 6: par baixo ',
        houseway3Line3: '7 até 10: par meio ',
        houseway3Line4: 'Valete até Rei: par alto ',
        houseway3Line5:
          'Par baixo e par baixo: dividir a menos que tenha um Rei o melhor, então jogue os dois pares detrás.',
        houseway3Line6:
          'Par baixo e par meio: dividir a menos que tenha um Rei o melhor, então jogue os dois pares detrás.',
        houseway3Line7: 'Par baixo e par alto: dividir a menos que tenha um As, então jogue os dois pares detrás.',
        houseway3Line8: 'Par meio e par meio: dividir a menos que tenha um As, então jogue os dois pares detrás.',
        houseway3Line9: 'Par meio e par alto: sempre dividir.',
        houseway3Line10: 'Par alto e Par alto: sempre dividir.',
        houseway3Line11: 'Par de Ases e quaisquer outro par: sempre dividir.',
        houseway4Label: 'Três paires',
        houseway4Line1: 'Sempre jogue o par mais alto no frente.',
        houseway5Label: 'Trio',
        houseway5Line1:
          'Sempre jogue trios detrás a menos que sejam três ases, então jogue um par de ases detrás e o outro as no frente.',
        houseway6Label: 'Trio duplo',
        houseway6Line1: 'Sempre jogue o par mais alto na frente.',
        houseway7Label: 'Seqüência, flush, seqüência do mesmo naipe, e seqüência real',
        houseway7Line1:
          'Sem par: Quando escolha se jogar uma seqüência, flush, ou seqüência real; jogue da forma que as dois cartas maiores estejam na frente.',
        houseway7Line2:
          'Com 6 ou 7 cartas: Jogue a seqüência ou flush detrás para colocar as cartas maiores na frente.',
        houseway7Line3:
          'Com um par: Jogue o par na frente somente se uma seqüência, flush o seqüência real podem ser jogadas detrás.',
        houseway7Line4: 'Com dois pares Use a regra de dois pares.',
        houseway7Line5: 'Com três pares: Use a regra de três pares.',
        houseway7Line6: 'Com trio: jogue um par na frente.',
        houseway7Line7: 'Com full house: Use as regras do full house.',
        houseway8Label: 'Full house',
        houseway8Line1: 'Sempre divida a menos que o par sejam 2s e você tem um Rei e um As para jogar na frente.',
        houseway9Label: 'Full house com trio e dois pares',
        houseway9Line1: 'Jogue o par alto na frente.',
        houseway10Label: 'Full house com trio duplo',
        houseway10Line1: 'Sempre jogue o par alto na frente.',
        houseway11Label: 'Poker',
        houseway11Line1: 'Jogue de acordo à classificao de Poker:',
        houseway11Line2: '2 até 6: Sempre manter juntos.',
        houseway11Line3: '7 até 10: Dividir a menos que tenha um Rei ou melhor que possa ser jogado na frente.',
        houseway11Line4: 'Valete até Rei: Dividir a menos que tenha um As para jogar na frente.',
        houseway11Line5: 'Ases: sempre dividir. ',
        houseway12Label: 'Poker e par',
        houseway12Line1: 'Jogue o par na frente.',
        houseway13Label: 'Poker e trio',
        houseway13Line1: 'Jogue o par na frente do trio.',
        houseway14Label: '5 Ases',
        houseway14Line1:
          'Divida os Ases e jogue três ases detrás e dois ases na frente a menos que tenha par de Reis, então jogue os 5 ases detrás com os reis na frente.',
        title: 'House Way',
      },
      ranking: {
        highStraight: 'Seqüência alta (10, J, Q, K, ACE)',
        secondHighStraight: '2da seqüência alta (ACE, 2, 3, 4, 5)',
        title: 'Classificação das mãos',
      },
    },
    gameControls: {
      button1Label: 'Jogo novo',
      button1Description: 'Começar um jogo novo.',
      button2Label: 'Limpar apostas',
      button2Description: 'Limpar todas as aposta.',
      button3Label: 'Reapostar',
      button3Description: 'Repetir sua ultima aposta.',
      button4Label: 'Repartir',
      button4Description: 'Diz para o dealer que pode repartir as carta.',
      button5Label: 'Call',
      button5Description: 'Vira as cartas do dealer.',
      button6Label: 'House way',
      button6Description: 'Deixa que o computador selecione as mãos na melhor forma por você.',
    },
  },
  pokerThree: {
    gameName: 'Poker Three',
    game: {
      introduction: {
        line1:
          'Poker Three é rápido e divertido, é um estilo de poker que precisa de pouca estratégia para que um jogador possa ter sucesso. A premissa do jogo é simples: ter uma mão melhor que o dealer com uma repartida de três cartas somente.',
        line2:
          "Poker 3 é realmente dois jogos em um. Ambos o dealer e o jogador obtém três cartas. Há duas apostas, Ante, onde o jogador compete com o dealer e 2's N Better, onde o jogador aposta se sua mão terá um par o melhor.",
        line3: "O jogador poderia ter a duas, Ante e 2's N Better, o somente uma aposta de Ante ganhada.",
        title: 'Introdução',
      },
      basicRules: {
        line1:
          'Poker 3 é igual que o Poker regular com um baralho de 52 cartas. As cartas são repartidas em mãos de três. Seqüência tem uma classificação maior que o Flush, já que há mais formas de fazer uma seqüência de três cartas que um flush. Ases podem ser baixos o altos, dependendo de sua necessidade como no Poker.',
        title: 'Regras básicas',
      },
      anteAndAnteBonus: {
        line1:
          "Comece colocando sua aposta de Ante na área de 'Ante' na mesa. O Ante trabalha igual como no poker convencional. É o custo de jogar a mão. Você pode também fazer sua aposta de 2's N Better neste momento. (veja 2's N Better embaixo).",
        line2:
          "O dealer recebe 3 cartas sem virar e o jogador recebe 3 cartas viradas. Você determina se suas cartas são boas para apostar contra do dealer (Call) or se você bota suas cartas. Se você botar, simplesmente perde sua aposta de Ante. O 2's N Better, se é aplicável é pago e a mão termina.",
        line3:
          "Se você decide jogar contra o dealer, você deve colocar uma aposta igual que o Ante no espaço de 'Aposta' na mesa e clicar no botão de 'Call'.",
        line4:
          "As cartas do dealer são viradas. O dealer deve ter pelo menos Rainha o melhor para qualificar. Quaisquer carta mais baixa é automaticamente uma perda para o dealer e um ganho para você (se você ainda não botou sua mão). Se a mão do dealer não qualifica, você é pagado 'even money' no Ante e no Bônus de Ante, se é aplicável. A aposta é empatada (você não ganha nem perde).",
        line5:
          'No Poker 3, você tem o bônus na aposta original, o Ante bônus. Este é pago se você tem uma das três cartas mais altas da seguinte combinação: seqüência do mesmo naipe, trio ou seqüência. Este bônus é pago se a mão do dealer não qualifica e também se a mão do dealer perdesse contra a mão do jogador. Veja PAGAMENTOS para o pago do Bônus de Ante. O Bônus do Ante não é pago se você bota sua mão.',
        line6:
          "Se a mão do dealer qualifica como a mão mais alta, o dealer ganha. Se a mão do dealer esta acima da mão do jogador, o jogador perde sua aposta de Ante e sua Aposta, mas ainda pode ganhar o Bônus do Ante com uma seqüência o maior. Os que possam vencer o dealer terão pago 'even money' em sua aposta de ante e sua aposta regular e também no bônus de ante, se é aplicável. Se a mão do jogador e a mão do dealer tem a mesma classificação, o carta mais alta determina o ganhador (por exemplo: um par de Reis vencerá um par de 10s). Se a mão do dealer empata com a mão do jogador as apostas são devolvidas e a aposta é um empate.",
        title: 'Ante e Ante Bonus',
      },
      twosNBetter: {
        line1:
          "O 2's N Better é uma aposta na qual você terá repartido um par ou melhor. Esta aposta opcional é feita ao mesmo tempo que você faz sua aposta de ante, colocando uma aposta de 2's N Better na mesa. Você não joga contra do dealer. Você ganha se você é repartido um par ou melhor, sem importar a mão do dealer. Veja PAGAMENTOS para conhecer o pago pelo 2's N Better na tabela de pagamentos embaixo:",
        line2:
          "Quando esteja calculando um ganho, há três coisas que deve considerar: o resultado do 2's N Better, o resultado do Bônus de Ante e o resultado do Ante.",
        payout: 'Pagamento',
        push: 'empata',
        lose: 'perde',
        anteWins: 'Ante ganha',
        antePushes: 'Ante empata',
        anteLoses: 'Ante perde',
        entryWins: 'Entry ganha',
        entryPushes: 'Entry empata',
        entryLoses: 'Entry perde',
        twosNBetterResult: "Resultado do 2's N Better",
        twosNBetterResult1Label: 'Par',
        twosNBetterResult2Label: 'Flush',
        twosNBetterResult3Label: 'Seqüência',
        twosNBetterResult4Label: 'Trio',
        twosNBetterResult5Label: 'Seqüência de mesmo naipe',
        anteBonusResult: 'Resultado do Ante Bonus',
        anteBonusResult1Label: 'Seqüência',
        anteBonusResult2Label: 'Trio',
        anteBonusResult3Label: 'Seqüência de mesmo naipe',
        playResult: 'Resultado do Ante',
        playResult1Label: 'O dealer não qualifica:',
        playResult2Label: 'O dealer qualifica e o jogador vence o dealer:',
        playResult3Label: 'O dealer qualifica e empata com o jogador:',
        playResult4Label: 'O dealer qualifica e vence o jogador:',
        title: "2's N Better",
      },
    },
    gameControls: {
      button1Label: 'Jogo novo',
      button1Description: 'Começar um jogo novo.',
      button2Label: 'Limpar apostas',
      button2Description: 'Limpar todas as aposta.',
      button3Label: 'Reapostar',
      button3Description: 'Repetir sua ultima aposta.',
      button4Label: 'Repartir',
      button4Description: 'Diz para o dealer que pode repartir as carta.',
      button5Label: 'Call',
      button5Description: 'Aumenta sua aposta para a quantidade de sua aposta original.',
      button6Label: 'Botar',
      button6Description: 'Botar sua mão.',
    },
  },
  redDog: {
    gameName: 'Red Dog',
    game: {
      introduction: {
        line1:
          'Red Dog, também conhecido como Acey-Deucey ou Between the Sheets, é um jogo simples. Se pode lembrar do numero 7 e você sabe subtrair, você pode jogar sem problema o jogo de Red Dog como quaisquer pessoa ao redor do mundo.',
        line2:
          'Duas cartas são repartidas. O objetivo do jogo é apostar na possibilidade que o naipe da seguinte carta será um no meio das duas primeiras cartas. Se for um naipe diferente ao naipe das duas primeiras então você ganha, se não, você perde. Se o possibilidade e odds nas primeiras duas cartas é maior, melhor será o potencial de ter uma terceira carta com um naipe diferente das primeiras dois.',
        title: 'Introdução',
      },
      howToPlay: {
        line1:
          'Red Dog usa um baralho de 52 cartas. Todas as cartas são classificadas pelo naipe de Poker, o tipo de naipe é irrelevante e Ases sempre são altos.',
        line2:
          'O spread é o numero de cartas e valores que estão no meio das primeiras dois cartas. O valor de quaisquer carta desde 2 até 10 conta com o valor dela mesma, o Valeta tem um valor de 11, Rainha 12, Rei 13 e o As 14.',
        line3:
          "Comece apostando seu Ante no lugar marcado como 'Aposta' na mesa. Duas cartas serão repartidas e viradas.",
        line4: 'Se as dois cartas viradas são consecutivas então a mão é um empate e sua aposta orignal é devolvida.',
        line5:
          'Se as dois cartas viradas são iguais uma terceira carta é repartida. Se a terceira carta é igual que as duas primeiras, o jogador é pagado 11:1 na aposta original. Se a terceira carta não é igual que as primeiras duas, então a aposta é um empate e o dinheiro é devolvido.',
        line6:
          'Se as duas primeiras cartas não são nem consecutivas nem iguais, o montante do Spread será mostrado. O jogador então tem a opção de fazer uma aposta mais (Aumentar) igual que a aposta original. Uma terceira carta será repartida. Se o naipe dessa carta é distinto ao naipe das duas primeiras o jogador ganha (veja Pagamentos). Se a terceira carta é de um naipe igual ou esta no meio dos naipes das duas primeiras cartas o jogador perde.',
        title: 'Como Jogar',
      },
      payouts: {
        line1: 'Três cartas iguais pagam 11:1.',
        line2: "Apostas de Ante sempre são pagadas 'even money'.",
        line3: 'O pagamento para as apostas de aumento é baseado no spread seguinte:',
        through: 'até ',
        numbers: 'Números',
        payout: 'Pagamento',
        title: 'Pagamentos',
      },
    },
    gameControls: {
      button1Label: 'Jogo novo',
      button1Description: 'Começar um jogo novo.',
      button2Label: 'Limpar apostas',
      button2Description: 'Limpar todas as aposta.',
      button3Label: 'Reapostar',
      button3Description: 'Repetir sua ultima aposta.',
      button4Label: 'Repartir',
      button4Description: 'Diz para o dealer que pode repartir as carta.',
      button5Label: 'Aumentar',
      button5Description: 'Aumenta sua aposta para a quantidade de sua aposta original.',
      button6Label: 'Call',
      button6Description: 'Virar as cartas do dealer.',
    },
  },
  roulette: {
    gameName: 'Roulette',
    game: {
      introduction: {
        line1:
          'O jogo de Roleta tem seu começo na Inglaterra nos anos 1700 num jogo que chamava-se Roly Poly. Tinha pontos brancos e pretos sem números.',
        line2:
          'Os Franceses redesenharam o jogo no final dos anos 1700 para incluir números. A roda original tinha 31 números mais um 0,00, e uma águia Americana para marcar o terceiro zero ( 000). Hoje as Roletas Europeus tem somente um zero quando a maioria das rodas Americanas tem um zero e um zero duplo.',
        line3: 'Duas rodas, tem agora 36 números. Roleta é o jogo mais popular nos Casinos na Europa.',
        line4:
          'Jogadores, geralmente até 8, jogam contra o Casino que é representado pelo crupiêr, quem gira a roda da Roleta e manipula as apostas e os pagamentos.',
        line5: 'O americano a roda tem 38 slots representando 36 números, um único zero e um duplo zero.',
        title: 'Introdução',
      },
      basicRules: {
        line1:
          "Para jogar Roleta, faça sua aposta ou apostas nos números (quaisquer outro numero incluído o zero e zero duplo) na disposição da mesa o fora da mesa, e quando todos na mesa fizeram as apostas o crupiêr dirá 'no mais apostas'. Desde esse momento nenhuma pessoa é permitida de fazer mais apostas o mudar as que já foram feitas até que a bola caia no ranhura. Depois disto o crupiêr põe a sinal no numero ganhador na mesa da roleta e limpa todas as outras apostas que perderam, você pode começar pôr suas apostas novas ainda quando o crupiêr esteja pagados ganhos. Os ganhadores são as apostas que estão ao redor o são o numero ganhador preciso. Também, as apostas afora da mesa poderiam ganhar se o numero ganhador é representado.",
        line2:
          'Há muitos tipos de fazer apostas na roleta. Cada aposta cobre um numero diferente de números e tem uma distribuição como pode ser vista na seção de "Tabela de Ganhos".',
        title: 'Regras básicas',
      },
      straightUp: {
        line1: 'Você pode apostas em quaisquer numero, incluindo o 0, pondo o chip no centro de quaisquer numero.',
        title: 'Aposta direita',
      },
      splitEntry: {
        line1: 'Você pode apostar em dois números pondo o chip na linha que divide os dois números.',
        title: 'Aposta divida',
      },
      streetEntry: {
        line1:
          'Você pode apostar em três números pondo o chip o mais perto possível da linha de divisão na esquerda da mesa da roleta na linha correspondente de três números.',
        title: 'Aposta de Três',
      },
      cornerEntry: {
        line1: 'Você pode apostar em quatro números pondo o chip no canto onde os quatro números se encontram.',
        title: 'Aposta de Canto',
      },
      fiveEntry: {
        line1:
          'Você pode apostar nos números 0, 00, 2 e 3 pondo seu chip no limite interior esquerdo no meio de 0 e 1.',
        title: 'Aposta de cinco',
      },
      lineEntry: {
        line1:
          'Você pode apostar dos linhas (exemplo: os seis números diferentes em duas linhas de três números) se você pôr seu chip na linha do limite interior esquerdo onde esta a divisão e as linhas que a interceptam.',
        title: 'Aposta de linha',
      },
      columnEntry: {
        line1:
          "Há três caixas com o nome '2 a 1' ao final de cada coluna com números. Você pode apostar todos os números da coluna pondo seu chip dentro de algum destas caixas. Se algum dos números em sua coluna é obtido você recebera 2:1 em suas aposta, 0 e 00 são perdedores.",
        title: 'Aposta de Coluna',
      },
      dozenEntry: {
        line1:
          "Você pode apostar num grupo de doze números pondo seu chip numa de três caixas marcadas '1eros 12', '2dos 12' ou '3eros 12'. Se algum dos seus 12 números é obtido, você recebe o pago de 2:1; 0 e 00 são perdedores.",
        title: 'Aposta de Dúzia',
      },
      moreEntries: {
        line1:
          'Você pode apostar numa das caixas na esquerda, ao lado da mesa que cobre a metade dos números da roleta (excluindo o 0 e 00). Cada caixa cobre 18 números. Você ganha even (1 a 1) em todas as apostas deste tipo; 0 e 00 são perdedores. Amostra de Números Ganhadores.',
        title: 'Apostas no Vermelho/Preto, Par/Ímpar, Baixo/Alto',
      },
      winningNumberDisplay: {
        line1: 'Amostra de números ganhadores mostra os resultados dos 7 giros anteriores.',
        title: 'Tabela de Ganhos',
      },
      winningsTable: {
        line1: 'NOTA: Você pode apostar que incluam dois, três e até quatro números.',
        line2: 'Por exemplo, usando o zero, você poder apostar no: 0+1, 1+2 +0, 3+0 ou 1+2+3+0.',
        chipCovers: 'O chip cobre',
        playType: 'Tipo de Aposta',
        pays: 'Pagamento',
        number: 'numero',
        numbers: 'numeros',
        win1Label: 'Direita',
        win2Label: 'Dividida',
        win3Label: 'Três',
        win4Label: 'Canto',
        win5Label: 'Quatro',
        win6Label: 'Linha',
        win7Label: 'Coluna ou Dúzia',
        win8Label: 'Vermelho/Preto, Par/Ímpar, Baixo/Alto',
        win9Label: 'Cinco',
        title: 'Pagamentos',
      },
    },
    gameControls: {
      button1Label: 'Jogo novo',
      button1Description: 'Começar um jogo novo.',
      button2Label: 'Limpar apostas',
      button2Description: 'Limpar todas as aposta.',
      button3Label: 'Reapostar',
      button3Description: 'Repetir sua ultima aposta.',
      button4Label: 'Girar',
      button4Description: 'Instruir o crupiêr para girar a roda da Roleta.',
      button5Label: 'Giro rápido',
      button5Description: 'O sistema escolhe algum numero de forma fortuita sem a ação da roda girando.',
    },
  },
  sicBo: {
    gameName: 'Sic Bo',
    game: {
      introduction: {
        line1:
          'Sic Bo, também conhecido como Tai Sai, é um jogo Chinês antigo de sorte. Três dados são jogados numa caixa. O objetivo do Sic Bo é escolher números ou combinações que poderiam aparecer no dado quando eles parar de girar. Há 50 tipos de apostas, então você tem uma série de opções com pagos diferentes – alguns que poderiam chegar até 180:1.',
        title: 'Introdução',
      },
      howToPlay: {
        line1:
          'Clique numa ficha com o valor desejado. Depois clique em quaisquer área de aposta na mesa para colocar sua aposta. (um listado de tipos de apostas esta disponível na área de Sic Bo). Cada vez que você clique na área de apostas, adiciona uma ficha mais. Selecione outra ficha para aumentar o valor da aposta. Você pode colocar varias fichas em diferentes áreas de apostas ao mesmo tempo.',
        line2:
          'Clique no botão de Rolar. Os dados agora giram e você poderá ver o resultado do jogo. Clique em Jogo Novo se você quiser jogar novamente. Coloque suas apostas como foram descritas acima e clique no botão de Rolar. Você pode também clicar no Re-Apostar para fazer a mesma aposta como no jogo anterior.',
        title: 'Como Jogar',
      },
      betTable: {
        type: 'Tipo',
        definition: 'Definição',
        bet1Label: 'Somente um numero',
        bet1Description:
          'A linha final do Sic Bo tem seis números em caixas. Estes são numero simples para apostas num numero somente. Se um, dois ou os três dados são iguais que este numero você ganha a quantidade nessa área.',
        bet2Label: 'Combinações de dois numeros',
        bet2Description:
          'Esta é uma aposta de combinação usando um das 15 combinações de números disponíveis. Se a combinação selecionada aparece nos dados, você ganha. Se a combinação aparecer mais de uma vez nos dados (por exemplo: você escolhe a combinação 3-5 e a combinação 3-5-5 aparece, você ganha duas vezes).',
        bet3Label: 'Par',
        bet3Description:
          'Esta é uma aposta feita em números específicos para aparecer pelo menos em dois dos três dados. Se o numero que você escolheu aparece em dois dos três dados, você ganha.',
        bet4Label: 'Triple',
        bet4Description:
          'Uma aposta que os três dados terão um numero especifico selecionado (triplo especifico). Você pode apostar em as 6 possibilidades triplos ao mesmo tempo. Isto é chamado de Quaisquer Triplo.',
        bet5Label: 'Total de três números',
        bet5Description:
          'Total de três números é uma aposta na suma dos três dados. Selecione um total especifico desde 4 até 17 (catorze apostas possíveis) . Se os odds para o total são pouco freqüentes então maior poderá ser o pagamento. O resultado de 3 ou 18 sempre é uma perda.',
        bet6Label: 'Números Pequenos ou Números Grandes',
        bet6Description:
          'Você apostar que o total dos três dados poderia ser um numero entre 4-10 (pequeno) ou 11-17(grande). Todas as apostas no pequeno e grande perderão se o resultado é um triplo(por exemplo três 5s).',
        title: 'Tipos de apostas',
      },
      payoutTable: {
        line1:
          'Os seguintes tipos de apostas estão baseados no numero especifico obtido e são pagados de acordo com o seguintes odds:',
        line2:
          'Os seguintes tipos de apostas estão baseados na suma do total dos três dados e o pagamento é feito de acordo como os seguintes odds:',
        type: 'Tipo',
        youWin: 'Você ganha',
        youWinIf: 'Você ganha se o total é igual que',
        examples: 'Exemplos',
        exceptions: 'Exceções',
        payout: 'Pagamento',
        and: 'e',
        or: 'ou',
        to: 'até',
        anyOtherTotal: 'Quaisquer outro total',
        payout1Label: 'Somente um número',
        payout1Description:
          'O pagamento muda dependendo da quantidade de vezes que o numero selecionado aparece nos dados.',
        payout2Label: 'Combinações de dois números',
        payout2Description: 'A combinação apostada aparece pelo menos em dois dados.',
        payout3Label: 'Par',
        payout3Description: 'O numero escolhido aparece em dois dos dados ou nos três dados.',
        payout4Label: 'Quaisquer triplo',
        payout4Description: 'Quaisquer dos números apostados aparece nos três dados.',
        payout5Label: 'Triplo especifico',
        payout5Description: 'Três números específicos apostados e selecionados aparecem nos três dados.',
        payout6Label: 'Números Pequenos',
        payout7Label: 'Números Grandes',
        payout8Label: 'Total de três número',
        title: 'Pagamentos',
      },
    },
    gameControls: {
      button1Label: 'Jogo novo',
      button1Description: 'Começar um jogo novo.',
      button2Label: 'Limpar apostas',
      button2Description: 'Limpar todas as aposta.',
      button3Label: 'Reapostar',
      button3Description: 'Repetir sua ultima aposta.',
      button4Label: 'Rolar',
      button4Description: 'Instrui para o jogo de girar a caixa com os dados.',
    },
  },
  slotOneLineMagic: {
    gameName: 'Mix pics Slot Machine Style',
    game: {
      description: {
        line1:
          'Caça-níqueis têm diferentes variações e designs. Caça-níqueis de 1 linha é muito fácil de jogar e só se parece com uma slot machine. No entanto, o jogador não precisa de ícones específicos seguidos. A tabela de pagamentos mostra os ícones. prêmio como nem todo símbolo vai pagar um prêmio. Estes ícones podem mostrar em qualquer uma das 5 janelas de exibição.',
        line2:
          'Se o jogador receber o ícone de rodadas grátis, o pagamento do ícone do prêmio durante as rodadas grátis será multiplicado conforme mostrado na tabela de pagamento.',
        title: 'Introdução',
      },
      howTo: {
        line1:
          'Escolha o valor da moeda que é o número de recompensas que você deseja usar clicando em uma das moedas. Em seguida, clique no botão revelar.',
        autoOffLabel: 'Auto Desligado',
        autoOff: "Pressione para desabilitar 'Auto Ligado'.",
        autoOnLabel: 'Auto Ligado',
        autoOn: 'Clique neste botão para repetir as entradas anteriores e revelar os jogos automaticamente.',
        clearLabel: 'Limpar apostas',
        clear: 'Pressione neste botão para remover a quantia para jogar.',
        revealLabel: 'Giro',
        reveal: 'Pressione neste botão para iniciar o jogo.',
        paytableLabel: 'Pagamentos',
        title: 'Como entrar e jogar',
      },
      bonusRound: {
        line1:
          'Cada jogo tem a sua própria tabela de prémios e será indicado na tabela de prémios se um jogo tiver um jogo de bónus, jackpots ou revelações gratuitas (free spins).',
        line2:
          'Símbolos especiais (como visto na Tabela de Prêmios) lhe dará a possibilidade, dependendo de qual jogo, para entrar na rodada de Bônus. A rodada de bônus será carregada automaticamente! Cada jogo de bônus é diferente. Na rodada de bônus você pode aumentar seus ganhos sem usar nenhuma de suas recompensas. ',
        title: 'Rodada de bônus',
      },
      prizeTable: {
        line1:
          'Este botão está localizado na parte inferior esquerda da máquina. Dentro da mesa de prêmios real, o valor do pagamento para cada prêmio e / ou o número do mesmo ícone é exibido.',
        title: 'Tabela de Prêmios',
      },
    },
    gameControls: {
      button1Description: 'Este seletor de jogo permite aumentar ou diminuir o valor de entrada / moeda do seu jogo.',
      button2Label: 'Giro',
      button2Description: 'Pressione neste botão para iniciar o jogo.',
      button3Label: 'Auto Ligado',
      button3Description: 'Clique neste botão para repetir as entradas anteriores e revelar os jogos automaticamente.',
      button4Label: 'Reset',
      button4Description: 'Pressione neste botão para remover a quantia para jogar.',
      description_1: 'Spin button. click to start the game',
      description_2:
        'Click to open the auto spin panel. There you can chose the number of auto spins you would like to program',
      description_3: 'Start auto spin play',
      description_4: 'Stop auto spin play',
      description_5: 'Pause auto spin play',
      description_6: 'Click to collect while in the bonus game',
    },
  },
  slots: {
    gameName: 'Caça-níqueis',
    game: {
      bonusScatterWild: {
        line1:
          'Cada jogo tem a sua própria tabela de prémios e será indicado na tabela de prémios se um jogo tiver um jogo de bónus, jackpots ou revelações gratuitas (rodadas grátis).',
        line2:
          'Os símbolos Scatter (como visto na Tabela de Prêmios) dão a você a possibilidade, dependendo de qual jogo, entrar na rodada de Bônus. Com um número de símbolos de Scatter igual ou maior que 2 a rodada de bônus será carregada automaticamente! jogo de bónus é diferente. Na ronda de bónus, pode aumentar os seus ganhos sem usar nenhuma das suas entradas. ',
        line3:
          'Símbolos wild são usados ​​como um símbolo especial para completar uma linha. Exemplo: Se a linha mostrar os símbolos A, A, W, A, B, a linha ganharia como se fosse 4 do símbolo A. (A, A, A , A, B) Os símbolos Wild também podem ganhar em uma linha exatamente como os outros símbolos na tabela de prêmios, começando na 1ª posição consecutiva, e alguns jogos dão a você várias quantias de revelações gratuitas (rodadas grátis) dependendo de quantos deles símbolos selvagens que você vê após a revelação. ',
        line4:
          'Existem também vários jogos que lhe permitem escolher ir ao jogo de bónus sempre que ganhar no jogo principal ou se o símbolo Wild for revelado.',
        title: 'Rodada de bônus, Scatter e Wild',
      },
      description: {
        line1:
          'Os jogos Caça-níqueis têm diferentes variações e desenhos. Podem ter um número máximo de linhas de entrada de 1, 1 a 5 linhas, 1 a 15 linhas, 1 a 21 linhas e 1 a 42 linhas para sua diversão. Linha 42 é na verdade 21 linhas começando com uma linha de símbolos correspondentes da esquerda para a direita e o mesmo da direita para a esquerda. ',
        line2:
          'Nos jogos de 15, 21 ou 42 linhas existem 5 rolos com 3 símbolos a serem exibidos em cada bobina, num total de 15 símbolos mostrando quando o jogo completa uma revelação.',
        line3:
          'Os caça-níqueis de linha única têm 3 bobinas e, possivelmente, exibem apenas 3 símbolos completos quando a revelação é concluída. A linha de vitória está no meio da área de jogo da esquerda para a direita.',
        line4: 'O jogo de 5 linhas é também 3 bobinas, no entanto as linhas vencedoras são 3 horizontais e 2 cruzadas.',
        title: 'Tudo sobre estilo de caça-níqueis',
      },
      howTo: {
        autoOffLabel: 'Auto Desligado',
        autoOff: "Pressione para desabilitar 'Auto Ligado'.",
        autoOnLabel: 'Auto Ligado',
        autoOn: 'Pressione neste botão para repetir as entradas anteriores e revelar os jogos automaticamente.',
        clearLabel: 'Fazer um saque',
        clear: 'Pressione neste botão para remover a quantia para jogar.',
        linesXLabel: 'Apostar Uma',
        linesX:
          'Pressione neste botão para aumentar o número de linhas de entrada que você deseja jogar. Quanto mais linhas você usar, maior a chance de ganhar.',
        maxLabel: 'Apostar o Máximo',
        max:
          'Este botão, se habilitado, irá reproduzir todas as linhas e o jogo será revelado. O jogo será carregado com o número máximo de linhas prontas para o seu uso',
        minusPlus: 'Este seletor de jogo permite aumentar ou diminuir o valor de entrada / moeda do seu jogo.',
        revealLabel: 'Giro',
        reveal: 'Pressione neste botão para iniciar o jogo.',
        paytableLabel: 'Pagamentos',
        title: 'Como entrar e jogar',
      },
      jackpot: {
        line1:
          'Se 5 dos mesmos símbolos do jackpot (sem incluir símbolos wild) aparecerem numa linha vencedora, apenas o jackpot é pago. linhas e Máximo de Entradas usando o seletor [-] e [+] pagarão a quantia do jackpot mostrada no código de rolagem. Usar menos que as Máximo de Entradas usando o valor do seletor [-] e [+] pagará um prêmio proporcional. ',
        line2:
          'Se houver 2 ou mais linhas com os 5 símbolos exatos, apenas um prêmio do jackpot é concedido por peça. No entanto, o prêmio para a linha mostrada na tabela de pagamento será concedido nas linhas vencedoras adicionais.',
        title: 'Pagamento do Jackpot',
      },
      payoutLines: {
        line1:
          'Os jogos caça-níqueis têm diferentes variações e designs. Estes podem ter um número máximo de linhas de 1, 1 a 5 linhas, 1 a 15 linhas, 1 a 21 linhas e 1 a 42 linhas estão disponíveis para sua diversão. A linha 42 é na verdade 21 linhas começando com uma linha de símbolos correspondentes da esquerda para a direita e a mesma da direita para a esquerda.',
        line2:
          'Este não é o MÓVEL H5',
        title: 'Linhas de Pagamento',
      },
      prizeTable: {
        line1:
          'Este botão está localizado dentro de um jogo para visualizar os prêmios vencedores. No lado esquerdo da tabela de prêmios existem linhas mostrando o número de símbolos consecutivos que você precisa para receber o prêmio indicado para cada símbolo específico (mostrado no topo Na tabela de prêmios atual, há colunas que mostram o seu prêmio para os acertos de símbolo. Esses prêmios mudam com base no número de entradas que você escolheu usando as setas [-] ou [+]. ',
        line2:
          'A única maneira de obter o símbolos consecutivos no jogo é ter uma sequência do mesmo símbolo sem interrupções começando pelo lado esquerdo das bobinas. A sequência será destacada pelas linhas coloridas mostradas no jogo, animação dos símbolos As informações mais importantes estão localizadas no centro da parte inferior do jogo.',
        title: 'Tabela de Prêmios',
      },
    },
    gameControls: {
      button1Description: 'Este seletor de jogo permite aumentar ou diminuir o valor de entrada / moeda do seu jogo.',
      button2Label: 'Apostar Uma',
      button2Description:
        'Pressione neste botão para aumentar o número de linhas de entrada que você deseja jogar. Quanto mais linhas você usar, maior a chance de ganhar.',
      button3Label: 'Giro',
      button3Description: 'Pressione neste botão para iniciar o jogo.',
      button4Label: 'Apostar o Máximo',
      button4Description:
        'Este botão, se habilitado, irá reproduzir todas as linhas e o jogo será revelado. O jogo será carregado com o número máximo de linhas prontas para o seu uso',
      button5Label: 'Auto Ligado',
      button5Description:
        'Pressione neste botão para repetir as entradas anteriores e revelar os jogos automaticamente.',
      button6Label: 'Fazer um saque',
      button6Description: 'Pressione neste botão para remover a quantia para jogar.',
      buttonH5PlayValues: 'Abre um Painel para selecionar o número de linhas e moedas que você deseja jogar.',
      buttonH5TwoWay:
        "O botão 'Bidirecional' está disponível em slots especiais. Pressione para ativar/desativar a aposta nas linhas da direita para a esquerda.",
      buttonH5Play: 'Pressione para iniciar / continuar a sequência de rotação automática ou rotação livre.',
      buttonH5Stop: 'Pressione para parar a sequência de rotação automática.',
      buttonH5Pause: 'Pressione para pausar a sequência de rotação automática ou rotação livre.',
    },
  },
  stud: {
    gameName: 'Caribbean Stud Poker',
    game: {
      introduction: {
        line1:
          "Caribbean Stud Poker é uma variação de stud poker de 5 cartas o qual tem também a emoção dos jackpots progressivos. Você joga a mão que recebe, não há um 'draw' no Caribbean Stud, então você não precisa decidir quais cartas manter. A mão do jogador é comparada com a mão do dealer, como a melhor mão sendo a ganhadora.",
        title: 'Introdução',
      },
      howToPlay: {
        line1:
          'Comece por colocar um ponto no espaço designado. Aos jogadores são dadas cinco cartas viradas para cima, enquanto a mão na mesa à sua frente recebe uma carta virada para cima.',
        line2: 'Neste ponto o jogador pode selecionar duas opções disponíveis:',
        title: 'Como Jogar',
      },
      surrender: {
        line1: 'Se render-se é selecionado, todas as apostas são perdidas e a mão é terminada.',
        title: 'Rendirse',
      },
      raise: {
        line1:
          'Uma aposta adicional igual que a aposta original é colocadas na posição de Aumentar na mesa. O dealer recebe 4 cartas mais viradas.',
        line2:
          "O dealer agora deve qualificar, tendo pelo menos um As ou Rei ou melhor. Se o dealer não qualifica, o jogador é pago 'even money' (1:1) e a aposta original é devolvida para o jogador como um empate.",
        line3:
          "Se o dealer não qualifica, o jogo avança para o 'Showdown' onde as mãos do dealer e do jogador são comparadas e a melhor mão de poker ganha.",
        title: 'Aumentar',
      },
      showdownTable: {
        if: 'Se...',
        then: 'então...',
        showdown1Label: 'O dealer ganha',
        showdown1Then: 'O jogador perder sua aposta original e o aumento.',
        showdown2Label: 'O dealer e jogador empatam',
        showdown2Then: 'A aposta original e o aumento são empate.',
        showdown3Label: 'O jogador ganha',
        showdown3Then:
          'O jogador é pagado even money (1:1) na aposta original e o Aumento é pagado de acordo à tabela de pagos de Aumento embaixo.',
        title: 'Showdown',
      },
      raisePayTable: {
        line1: 'Se o jogador ganha, o pago pela aposta de aumento é calculado na seguinte forma:',
        playerHand: 'Mão do jogador',
        payout: 'Pagamento',
        raisePay1Label: 'Seqüência real',
        raisePay2Label: 'Seqüência de mesmo naipe',
        raisePay3Label: 'Poker',
        raisePay4Label: 'Full House',
        raisePay5Label: 'Flush',
        raisePay6Label: 'Seqüência',
        raisePay7Label: 'Trio',
        raisePay8Label: 'Dois Pares',
        raisePay9Label: 'Par',
        raisePay10Label: 'As-Rei',
        raisePay11Label: 'Ooutra',
        raisePay11Payout: 'Empate',
        title: 'Tabela de pagamentos de Aumento',
      },
    },
    gameControls: {
      button1Label: 'Jogo novo',
      button1Description: 'Começar um jogo novo.',
      button2Label: 'Limpar apostas',
      button2Description: 'Limpar todas as aposta.',
      button3Label: 'Reapostar',
      button3Description: 'Repetir sua ultima aposta.',
      button4Label: 'Repartir',
      button4Description: 'Diz para o dealer que pode repartir as carta.',
      button5Label: 'Rendirse',
      button5Description: 'Dobre a sua mão e perde pontos iniciais jogados.',
      button6Label: 'Aumentar',
      button6Description: 'Adiciona um ponto igual a duas vezes os pontos iniciais colocados na tabela.',
    },
  },
  videoPoker: {
    gameName: 'Video Poker',
    game: {
      rulesOfTheGame: {
        line1:
          'O jogo de linha única / linha usa um baralho padrão de 52 cartas, que é embaralhado após cada mão. O jogo de múltiplas linhas usa um baralho de 52 cartas por linha.',
        line2:
          'O jogador está tentando ter a melhor combinação de cartas que serão pagas conforme mostrado na tabela de prêmios, que pode ser diferente para cada jogo único',
        line3:
          'Para detalhes específicos sobre cada combinação vencedora, por favor, verifique a Tabela de Prêmios que aparece na tela.',
        deuceWild: 'Deuce & Wild Video Poker',
        deuceWildRule:
          "é uma das versões mais divertidas do Poker. É jogado de forma semelhante a um jogo de Poker, mas os Deuces (os cards com o número '2') são Wild, o que significa que o cartão Deuce pode se tornar o cartão perdido. obtenha a maior mão possível. Com a carta '2-Wild', você pode transformar qualquer par em Three of a Kind ou completar um straight.",
        changePrizeColumn: 'Alterar coluna de prêmios',
        draw: 'Repartir',
        title: 'Regras do jogo',
      },
      howToPlayReveal: {
        line1:
          'Escolha o número de recompensas que deseja jogar clicando nas setas na parte inferior direita da máquina. [-] ou [+].',
        line2:
          "Clique no botão 'Alterar coluna de prêmios' para aumentar o valor do seu jogo e alterar a coluna do prêmio a ser usada na tabela de prêmios.",
        line3:
          "Depois de escolher uma das colunas do prêmio na tabela de prêmios, clique no botão 'Deal', você receberá 5 cartas com a face para cima.",
        line4:
          "Após o primeiro sorteio, clique nos cartões, se houver, que você deseja manter. 'HOLD' será exibido nas cartas que você deseja manter.",
        line5: "Clique em 'Draw' e os cartões serão substituídos. Apenas os cards com hold não serão alterados.",
        line6:
          'Suas cartas são comparadas com a tabela de combinações vencedoras na tabela de prêmios do jogo que você está jogando. Se o valor da sua mão for menor que a combinação ganhadora mínima, você perde suas recompensas jogadas. Se a combinação do seu card é qualquer um dos mostrados na tabela de prêmios você ganha o prêmio!!! Na multilinha você pode ganhar na combinação de cada linha. ',
        title: 'Como jogar ',
      },
      doublePlayoffRound: {
        line1:
          "Quando você tem uma mão vencedora, é oferecida uma rodada de playoff duplo na qual você tem a oportunidade de 'Dobrar' seus ganhos. Se você não quiser participar da rodada de playoff duplo, simplesmente clique em 'Coletar'.",
        double: 'Dobrar',
        collect: 'Coletar',
        title: 'Ronda de jogo dobro',
      },
      howToPlayDoublePlayoffRound: {
        line1: "Clique no botão 'Dobrar'.",
        line2:
          'Os cartões serão mostrados virados para baixo e, em seguida, um cartão do lado esquerdo ficará virado para cima.',
        line3: 'Clique em uma das quatro cartas que permanecem voltadas para baixo.',
        line4:
          'Se a carta escolhida tiver um valor maior do que o cartão entregue pelo computador - você vence e a ronda de playoff duplo continua, se desejar.',
        line5:
          "Por favor note: Se você não quiser continuar participando da rodada de playoff duplo, simplesmente clique em 'Coletar'.",
        line6: "Você pode mais uma vez clicar no botão 'Dobrar' e o mesmo ciclo irá ocorrer.",
        line7:
          'Se a carta escolhida for de igual valor para a carta virada pelo computador, é uma empate. A rodada dupla de jogos decisivos continua, se você quiser.',
        line8:
          'Se a carta escolhida for de valor menor que a carta entregue pelo computador, você perderá a jogada e a rodada de playoff duplo acabou.',
        line9: 'Por favor note: A rodada de playoff duplo termina automaticamente após um total de cinco rodadas.',
        title: 'Como jogar a ronda de jogo dobro',
      },
      paytable: {
        line1:
          'A tabela de pagamento irá alterar dinamicamente os valores do prêmio, dependendo das recompensas jogadas.',
        title: 'Tabela de pagamentos',
      },
      winningTable: {
        winning1Label: 'Dois Pares',
        winning1Description: '2 conjuntos de pares da mesma denominação do cartão',
        winning2Label: 'Trio',
        winning2Description: '3 cartas da mesma denominação',
        winning3Label: '4 Ases ou Oitos',
        winning3Description: 'Quatro cartas de ases ou oitos',
        winning4Label: '4 Deuces',
        winning4Description: 'Quatro cartas de Deuces',
        winning5Label: '4 Setes',
        winning5Description: 'Quatro cartas de Setes',
        winning6Label: 'Poker',
        winning6Description: '4 cartas da mesma denominação',
        winning7Label: 'Cinco do mesmo tipo',
        winning7Description: '4 cartas da mesma denominação e a carta Wild ou Joker.',
        winning8Label: '10s ou melhores',
        winning8Description:
          'Um Par só paga se as cartas do par forem Dez, Valetes, Damas, Reis ou Ases. Pares inferiores não pagam.',
        winning9Label: 'Flush',
        winning9Description: '5 cartões de denominação não consecutivos, todos do mesmo naipe',
        winning10Label: 'Full House',
        winning10Description:
          'Um conjunto de 3 cartas da mesma denominação, mais um conjunto de 2 cartas da mesma denominação.',
        winning11Label: 'HI Seqüência real',
        winning11Description:
          '5 cartas de denominações consecutivas, todas do mesmo naipe, começando de 10 e terminando com um ás.',
        winning12Label: 'Valetes ou melhores',
        winning12Description:
          'Um Par só paga se as cartas do par forem Valetes, Damas, Reis ou Ases. Pares inferiores não pagam.',
        winning13Label: 'Seqüência real com curingão',
        winning13Description:
          '5 cartas de denominações consecutivas, todas do mesmo naipe, a partir de 10 e terminando com um ás, preenchidas pela carta do Joker.',
        winning14Label: 'LO Seqüência real',
        winning14Description:
          '5 cartões de denominações consecutivas, todos do mesmo naipe, a partir de 2 e terminando com 6.',
        winning15Label: 'Seqüência real',
        winning15Description:
          '5 cartas de denominações consecutivas, todas do mesmo naipe, a partir de 10 e terminando com um ás sem o Wild ou o Joker.',
        winning16Label: 'Seqüência',
        winning16Description: '5 cartões de denominações consecutivas, não do mesmo naipe',
        winning17Label: 'Seqüência de mesmo naipe',
        winning17Description: '5 cartões de denominações consecutivas, todos do mesmo naipe',
        winning18Label: 'Seqüência real selvagem',
        winning18Description:
          '5 cartas de denominações consecutivas, todas do mesmo naipe, a partir de 10 e terminando com um ás, completadas pela carta Wild',
        title: 'Combinações vencedoras mostradas nas tabelas de prêmios para cada jogo de vídeo diferente',
      },
    },
    gameControls: {
      button1Description: 'O seletor de aumento de apostas lhe permite mudar o valor da moeda de sua aposta.',
      button2Label: 'Alterar coluna de prêmios',
      button2Description:
        'Pressione uma ou mais vezes para selecionar uma das 5 colunas da tabela de prêmios. Cada vez que você clicar, o número de prêmios será multiplicado por 1,2,3,4,5.',
      button3Label: 'Repartir',
      button3Description: "Uma vez que você colocou sua aposta pressione o botão de 'Repartir' para começar o jogo!.",
      button4Label: 'Dobrar',
      button4Description: "Pressione o botão de 'Dobrar' para começar a ronda de jogo dobro.",
      button5Label: 'Coletar',
      button5Description: "Pressione 'Coletar' para coletar seus ganhos.",
      description_1: 'Play button. Press to start a game',
      description_2: 'Draw button. After holding your chose cards, press to reveal new ones',
      description_3: 'Increase coin value',
      description_4: 'Decrease coin value',
      description_5: 'Double button. Click to enter the double playoff round',
      description_6: 'Collect button. Click to collect your winnings, and pass your chance to double your winnings',
      description_7: 'Clear button. Click to clear the game in order to start a new one',
      description_8: 'Show prize table. This button is only available in Multi, 18 and 50 games',
    },
  },
  zipTabs: {
    gameName: 'Zip Tabs',
    game: {
      introduction: {
        line1:
          'Fácil, divertido, e com muita cor. Este jogo tem que ser um de nossos jogos mais fáceis de jogar. O objetivo deste jogo é de obter três símbolos iguais na mesma linha de cartas.',
        title: 'Introdução',
      },
      howToPlay: {
        line1:
          'Selecione o custo da carta usando a seta de mais ou menos. Leve em conta que a tabela de pagamentos será mudada de acordo.',
        line2: 'Clique em compre uma carta.',
        line3: 'Clique em descascar todo, ou clique em descascar a linha individual.',
        line4: 'Para jogar de novo, clique em Compre carta de novo.',
        line5: 'O custo da carta será mantido até que você o mude.',
        title: 'Como Jogar',
      },
    },
    gameControls: {
      button1Label: 'Cascar tudo',
      button1Description: 'Pressione este botão para abrir as abas.',
      button2Label: 'Compre uma carta',
      button2Description: "Depois de definir seus pontos, pressione o botão 'Compre uma carta' para iniciar o jogo!.",
      button3Label: 'Seletor',
      button3Description: 'Este seletor de incremento de jogo permite que você defina o valor da moeda do seu jogo.',
    },
  },
  generalInfo: {
    introduction: {
      title: 'Introdução',
      line1:
        'Os nossos jogos são concebidos para serem fáceis de aprender e jogar. Apenas alguns conceitos simples permitem-lhe começar a correr em pouco tempo!',
      line2:
        'Esta Página de Ajuda que você está lendo agora mostrará como jogar nossos jogos, como personalizar os jogos de acordo com suas preferências pessoais e como obter informações sobre seu histórico de jogos. todos os jogos, e não entra em detalhes em nenhum jogo específico.',
    },
    gameInterface: {
      title: 'A interface do Jogo',
      line1: 'Cada jogo contém as seguintes áreas e / ou botões:',
      balance: {
        title: 'Exibições do saldo e da quantia jogada',
        line1:
          'No canto inferior esquerdo estão dois monitores que mostram o seu saldo atual disponível para uso - o dinheiro que está disponível para jogar. Jogado é a quantidade de dinheiro que você acabou de usar em um jogo ou está prestes a usar no jogo atual.',
      },
      message: {
        title: 'Mensagens',
        line1:
          'No centro da janela na parte inferior, são exibidas as mensagens do jogo, como se você ganhou e quanto ganhou, etc.',
      },
      options: {
        title: 'Botão Opções',
        line1:
          'Clicar no botão Options na área inferior direita da tela mostrará a janela Game Options. Você pode personalizar o jogo das seguintes formas:',
        speed: {
          title: 'Velocidade do Jogo',
          line1:
            'Os jogos têm uma configuração de velocidade entre 1 e 3. A configuração padrão é 2. Velocidades mais rápidas fazem com que as animações acelerem para que você possa jogar mais rápido.',
        },
        color: {
          title: 'Cor da tabela',
          line1: 'Aplicável apenas aos jogos de mesa, esta opção permite que você escolha a aparência do seu jogo.',
        },
        sounds: {
          title: 'Sons',
          line1: 'Você pode transformar vozes e efeitos sonoros separadamente.',
        },
        soundFx: {
          audio: 'Audio',
          title: 'Som',
          line1: 'Você pode alternar os efeitos sonoros.',
        },
        voices: {
          title: 'Vozes',
          line1: 'Você pode alternar vozes.',
        },
        yes: 'Yes',
        no: 'No',
        options: 'opções',
        settings: 'configurações',
      },
      history: {
        title: 'Botão Histórico',
        line1:
          'Clicar neste botão fará com que seja exibida uma janela pop-up contendo uma conta abrangente de todas as suas jogadas e resultados para o jogo atual.',
      },
      help: {
        title: 'Botão Ajuda',
        line1:
          'Clicar no botão Ajuda faz com que seja exibida uma janela pop-up contendo a Ajuda do jogo atual que está carregado.',
      },
    },
    playSequence: {
      title: 'A Sequência do Jogo',
      line1:
        'Jogos de mesa são jogados abrindo jogos, escolhendo limites de mesa, colocando fichas na mesa, jogando e observando as escolhas dos jogos de resultado.',
      line2:
        'Os jogos de slot / keno são jogados abrindo e escolhendo o valor da moeda, o número de linhas, clicando e observando o resultado de suas escolhas de jogo.',
    },
  },
}