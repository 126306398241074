import React from 'react'
import { addLocaleData, IntlProvider, FormattedMessage } from 'react-intl'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import { flattenMessages, getParameterByName } from '../utils'
import es from 'react-intl/locale-data/es'
import en from 'react-intl/locale-data/en'
import pt from 'react-intl/locale-data/pt'
import vi from 'react-intl/locale-data/vi'
import messages_sportbooks_en from '../translations/sportbooks.en'
import messages_sportbooks_vi from '../translations/sportbooks.vi'
import messages_sweepstakes_en from '../translations/sweepstakes.en'
import messages_sweepstakes_vi from '../translations/sweepstakes.vi'
import messages_es from '../translations/es'
import messages_pt from '../translations/pt'
import Header from './Header'

import {
  BACCARAT,
  BLACKJACK,
  CASINO_WAR,
  CRAPS,
  CRAZY_POKER,
  HOOHEYHOW,
  KENO,
  KEEP_EM_POKER,
  PAIGOW,
  POKER_THREE,
  RED_DOG,
  ROULETTE,
  SIC_BO,
  SLOTS,
  SLOT_1_LINE_MAGIC,
  STUD,
  VIDEOPOKER,
  ZIPTAB,
  SPORTBOOKS,
  SWEEPSTAKES,
  FLASH,
  H5
} from '../constants'

const GeneralInfo = React.lazy(() => import('./GeneralInfo'))
const GeneralInfoSlot1Line = React.lazy(() => import('./GeneralInfoSlot1Line'))

const BaccaratGame = React.lazy(() => import('../games/baccarat/Game'))
const BaccaratControls = React.lazy(() => import('../games/baccarat/Controls'))
const BlackjackGame = React.lazy(() => import('../games/blackjack/Game'))
const BlackjackControls = React.lazy(() => import('../games/blackjack/Controls'))
const CasinoWarGame = React.lazy(() => import('../games/casinoWar/Game'))
const CasinoWarControls = React.lazy(() => import('../games/casinoWar/Controls'))
const CrapsGame = React.lazy(() => import('../games/craps/Game'))
const CrapsControls = React.lazy(() => import('../games/craps/Controls'))
const CrazyPokerGame = React.lazy(() => import('../games/crazyPoker/Game'))
const CrazyPokerControls = React.lazy(() => import('../games/crazyPoker/Controls'))
const HooHeyHowGame = React.lazy(() => import('../games/hooHeyHow/Game'))
const HooHeyHowControls = React.lazy(() => import('../games/hooHeyHow/Controls'))
const KenoGame = React.lazy(() => import('../games/keno/Game'))
const KenoControls = React.lazy(() => import('../games/keno/Controls'))
const KeepEmPokerGame = React.lazy(() => import('../games/keepEmPoker/Game'))
const KeepEmPokerControls = React.lazy(() => import('../games/keepEmPoker/Controls'))
const PaigowGame = React.lazy(() => import('../games/paigow/Game'))
const PaigowControls = React.lazy(() => import('../games/paigow/Controls'))
const PokerThreeGame = React.lazy(() => import('../games/pokerThree/Game'))
const PokerThreeControls = React.lazy(() => import('../games/pokerThree/Controls'))
const RedDogGame = React.lazy(() => import('../games/redDog/Game'))
const RedDogControls = React.lazy(() => import('../games/redDog/Controls'))
const RouletteGame = React.lazy(() => import('../games/roulette/Game'))
const RouletteControls = React.lazy(() => import('../games/roulette/Controls'))
const SicBoGame = React.lazy(() => import('../games/sicBo/Game'))
const SicBoControls = React.lazy(() => import('../games/sicBo/Controls'))
const StudGame = React.lazy(() => import('../games/stud/Game'))
const StudControls = React.lazy(() => import('../games/stud/Controls'))
const ZipTabGame = React.lazy(() => import('../games/zipTab/Game'))
const ZipTabControls = React.lazy(() => import('../games/zipTab/Controls'))

const SlotGame = React.lazy(() => import('../games/slots/Game'))
const SlotControls = React.lazy(() => import('../games/slots/Controls'))
const SlotOneLineMagicGame = React.lazy(() => import('../games/slotOneLineMagic/Game'))
const SlotOneLineMagicControls = React.lazy(() => import('../games/slotOneLineMagic/Controls'))
const VideoPokerGame = React.lazy(() => import('../games/videoPoker/Game'))
const VideoPokerControls = React.lazy(() => import('../games/videoPoker/Controls'))

// These games have separate flash files, which will be easy to remove once those games are dead.
const SlotGameFlash = React.lazy(() => import('../games/slots/GameFlash'))
const SlotControlsFlash = React.lazy(() => import('../games/slots/ControlsFlash'))
const SlotOneLineMagicGameFlash = React.lazy(() => import('../games/slotOneLineMagic/GameFlash'))
const SlotOneLineMagicControlsFlash = React.lazy(() => import('../games/slotOneLineMagic/ControlsFlash'))
const VideoPokerGameFlash = React.lazy(() => import('../games/videoPoker/GameFlash'))
const VideoPokerControlsFlash = React.lazy(() => import('../games/videoPoker/ControlsFlash'))


const technology = getParameterByName('technology') || FLASH

let messages = {
  en: messages_sweepstakes_en,
  es: messages_es,
  pt: messages_pt,
  vi: messages_sweepstakes_vi,
}

addLocaleData([...en, ...es, ...pt, ...vi])

const styles = {
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 10,
  },
  wrapper: {
    maxWidth: 1200,
    margin: 'auto',
  },
}

const TabContainer = ({ children, dir }) => {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  )
}

class RulesWrapper extends React.Component {
  state = {
    value: 0,
    gamePage: null,
    controlsPage: null,
  }

  handleChange = (event, value) => {
    this.setState({ value })
  }

  handleChangeIndex = index => {
    this.setState({ value: index })
  }

  componentDidMount() {
    let gamePage = null
    let controlsPage = null
    const generalInfo =
      this.props.game === SLOT_1_LINE_MAGIC ? (
        <GeneralInfoSlot1Line />
      ) : (
        <GeneralInfo />
      )
    switch (this.props.game) {
      case BACCARAT: {
        gamePage = <BaccaratGame />
        controlsPage = <BaccaratControls />
        break
      }
      case BLACKJACK: {
        gamePage = <BlackjackGame />
        controlsPage = <BlackjackControls />
        break
      }
      case CASINO_WAR: {
        gamePage = <CasinoWarGame />
        controlsPage = <CasinoWarControls />
        break
      }
      case CRAPS: {
        gamePage = <CrapsGame />
        controlsPage = <CrapsControls />
        break
      }
      case CRAZY_POKER: {
        gamePage = <CrazyPokerGame />
        controlsPage = <CrazyPokerControls />
        break
      }
      case HOOHEYHOW: {
        gamePage = <HooHeyHowGame />
        controlsPage = <HooHeyHowControls />
        break
      }
      case KENO: {
        gamePage = <KenoGame />
        controlsPage = <KenoControls />
        break
      }
      case KEEP_EM_POKER: {
        gamePage = <KeepEmPokerGame />
        controlsPage = <KeepEmPokerControls />
        break
      }
      case PAIGOW: {
        gamePage = <PaigowGame />
        controlsPage = <PaigowControls />
        break
      }
      case POKER_THREE: {
        gamePage = <PokerThreeGame />
        controlsPage = <PokerThreeControls />
        break
      }
      case RED_DOG: {
        gamePage = <RedDogGame />
        controlsPage = <RedDogControls />
        break
      }
      case ROULETTE: {
        gamePage = <RouletteGame />
        controlsPage = <RouletteControls />
        break
      }
      case SIC_BO: {
        gamePage = <SicBoGame />
        controlsPage = <SicBoControls />
        break
      }
      case SLOT_1_LINE_MAGIC: {
        gamePage = technology === H5 ? <SlotOneLineMagicGame /> : <SlotOneLineMagicGameFlash />
        controlsPage = technology === H5 ? <SlotOneLineMagicControls /> : <SlotOneLineMagicControlsFlash />
        break
      }
      case SLOTS: {
        gamePage = technology === H5 ? <SlotGame /> : <SlotGameFlash />
        controlsPage = technology === H5 ? <SlotControls /> : <SlotControlsFlash/>
        break
      }
      case STUD: {
        gamePage = <StudGame />
        controlsPage = <StudControls />
        break
      }
      case VIDEOPOKER: {
        gamePage = technology === H5 ? <VideoPokerGame /> : <VideoPokerGameFlash />
        controlsPage = technology === H5 ? <VideoPokerControls /> : <VideoPokerControlsFlash />
        break
      }
      case ZIPTAB: {
        gamePage = <ZipTabGame />
        controlsPage = <ZipTabControls />
        break
      }
      default: {
        return null
      }
    }
    this.setState({
      gamePage,
      controlsPage,
      generalInfo,
    })
  }

  render() {
    const { language, game, allowedLanguages, classes } = this.props
    const { value, gamePage, controlsPage, generalInfo } = this.state

    const technology = getParameterByName('technology') || FLASH
    const isH5 = technology.toLowerCase() === H5 ? true : false

    const platform = getParameterByName('platform') || SPORTBOOKS
    const isSweepstakes = platform.toLowerCase() === SWEEPSTAKES
    if (!isSweepstakes) {
      messages = {
        en: messages_sportbooks_en,
        es: messages_es,
        pt: messages_pt,
        vi: messages_sportbooks_vi,
      }
    }

    return (
      <IntlProvider
        locale={language}
        messages={flattenMessages(messages[language])}
      >
        <React.Suspense fallback={<div/>}>
        <div>
          <Header
            name={game}
            language={language}
            allowedLanguages={allowedLanguages}
          />

          <div>
            <Paper className={classes.root}>
              <Tabs
                value={this.state.value}
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab label={<FormattedMessage id="menu.theGame" />} />
                <Tab label={<FormattedMessage id="menu.theControls" />} />
                {!isH5 && <Tab label={<FormattedMessage id="menu.generalInfo" />} />}
              </Tabs>
            </Paper>
            {value === 0 && (
              <TabContainer>
                <Paper elevation={0} className={classes.wrapper}>
                  {gamePage}
                </Paper>
              </TabContainer>
            )}
            {value === 1 && (
              <TabContainer>
                <Paper elevation={0} className={classes.wrapper}>
                  {controlsPage}
                </Paper>
              </TabContainer>
            )}
            {!isH5 && value === 2 && (
              <TabContainer>
                <Paper elevation={0} className={classes.wrapper}>
                  {generalInfo}
                </Paper>
              </TabContainer>
            )}
          </div>
        </div>
        </React.Suspense>
      </IntlProvider>
    )
  }
}

export default withStyles(styles)(RulesWrapper)
