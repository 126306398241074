import React  from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import RulesWrapper from './components/RulesWrapper'
import {EN, ES, PT, VI, BACCARAT, BLACKJACK, CASINO_WAR, CRAPS, CRAZY_POKER,
  HOOHEYHOW, KENO, ROULETTE, KEEP_EM_POKER, PAIGOW, POKER_THREE, SLOT_1_LINE_MAGIC,
  RED_DOG, SIC_BO, SLOTS, STUD, VIDEOPOKER, ZIPTAB} from './constants'
import ErrorHeader from './components/ErrorHeader'
import Home from './components/Home'

const allowedLanguages = [EN, ES, PT, VI]
const allowedGames = [
  BACCARAT,
  BLACKJACK,
  STUD,
  CASINO_WAR,
  CRAPS,
  CRAZY_POKER,
  HOOHEYHOW,
  KEEP_EM_POKER,
  KENO,
  PAIGOW,
  POKER_THREE,
  RED_DOG,
  ROULETTE,
  SIC_BO,
  SLOT_1_LINE_MAGIC,
  SLOTS,
  VIDEOPOKER,
  ZIPTAB
]

const App = () => {
  let languageIsAllowed = false
  let gameIsAllowed = false
  let language = EN
  return (
    <Switch>
      <Route path="/" exact render={() => <Home allowedLanguages={allowedLanguages} allowedGames={allowedGames} />} />
      <Route path="/404" exact render={() => <ErrorHeader primary="404 Rules not found" secondary="Please check your params" />} />
      <Route
        path="/:language?/:game?"
        render={({match}) => {
          const {language: paramLanguage, game} = match.params
          allowedLanguages.map(x => {
            if(!languageIsAllowed) {
              languageIsAllowed = x === paramLanguage
            }
            return null
          })

          // default is EN, only apply language if we've implemented it
          if (languageIsAllowed) {
            language = paramLanguage
          }

          allowedGames.map(x => {
            if(!gameIsAllowed) {
              gameIsAllowed = x === game
            }
            return null
          })
          if (!gameIsAllowed) {
            return <Redirect to="/404" />
          }
          return <RulesWrapper game={game} language={language} allowedLanguages={allowedLanguages}/>
        }}/>
    </Switch>
  )
}

export default App
