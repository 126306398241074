import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter} from 'react-router-dom'
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles'
import ReactGA from 'react-ga'
import App from './App'
import registerServiceWorker from './registerServiceWorker'
import theme from './themes/tazpool'
import './index.css'

const appTheme = createMuiTheme(theme)

ReactDOM.render((
  <BrowserRouter>
    <MuiThemeProvider theme={appTheme}>
      <App />
    </MuiThemeProvider>
  </BrowserRouter>
), document.getElementById('root'))
registerServiceWorker()

ReactGA.initialize('UA-52087457-18')
ReactGA.pageview(window.location.pathname + window.location.search)
