export default {
  menu: {
    theGame: 'El Juego',
    theControls: 'Los controles',
    generalInfo: 'Información general',
    buttonControl: 'Botón',
    description: 'Descripción',
  },
  baccarat: {
    gameName: 'Baccarat',
    game: {
      introduction: {
        line1:
          'El Baccarat se considera un juego de apuestas altas, y generalmente se juega a puertas cerradas o en secciones acordonadas de un casino',
        line2:
          "Recientemente, se desarrolló una variación llamada 'Mini Baccarat' para popularizar el juego en los Estados Unidos y en línea. El Mini-Baccarat se juega en una mesa estándar de tamaño Blackjack. Esta es la versión que se ofrece aquí y usa 8 mazos.",
        title: 'Introducción',
      },
      basicRules: {
        line1:
          'La finalidad del Baccarat es la de tener una baza con una suma de cartas que se acerque al 9. Los ases valen un punto; las cartas de 2 a 9 cuentan por su mismo valor y las cartas 10 y figuras cuentan cero (véase tabla aquí al lado). Si el valor total de la baza es igual o superior a 10, resta 10 para obtener el valor de la baza. Por ejemplo la baza que está compuesta por un 8 y un 6, tendrá un valor de 4 (8+6=14,14-10=4); si la baza está compuesta por un 3 y un 7 tendrá el valor de cero (3+7=10,10-10=0).',
        line2:
          'Colocar vuestra apuesta sobre el área del Banco y/o sobre el área del Jugador y/o en el área de Empate.',
        line3: 'El jugador más cercano a 9 gana.',
        line4: 'Si apostáis sobre Jugador y el Jugador gana se os pagará 2 a 1. ',
        line5:
          'Si apostáis sobre el Banco y éste gana se os pagará 2 a 1, menos el 5% de el premio por la comisión del Banco (ejemplo: ganando con una apuesta de 10 obtendréis vuestros 10 más los 10 de el premio, menos el 5% (igual a 9.5) según el siguiente cálculo 10 + 9.5 = 19.5). ',
        line6:
          'Si el jugador y el Banco tienen un valor de la baza igual, el resultado es un empate. Si habéis colocado la apuesta en Empate ganaréis por un total de 8 a 1 (apostando 10 el premio será de 10 + 80 =90).',
        title: 'Reglas Principales',
        cardTable: {
          card: 'Cartas',
          value: 'Valor',
          faceValue: 'Figuras',
          card1Label: 'Ases',
          card2Label: 'Cartas 2-9',
          card3Label: '10s',
          card4Label: 'Face Cards',
        },
      },
      thirdCardRules: {
        line1:
          'En algunos casos después de haber dado dos cartas al Jugador y al Banco se le dará una tercera carta al Jugador y/o al Banco .Las tablas siguientes muestran cuándo sucede esto:',
        line2:
          'Después de que el Jugador toma la tercera carta del Jugador, el Banco toma la tercera carta siguiendo la tabla siguiente:',
        line3:
          'NOTA: Si el Jugador y el Banco tienen un total de 8 o 9 ambos se quedan sin coger la tercera carta. Esta regla anula todas las demás reglas de tomar la tercera carta.',
        title: 'Regla de la Tercera Carta',
        from: 'de',
        to: 'a',
        or: 'o',
        playerHandTable: {
          value: 'Si la baza del Jugador es:',
          then: 'Entonces el Jugador:',
          playerHand1Then: 'Toma la tercera carta.',
          playerHand2Then: 'Permanece parado.',
          playerHand3Then: "Permanece parado 'Natural Hand'.",
        },
        bankerHandTable: {
          value: 'Si la baza del  Banco es:',
          if: 'Banco toma la tercera carta si el Jugador tiene:',
          then: 'Banco no coge carta si la tercera carta del Jugador es:',
          bankerHand1If: 'Una carta cualquiera.',
          bankerHand6If: 'Permanece parado.',
          bankerHand7If: "Permanece parado 'Natural Hand'.",
        },
      },
    },
    gameControls: {
      button1Label: 'Nuevo juego',
      button1Description: 'Iniciar un nuevo juego.',
      button2Label: 'Limpiar',
      button2Description: 'Poner en cero el monto a jugar.',
      button3Label: 'Mismo monto',
      button3Description: 'Repite el juego con el monto anterior.',
      button4Label: 'Revelar',
      button4Description: 'Pedirle al croupier que distribuya las cartas.',
    },
  },
  blackjack: {
    gameName: 'Blackjack',
    game: {
      introduction: {
        line1:
          'La historia del Blackjack es todavía objeto de discusión pero, probablemente, se trata de una innovación de otros juegos franceses como el ’Chemin de fer’ y el ’ France Ferme’.  El Blackjack tuvo inicio en los casinos franceses en 1700 y se llamaba entonces Veintiuno, mientras que en los EE.UU fue introducido en 1800. Blackjack (valet negro) se llama así porque si un jugador tiene un jack de picas como primeras dos cartas (las picas son de color negro), el jugador ganaba más. El Blackjack también puede tener muchas variaciones, como cuando un jugador puede doblar, pagar un Blackjack, cuando el crupier golpea o se detiene tan bien como otros.',
        title: 'Introducción',
      },
      basicRules: {
        line1:
          'El objetivo de Blackjack es totalizar una baza que sea lo más cercana posible a 21, respecto a la baza de la banca sin superar nunca 21.',
        line2:
          'En el Blackjack los ases cuentan 1 o bien 11, las figuras cuentan 10 y las cartas numeradas cuentan su mismo valor.',
        line3:
          'Si recibís un as y una carta con valor de 10 como primeras dos cartas de la baza, realizáis un Blackjack y ganáis una vez y media vuestra jugada/apuesta (Por ejemplo si apostáis 10 recibiréis vuestros 10 más 15 por un total de 25). Si el total de vuestras cartas es más cercano a 21, superando la banca ganaréis 1 a 1 lo que habéis apostado por ejemplo si apostáis 10 recibiréis vuestros 10 más 10 por un total de 20). Si el total de las dos cartas es superior a 21 os pasáis (Bust) y perdéis vuestra apuesta. Si tenéis una suma de puntos igual a la banco (de 17 para arriba) ninguno de los dos gana y la apuesta será devuelta. El Blackjack (compuesto de sólo dos cartas gana a cualquier baza que haya totalizado 21).',
        title: 'Reglas Principales',
      },
      split: {
        line1:
          'Si las dos primeras cartas del jugador tienen el mismo valor, se puede dividirlas (Split), considerarlas como si fueran dos bazas separadas, añadiendo una apuesta igual a la inicial sobre cada baza.',
        line2:
          'Luego se procederá a tomar las cartas. Si se dividen dos ases y una de las dos bazas totaliza 21 se cuenta como 21 y no como Blackjack. Si se dividen ases, solo se reparte una carta adicional. Solo se permite una división por mano en estos juegos, no se puede volver a dividir.',
        title: 'Divide',
      },
      double: {
        line1:
          'Después de recibir dos cartas y cree que una carta adicional le permitirá ganarle a las cartas mostradas por el croupier, puede aumentar su monto de juego iniciales en la misma cantidad que su jugada original. Su jugada original se duplicó, y usted recibirá solo una carta más. Puede haber variaciones adicionales cuando se puede duplicar. Ejemplo: en cualquier momento después de las primeras 2 cartas, el total de las cartas está entre 8 y 11, o el total de cartas es 10 u 11.',
        line2:
          'Por favor, verifique parámetros de información del juego cuando pases el mouse sobre un ícono del juego',
        title: 'Doble',
      },
      insurance: {
        line1:
          'El Seguro se ofrece solo cuando la primera carta presentada es un As. El seguro se considera como un juego propio dentro de BlackJack. Le permite intentar protegerse cuando piensa que las cartas del croupier se muestran en la mesa puede ser un BlackJack. Al comprar un seguro, coloca la mitad del monto de juego inicial presionando el botón de Seguro. Si las cartas del croupier muestran BlackJack, se le paga de 2 a 1 en su juego de Seguro. ',
        line2:
          'Ejemplo: si juegas 10 y aseguras 5, si las cartas que se muestran revelan BlackJack si empatas o pierdes con tus cartas, ganarás 5 más 10 para un total de 15. Si las cartas que se muestran en la parte superior de la mesa revelas un BlackJack, pierdes el monto jugado en el Seguro. ',
        title: 'Seguro',
      },
      checkingForBlackJack: {
        line1:
          'Si la primera de las 2 cartas del croupier en la parte superior de la mesa es un as, la segunda carta será revisada o se asomará cuando esté boca abajo. Si la segunda carta presentada es una figura o un 10 (BlackJack), la carta se volteará, mostrará el valor y el juego terminará. Sin embargo, si la segunda carta que se muestra en la parte superior de la mesa no completa un BlackJack, la carta permanece boca abajo y el juego continúa.',
        line2:
          'Si la primera carta que se muestra en la parte superior de la tabla es una 10 o una figura, no se ofrecerá seguro, la segunda carta será revisada (asomada) y si es un as la carta dará la vuelta, mostrará el valor y el juego finalizará. Esas cartas ganan. Sin embargo, si la segunda carta que se muestra en la parte superior de la mesa no completa un BlackJack, la carta permanece boca abajo y el juego continúa.',
        title: 'Control Blackjack',
      },
      variations: {
        line1:
          'Hay muchas variaciones de Blackjack y tenga en cuenta lo siguiente para los juegos que se ofrecen para su disfrute aquí:',
        title: 'Variaciones',
        blackjackSwitch: {
          title: 'Blackjack Switch',
          line1:
            'Paga una cantidad igual al monto inicial cuando el jugador obtiene un Blackjack, debe jugar 2 manos, la segunda carta de cada mano puede cambiarse con la otra mano. Si las cartas de los concesionarios suman 22 y el jugador no ha reventado, el juego es un empate y el jugador gana los premios originales.',
        },
        blackjackDoubleExposure: {
          title: 'Blackjack Double Exposure',
          line1:
            'Los Blackjacks pagan una cantidad igual al monto inicial, todas las cartas se reparten boca arriba, el Dealer ganará en todas los empates, excepto si ambas manos son Blackjack entonces se retorna el monto inicial.',
        },
        winningsTable: {
          winnings: 'Ganancias',
          pays: 'Pagos',
          win1Label: 'Mano ganadora',
          win2Label: 'Seguro',
          win3Label: 'Blackjack',
        },
      },
    },
    gameControls: {
      button1Label: 'Nuevo juego',
      button1Description: 'Iniciar un nuevo juego.',
      button2Label: 'Limpiar',
      button2Description: 'Poner en cero el monto a jugar.',
      button3Label: 'Mismo monto',
      button3Description: 'Repite el juego con el monto anterior.',
      button4Label: 'Revelar',
      button4Description: 'Pedirle al croupier que distribuya las cartas.',
      button5Label: 'Seguro',
      button5Description: 'Activa el seguro por la mitad del monto original.',
      button6Label: 'Dividir',
      button6Description: 'Divide la mano.',
      button7Label: 'Pedir',
      button7Description: 'Indica al croupier que le reparta otra carta.',
      button8Label: 'Parar',
      button8Description: 'Indica al croupier que no quiere más cartas.',
    },
  },
  casinoWar: {
    gameName: 'Casino War',
    game: {
      introduction: {
        line1:
          "Casino War es conocido también como 'Batalla Real'. Se basa sobre un juego de cartas para niños llamado Guerra. Tanto el jugador como la banca reciben una carta descubierta. La carta más alta gana y cuando hay un empate el jugador puede rendirse o declarar guerra para intentar ganar.",
        title: 'Introducción',
      },
      howToPlay: {
        line1: 'Casino War se juega con una baraja de 52 cartas.',
        line2: 'Las cartas se evalúan como en el póquer con excepción de los ases que son siempre la carta más alta.',
        line3: 'En Casino War los palos de las cartas no tienen valor.',
        line4:
          'Se comienza colocando una apuesta en el punto preparado sobre la mesa. Después, presione el botón Revelar, se dará una carta descubierta tanto al jugador como al banco. Si la carta del jugador es superior a la de la banca el jugador gana 1 a 1; si la carta de la banca es superior a la del jugador, el jugador pierde su apuesta.',
        line5: 'Cuando las cartas tienen valores iguales el jugador tiene dos posibilidades:',
        title: 'Cómo se juega',
      },
      surrender: {
        line1: 'Significa que el jugador se rinde y recibe la mitad del monto jugado.',
        title: 'Rendirse',
      },
      goToWar: {
        line1: 'El jugador agrega un monto igual al monto inicial y le es dada otra carta descubierta.',
        line2: 'La banca descarta tres cartas cubiertas y se da una descubierta.',
        line3:
          'Si la segunda carta del jugador bate al banco, el jugador es pagado 1 a 1 sólo en el segundo monto y el monto inicial es devuelto.',
        title: 'Declarar Guerra',
      },
      tiePlays: {
        line1:
          'Es posible poner un monto opcional sobre el Empate. Este monto se paga 10 a 1 si la primera carta del jugador y la primera carta descubierta de la banca tienen un valor igual. En cualquier otro caso el monto sobre el Empate se pierde.',
        title: 'Empate',
      },
    },
    gameControls: {
      button1Label: 'Nuevo juego',
      button1Description: 'Iniciar un nuevo juego.',
      button2Label: 'Limpiar',
      button2Description: 'Poner en cero el monto a jugar.',
      button3Label: 'Mismo monto',
      button3Description: 'Repite el juego con el monto anterior.',
      button4Label: 'Revelar',
      button4Description: 'Pedirle al croupier que distribuya las cartas.',
      button5Label: 'Rendirse',
      button5Description: 'Rendirse cuando el resultado es un empate.',
      button6Label: 'Guerra',
      button6Description: 'Declarar guerra cuando el resultado es un empate.',
    },
  },
  craps: {
    gameName: 'Craps',
    game: {
      introduction: {
        line1:
          'Craps, o juego de los Dados es un juego de azar que tiene orígenes muy antiguos. Consiste en adivinar el resultado de los dados lanzados.',
        title: 'Introducción',
      },
      comeOut: {
        line1:
          "Un nuevo juego, en el juego del 'Craps', comienza siempre con el lanzamiento de apertura que es el primer lanzamiento del que tira. La apuesta más sencilla y común en el juego del Craps es la apuesta sobre la línea Pass (o bien su opuesta apuesta llamada apuesta sobre la línea Don’t Pass).",
        line2:
          'En el lanzamiento de apertura una apuesta sobre la línea Pass gana si el tirador saca un 7 o un 11 (tiro llamado natural), y pierde su apuesta si el tiro es un 2, 3 o 12 (llamado “Craps”). Si el tirador saca un 4, 5, 6, 8, 9 o 10 en el lanzamiento de apertura este número serán los puntos del tirador que el croupier indicará sobre la mesa con un puck (disquete) blanco o negro que se coloca con la parte blanca vuelta hacia arriba en el lugar de la mesa que indica ese número. El nuevo objetivo del tirador es sacar otra vez el mismo número para poder ganar (llamado Pass) antes de que saquen un 7 (que le haría perder la apuesta).',
        line3:
          "La apuesta Don't Pass, al ser lo opuesto de la apuesta sobre la línea Pass, pierde con un 7 o 11, y gana con un 2 o 3. Para una apuesta Don’t Pass un 2 es considerado un “push” (un empate), así la apuesta no se considera ni ganada ni perdida. Si el tirador saca un 4, 5, 6, 8, 9, o 10, este número se convierte en sus nuevos puntos y el tirador tiene que sacar un 7 (para ganar) antes de sacar nuevamente el número de puntos (que perdería la apuesta).",
        title: "Lanzamiento de salida / Apuestas Pass Line y Don't Pass",
      },
      come: {
        line1:
          "Una apuesta Come es prácticamente igual a la apuesta sobre la línea Pass. Del mismo modo, también la apuesta Don't Come es similar a la apuesta del Don't Pass. La única diferencia es que las apuestas sobre la línea Pass y Don't Pass pueden ser realizadas solamente antes del lanzamiento de apertura, mientras que las apuestas Come y Don't Come pueden ser realizadas solamente después de que se haya establecido una puntuación. Si el jugador saca un 4, 5, 6, 8, 9 o 10 en el lanzamiento de apertura, el juego continúa pero el jugador no tendrá ya la posibilidad de colocar apuestas sobre la línea Pass y sobre la línea Don’t Pass. Es en este momento cuando se pueden hacer las apuestas Come y Don’t Come.",
        line2:
          "Una apuesta Come gana si el lanzamiento sucesivo es un  7 o 11, como en el primer lanzamiento para una apuesta sobre la línea Pass. La apuesta se pierde si el jugador lanza un 2, 3, o 12. Con cualquier otro número la apuesta se desplaza de la gran zona “Come” sobre la mesa al área más pequeña que contiene ese número situada apenas por encima de la zona “Come”. Una vez que una apuesta ha sido desplazada a una de estas áreas más pequeñas se gana la apuesta cuando el jugador saca de nuevo el mismo número, o bien se pierde si se saca antes un 7. La apuesta Don't Come es opuesta a la apuesta Come, que pierde con un 7 o con 11, y gana con un 2 o con 3. Como en la apuesta Don't pass, un 12 se considera un “push” (un empate) y la apuesta Don't come no es ganada ni perdida. Con cualquier otro número, la apuesta es desplazada a detrás del área que contiene ese número en el área Don't Come. Se ganará esta apuesta si se saca un 7 antes de sacar de nuevo este número, o se perderá la apuesta si se saca antes el número.",
        title: "Apuesta Come y Don't Come",
      },
      oddsBets: {
        line1:
          "La apuesta Odds puede hacerse sólo después de haber hecho una apuesta sobre la línea Pass, Don't Pass, una apuesta Come, o bien una apuesta Don't Come, y se establece una puntuación. Una vez que se haya establecido la puntuación para vuestra apuesta sobre la línea Pass o Come, podréis colocar una apuesta Odds hasta el máximo Odds para ese número (ver la tabla de abajo). La apuesta Odds se gana o se pierde cuando  la apuesta sobre la línea Pass asociada, Don't Pass, Come o Don't Come gana o pierde. La diferencia es que el jugador es pagado realmente sobre la apuesta Odds cuando gana. Por ejemplo, los Odds reales por un 4 y un 10 son 2:1 (2 a 1). Suponemos que el jugador tenga un punto establecido de 4 con una apuesta sobre la línea Pass de  5 fichas y una apuesta Odds de 10 fichas sobre la línea Pass. Si el punto sucesivo es realizado sacando otro 4, el jugador ganaría una suma igual (1:1) a la apuesta sobre la línea Pass (5 fichas) pero ganaría los reales Odds 2:1 sobre la apuesta Odds (de 20 fichas).",
        point: 'Puntos',
        trueOdds: 'Pagos reales',
        and: 'y',
        title: 'Apuesta Odds',
      },
      maximumOdds: {
        line1:
          'La apuesta máxima en Odds que puede ser colocada una apuesta Pass Line o una apuesta Come depende de la puntuación, que puede ser:',
        line2:
          'La apuesta máxima en Odds para la apuesta Don’t Pass Line o una apuesta Don’t Come es seis veces la apuesta base.',
        point: 'Puntos',
        maximumOdds: 'Pagos máximos',
        and: 'y',
        times: 'Veces',
        title: 'Apuesta Odds',
      },
      oddsOnTheDontCome: {
        line1:
          "Cuando el jugador quiere Odds sobre una apuesta Don't Come o Don't Pass, a esto se le llama “colocar los Odds” (literal: “laying odds”) y la acción opuesta se llama “retirar los Odds” (literal: “taking Odds”) con una apuesta Come o una apuesta sobre la línea Pass. Sin embargo, ya que las apuestas  Don't Come y Don't Pass requieren que se saque un 7 antes de la puntuación, los Odds Reales para las apuestas Don't Come y Don't Pass son lo opuesto a las apuestas Come y línea Pass. Por ejemplo, los Odds Reales para un 4 y un 10 son 2:1, que significa que los Odds sobre una apuesta Don't Come y Don't Pass pagarán 1:2. Supongamos que el jugador tiene una puntuación de 4 establecida con una apuesta Don’t Pass de 5 fichas y una apuesta Odds de 10 fichas sobre la apuesta Don't Pass. Si el jugador sacara un 7 antes de sacar otro 4, ganaría una cantidad igual (1:1) sobre la apuesta Don't Pass (de 5 fichas) y 1:2 sobre la apuesta Odds (de 5 fichas).",
        title: "Odds sobre las apuestas Don't Come o Don't Pass",
      },
      placeBets: {
        line1:
          'Las apuestas Place le permiten al jugador apostar que el número (elegido) saldrá antes de que salga un 7. Si sale antes un 7, la apuesta se perderá. Esta apuesta es similar a una apuesta Come o a una apuesta sobre la línea Pass, a parte de que el número es elegido por el jugador (y no se basa sobre el resultado del lanzamiento sucesivo) y no hay ninguna posibilidad de ganar con un 7 o un 11 con el lanzamiento sucesivo, o perder con un 2, 3, o 12. Los únicos números que el jugador puede elegir son: 4, 5, 6, 8, 9 y 10. Las apuestas Place pueden ser hechas en el área que está debajo del área donde se encuentra escrito el número sobre el cual se quiere apostar. Las apuestas Place tienen que ser hechas en incrementos de 5 fichas sobre los números  4, 5, 9 y 10, y en incrementos de 6 fichas sobre los números  6 y 8. El motivo de esto es que los Odds son 9:5 para el 4 y 10, 7:5 para el 5 y 9, y 7:6 para el 6 y 8.',
        title: "Apuestas Place Bets y Don't Place",
      },
      buyBets: {
        line1:
          'Una apuesta Buy es similar a una apuesta Place. La apuesta Buy declara que el número sobre el cual habéis apostado saldrá antes que el número 7. Sin embargo, la apuesta Buy le ofrece al jugador los Odds Reales sobre la apuesta haciéndoles pagar una comisión del 5% (llamada literalmente “vigorish”). La comisión es pagada en el momento en que se hace la apuesta. Por ejemplo, si el jugador quiete hacer una apuesta Buy de 20 fichas sobre el número 10, tiene que hacer la apuesta de 21 fichas = 20 fichas más 1 ficha (que es la comisión del 5%). Si gana el lanzamiento, al jugador se le pagará por los Odds Reales (2:1 por el 10) sobre 20 fichas que sería igual a 40 fichas. Esta versión del juego ‘Craps’ asume que cada apuesta Buy hecha incluye la comisión del 5%, que es calculada dividiendo la suma de la apuesta por 1,05 (21 ÷ 1,05 = 20).',
        title: 'Apuestas Buy',
      },
      layBets: {
        line1:
          'Una apuesta Lay es lo contrario de una apuesta Buy. Con una apuesta Lay, el jugador espera que el número 7 salga antes que el número sobre el cual ha apostado. Si se lanza un 7 antes de que el número sobre el que se ha apostado salga de nuevo, la apuesta se gana. Si el número sobre el cual ha apostato sale antes que el número 7, la apuesta se pierde. Si se ha lanzado cualquier otro número,  no sucede nada. Sin embargo, como en la apuesta Buy, una apuesta Lay paga los Odds Reales, y el jugador tiene que pagar una comisión del 5% (“vigorish”) para poder hacer su apuesta. Una diferencia es que dicha comisión para una apuesta Lay es el 5 % de la cantidad que gana el jugador si la apuesta Lay tiene éxito. Hay que recordar que los Odds Reales, cuando se apuesta contra el número, son lo contrario de los Odds Reales de cuando se apuesta por un número. Por ejemplo: supongamos que el jugador tenga una apuesta Lay de 40 fichas sobre el número 10. Si el jugador saca un 10 antes que el número 7, gana los Odds Reales (1:2) sobre la apuesta, por un total de 20 fichas. La comisión del 5% es pagada sobre la cantidad ganadora (5% de 20 = 1 fichas), por lo tanto el jugador recibe 19 fichas.',
        title: 'Apuestas Lay',
      },
      Big6OrBig8: {
        line1:
          'Las apuestas Big 6 y Big 8 pueden ser realizadas en el ángulo inferior derecho de la mesa, donde están indicados los grandes números rojos 6 y 8. La apuesta funciona del mismo modo que la apuesta Place sobre los números  6 o 8, con la excepción de que la apuesta mínima hecha sobre el Big 6 y Big 8 es simplemente el mínimo de la mesa, y que Big 6 y Big 8 pagan una suma igual. Como una apuesta Place, la apuesta gana si uno de los números elegidos por el jugador sale antes que el número 7. Si sale antes un 7, la apuesta se pierde.',
        title: 'Apuesta Big 6 o Big 8',
      },
      fieldBet: {
        line1:
          "Aquí se apuesta que en el lanzamiento sucesivo, saldrá uno de los siguientes 7 números: 2, 3, 4, 9, 10, 11, o 12. La apuesta se pierde si sale un 5, 6, 7, o 8. Si el lanzamiento ganador es un 2 o un 12, la apuesta se paga 2:1. Todos los demás lanzamientos ganadores se pagan igual (1:1). Para colocar la apuesta basta simplemente colocar una cantidad en el área Field comprendida entre el área Don't Pass Bar y el área Come.",
        title: 'Apuesta Field Bet',
      },
      anySeven: {
        line1:
          'Esta es una apuesta con un sólo lanzamiento en la que el  jugador apuesta que sacará el número 7 en el lanzamiento que seguirá. Si el lanzamiento sucesivo es un 7, el jugador gana  4:1 sobre su apuesta. Si sale cualquier otro número, la apuesta se pierde.',
        title: 'Apuesta Any Seven',
      },
      anyCraps: {
        line1:
          'Esta es una apuesta con un solo lanzamiento en la que el  jugador apuesta que sacará un 2, 3, o 12 en el lanzamiento que seguirá. Si el lanzamiento sucesivo es un 2, 3 o 12, el jugador gana 7:1 sobre su apuesta. Si sale cualquier otro número, la apuesta se pierde.',
        title: 'Apuesta Any Craps',
      },
      hornTwelve: {
        line1:
          'Esta es una apuesta con un solo lanzamiento en la que el  jugador apuesta que sacará un 12 en el lanzamiento que seguirá. Si el lanzamiento sucesivo es un 12, el jugador ganará un magnífico 30:1 sobre su apuesta. Si sale cualquier otro número, la apuesta se pierde.',
        title: 'Apuesta Horn Twelve',
      },
      hornTwo: {
        line1:
          'Esta es una apuesta con un solo lanzamiento en la que el  jugador apuesta que sacará un 2 en el lanzamiento que seguirá. Si el lanzamiento sucesivo es un 2, el jugador gana un magnífico 30:1 sobre su apuesta. Si sale cualquier otro número, la apuesta se pierde.',
        title: 'Apuesta Horn Two',
      },
      hornEleven: {
        line1:
          "Esta es una apuesta con un solo lanzamiento en la que el  jugador apuesta que sacará un 11 (llamado 'yo' para evitar la confusión en el llamar las palabras 'seven' y 'eleven') en el lanzamiento que seguirá. Si el lanzamiento sucesivo es un 11, el jugador gana 15:1 sobre su apuesta. Si sale cualquier otro número, la apuesta se pierde.",
        title: 'Apuesta Horn Eleven',
      },
      hornThree: {
        line1:
          'Esta es una apuesta con un solo lanzamiento en la que el  jugador apuesta que sacará un 3 en el lanzamiento que seguirá. Si el lanzamiento sucesivo es un 3, el jugador gana 15:1 sobre su apuesta. Si sale cualquier otro número, la apuesta se pierde.',
        title: 'Apuesta Horn Three',
      },
      hardWays: {
        line1:
          'Cuando el jugador lanza ambos dados y ambos dados sacan el mismo número, esta puntuación se llama ‘hard way’. Los lanzamientos de los números 2 y 12 no son considerados ‘hard way’ porque cada total puede solamente salir de una sola combinación (dos dados con el número uno o bien dos dados con el número seis). En el caso de otras apuestas ‘hard way’, el total puede salir de otras combinaciones de los dados. Por ejemplo: un 10 ‘hard way’ (llamado “hard 10”) significa que se han lanzado dos 5 por un total de 10. Sin embargo, se puede llegar al número 10 también con un 6 y un 4.',
        title: 'Apuesta Hard Ways',
      },
      hard4: {
        line1:
          'Cuando el jugador apuesta sobre un ‘hard 4’, el jugador espera que salgan dos 2 con los dados antes de que salga cualquier otra combinación de 4, o el 7. Si el jugador saca un 1 y un 3, o un 7, la apuesta se pierde. El premio por un Hard 4 es de 7:1.',
        title: 'Apuesta Hard 4',
      },
      hard10: {
        line1:
          'Cuando el jugador apuesta sobre un ‘hard 10’, el jugador espera que salgan dos 5 con los dados antes de que salga cualquier otra combinación de 10, o el 7. Si el jugador saca un 4 y un 6, o un 7, la apuesta se pierde. El premio por un Hard 10 es de 7:1.',
        title: 'Apuesta Hard 10',
      },
      hard6: {
        line1:
          'Cuando el jugador apuesta sobre un ‘hard 6’, el jugador espera que salgan dos 3 con los dados antes de que salga cualquier otra combinación de 6, o el 7. Si el jugador saca un 1 y un 5, o un 2 y un 4, o bien el 7, la apuesta se pierde. El premio por un Hard 6 es de 9:1.',
        title: 'Apuesta Hard 6',
      },
      hard8: {
        line1:
          'Cuando el jugador apuesta sobre un ‘hard 8’, el jugador espera que salgan dos 4 con los dados antes de que salga cualquier otra combinación de 8, o bien el 7. Si el jugador saca un 2 y un 6, o bien un 3 y un 5, o bien el 7, la apuesta se pierde. El premio por un Hard 8 es de 9:1.',
        title: 'Apuesta Hard 8',
      },
      payouts: {
        line1:
          'El premio en el juego del ‘Craps’ varía a menudo sobre la base de la puntuación que ha sido establecida o del total que el jugador está intentando realizar. La tabla de abajo indica todos los premios disponibles en el juego del ‘Craps. En algunos casos, el premio da la lista de los números a los que se aplica el premio.',
        line2:
          'Por ejemplo: Pass Line Odds - 4 y 10 representa el premio para una apuesta Odds  que ha ganado sobre la línea Pass cuando la puntuación es 4 o 10. Come Odds - 6 y 8 es el premio para una apuesta Odds que ha ganado sobre una apuesta Come en la posición 6 o 8.',
        bet: 'Apuesta',
        payout: 'Premio',
        and: 'y',
        title: 'Premios',
      },
    },
    gameControls: {
      button1Label: 'Roll',
      button1Description: 'Lanza los dados.',
      gameTips: {
        title: 'Sugerencias para jugar',
        tip1Label: 'CONTROL+CLICK',
        tip1Description: 'Para retirar una apuesta.',
        tip2Label: 'SHIFT+CLICK sobre una apuesta',
        tip2Description: 'Para activar o desactivar la apuesta.',
      },
    },
  },
  crazyPoker: {
    gameName: 'Crazy Poker',
    game: {
      introduction: {
        line1:
          'Crazy Poker es un juego de mesa de casino en el cual se desafia al repartidor. Es similar al Poker de tres cartas, pero, como su nombre indicahay una “loca” diferencia:gana la mejor jugada realizada con cuatro cartas.',
        title: 'Introducción',
      },
      basicRules: {
        line1:
          'El jugador y el repartidor, de hecho, recibirán cada uno cinco cartas para lograr el mejor punto posible con cuatro cartas. Escalera, color y escalera de color seran considerados respectivamente escalera, color y escalera de color.',
        title: 'Reglas Principales',
      },
      start: {
        line1:
          'Coloca una cantidad igual de apuesta sea en el circulo Ante que Super Bono. Es posibile colocar una apuesta tambien en el circulo Queens Up.',
        line2:
          'Una vez realizadas las apuestas, haz click en el botón deal, situado en la parte inferior de la mesa y sea tu que el repartidor iran a recibir 5 cartas.',
        title: 'Inicio',
      },
      playAndAnteWagers: {
        line1:
          "Después de ver tus cartas, puedes decidir si pasar la mano o permanecer en el juego colocando una apuesta cuya cantidad debe coincidir con la del Ante. Para apostar, haz click en el boton 'Llamar' situado en la parte inferior.",
        title: 'Apuestas Play y Ante',
      },
      tripleDownOption: {
        line1:
          "Si tienes al menos un par de Ases en la mano, tendras la oportunidad de triplicar tu apuesta. Al hacer clic en el botón 'Triple Down', podrás apostar 3 veces el valor del Ante (Triple Dow”).",
        title: 'Opción Triple Down',
      },
      howToWin: {
        line1:
          'Hay dos maneras de ganar: (1) cuando el repartidor no califica o (2) cuando el jugador realiza una jugada mejor que la del repartidor. Para poder jugar el repartidor debe tener almenos un Rey (King-High). Puedes ganar la misma cantidad de dinero de tu apuesta si el repartidor pasa la mano y la apuesta del Ante se acumula. Ganaras, en cambio, sea la misma cantidad de dinero de tu apuesta que la del Ante si golpeas la mano. Perderas sea la cantidad de tu apuesta que la del Ante si es el repartidor quien golpea la mano.',
        title: 'Cómo ganar',
      },
      superBonus: {
        line1:
          'La cantidad de apuesta en Super Bonus debe ser la misma de la del Ante. Puedes perder tu apuesta de dos maneras: (1) Si pasas la mano o (2) si el repartidor golpea la mano Y tu jugada es inferior a una escalera. Con la apuesta Super Bono ganas cada vez que realizas al menos una escalera. La ganancia que obtienes se basa en la tabla de pagos del Super Bonus.',
        title: 'Super Bonus',
      },
      queensUp: {
        line1:
          'Es una apuesta opcional en la mano del jugador contra la tabla de pagos. El jugador necesita realizar almenos un par de Reinas para ganar. El resultado de la apuesta no se ve afectado por la jugada realizada por el repartidor.',
        title: 'Queens Up',
      },
    },
    gameControls: {
      button1Label: 'Nuevo juego',
      button1Description: 'Iniciar un nuevo juego.',
      button2Label: 'Limpiar',
      button2Description: 'Poner en cero el monto a jugar.',
      button3Label: 'Mismo monto',
      button3Description: 'Repite el juego con el monto anterior.',
      button4Label: 'Revelar',
      button4Description: 'Pedirle al croupier que distribuya las cartas.',
      button5Label: 'Llamar',
      button5Description: 'Pídele al croupier que enseñe sus cartas.',
      button6Label: 'Abandonar',
      button6Description: 'Retirarse de la apuesta del juego.',
      button7Label: 'Triple Down',
      button7Description: 'Apostar 3 veces el valor del Ante.',
    },
  },
  hooHeyHow: {
    gameName: 'Hoo Hey How',
    game: {
      introduction: {
        line1:
          'Este juego representa una antigua y sencilla variación del Este asiático del juego de lotería con los dados llamado “Crown & Anchor” (literalmente Corona y Ancla).',
        line2:
          'Hay 3 dados y en cada lado hay una imagen distinta que corresponde a la imagen correspondiente sobre la mesa de juego. Las 6 imágenes que lleva cada dado son: un pez, una gamba, un gallo, un cangrejo, una botella y una moneda.',
        title: 'Introducción',
      },
      rulesOfGame: {
        line1:
          'Seleccionar una ficha y colocarla apostando sobre una o varias imágenes presente sobre la mesa. El único límite de lo que se puede apostar es el límite total de la mesa. Cuando hayáis terminado de colocar vuestras apuestas pulsar sobre el botón ‘Roll’ (voltear) para hacer girar la jaula que contiene los dados. Cuando la jaula se para se podrá ver sobre los dados las imágenes que han salido. Si alguna de las imágenes e los dados corresponde a una o varias imágenes sobre las que habéis apostado... ganáis!.',
        title: 'Reglas del juego',
        dice: 'dado',
        dicePlural: 'dados',
        match: 'Correspondencia',
        payout: 'Premio',
      },
    },
    gameControls: {
      button1Label: 'Nuevo juego',
      button1Description: 'Iniciar un nuevo juego.',
      button2Label: 'Limpiar',
      button2Description: 'Poner en cero el monto a jugar.',
      button3Label: 'Mismo monto',
      button3Description: 'Repite el juego con el monto anterior.',
      button4Label: 'Roll',
      button4Description: 'Pedirle al croupier que gire la jaula.',
    },
  },
  keepEmPoker: {
    gameName: "Keep 'em Poker",
    game: {
      introduction: {
        line1:
          "Keep'em Poker se basa sobre el póquer de 5 cartas o stud póquer. En este juego no se compite con otros jugadores ni contra la banca. La finalidad es la de obtener una baza de póquer mejor que las tres cartas iniciales y las dos cartas en común que son mostradas sucesivamente.",
        title: 'Introducción',
      },
      howToPlay: {
        line1: 'El jugador coloca tres apuestas iguales en los círculos marcados 1, 2 y 3.',
        line2:
          'Al jugador se le dan 3 cartas descubiertas y 2 cartas cubiertas que son colocadas en el centro de la mesa arriba.',
        line3:
          "Después de haber mirado las primeras 3 cartas, el jugador podrá retirar la primera apuesta (pull out) o bien girar la primera carta (Keep'em).",
        line4:
          'Entonces, la primera de las dos cartas cubiertas se gira y se convierte en la cuarta carta del jugador.',
        line5:
          "Ahora el jugador podrá elegir si retirar (pull out) o no (Keep'em) la segunda apuesta y girar la segunda carta cubierta.",
        line6:
          'Luego la segunda carta cubierta es girada y el resultado de la baza póquer de 5 cartas determina el resultado. Si el jugador acaba con por lo menos una pareja de 10 o superior, el jugador recibirá un premio (véase la tabla de los Premios).',
        line7:
          'Nótese que retirar la segunda apuesta (pull out) es independiente de lo que se haya decidido primero. Prescindiendo de la decisión tomada para la primera y la segunda apuesta el jugador no puede retirar (pull out) la tercera apuesta.',
        title: 'Cómo se juega',
      },
      bonusGame: {
        line1:
          'Cuando se colocan las 3 apuestas iniciales el jugador puede realizar una apuesta “bonus” opcional de 1 (side bet). Cuando las bazas de póquer se han calificado, se pueden ganar hasta 20.000 veces (véase más abajo).',
        title: 'Apuesta Bonus',
      },
      payoutTable: {
        playerHand: 'Baza del jugador',
        basicPayout: 'Premios base',
        sideBetPayout: 'Premios de la apuesta lateral',
        payout1Label: 'Escalera Real',
        payout2Label: 'Escalera de Color',
        payout3Label: 'Póquer',
        payout4Label: 'Full',
        payout5Label: 'Color',
        payout6Label: 'Escalera',
        payout7Label: 'Trío',
        payout8Label: 'Doble Pareja',
        payout9Label: 'Pareja de 10 o superior',
        title: 'Premios',
      },
    },
    gameControls: {
      button1Label: 'Nuevo juego',
      button1Description: 'Iniciar un nuevo juego.',
      button2Label: 'Limpiar',
      button2Description: 'Poner en cero el monto a jugar.',
      button3Label: 'Mismo monto',
      button3Description: 'Repite el juego con el monto anterior.',
      button4Label: 'Revelar',
      button4Description: 'Pedirle al croupier que distribuya las cartas.',
      button5Label: 'Keep em',
      button5Description:
        'Pedirle al croupier que siga repartiendo las cartas después de que las primeras 3 cartas hayan sido dadas.',
      button6Label: 'Pull out',
      button6Description: 'Acabar el juego y retirar todas las apuestas ganadas.',
    },
  },
  keno: {
    gameName: 'Keno',
    game: {
      introduction: {
        line1:
          'El Keno es un excitante juego introducido en China hacia el año 200 A.C. Llegó a los E.E.U.U. a través de los emigrantes y ha evolucionado a lo largo del tiempo hasta convertirse en el juego que hoy conocemos.',
        title: 'Introducción',
      },
      rules: {
        line1:
          'El juego del Keno se juega tradicionalmente con un billete en el que se encuentran marcados 80 números y 20 bolitas cuya función es la misma que la de una lotería estándar. El jugador marca de 1 a 15 números en el billete o de 1 a 10 dependiendo de la clase de Keno escogida.',
        line2:
          'Los montos de los premios se muestran en la tabla de pagos según la cantidad de números que coincidan. Los números coincidentes son los elegidos por el jugador y lo que muestra el juego',
        line3:
          'Después de cada juego, puedes elegir quedarte con los mismos números que elegiste previamente (haciendo clic en el botón JUEGA) o hacer clic en los nuevos números dentro del juego',
        title: 'Reglas del juego',
      },
      superKeno: {
        line1:
          'En un juego de Super Keno, si el número final que se muestra en el juego coincide con un número elegido por el jugador, las ganancias se multiplican por 4.',
        title: 'Super Keno',
      },
    },
    gameControls: {
      button1Description: "Pulsar sobre el 'grid' para seleccionar los números.",
      button2Description: 'Seleccionar la apuesta.',
      button3Label: 'Juega',
      button3Description: 'Iniciar el juego.',
    },
  },
  paigow: {
    gameName: 'Pai Gow Poker',
    game: {
      introduction: {
        line1: 'El juego del Pai Gow Poker es la combinación del póquer y del antiguo juego chino Pai Gow.',
        line2:
          'El jugador recibe 7 cartas que le divide en las dos bazas, la baza de 5 cartas y la baza de dos cartas. La finalidad del juego es la de ganar al dealer.',
        title: 'Introducción',
      },
      howToPlay: {
        line1:
          "Antes de nada, coger una ficha y colocar vuestra apuesta en la zona dedicada de la mesa. Si queréis podéis apostar distintas fichas simultáneamente. Para quitar la apuesta pulsar sobre el botón 'Limpiar'.",
        line2:
          'Se reciben siete cartas. Se dejan 5 cartas en la baza “principal” (Highest Hand) y se eligen las dos cartas que se pondrán en la baza “secundaria”  (2nd Highest). Para elegir las cartas que hay que poner en la baza secundaria, simplemente pulsarla. Para hacer volver una carta a la baza principal simplemente se pulsa de nuevo. El valor total de la baza de cinco cartas tiene siempre que superar el valor de 2 cartas. En caso que estéis indecisos sobre qué cartas elegir, podéis también pulsar el botón House Way para permitirle al ordenador que elija vuestras bazas (de la forma más conveniente para vosotros).',
        line3:
          'Luego se giran las cartas del dealer. Si ambas bazas del dealer tienen el valor total más alto que vuestras bazas, vosotros perdéis la apuesta. Si una de vuestras bazas tiene un valor más alto y la otra un valor más bajo, la partida resulta empatada y vuestra apuesta os será devuelta. Si ambas bazas vuestras son más altas que las del dealer, ganaréis una cantidad equivalente a la de vuestra apuesta (en la proporción de 1 a 1) menos la comisión del 5% para la banca. En caso de una combinación exacta entre las bazas (llamada par) el empate va a la banca.',
        title: 'Cómo se juega',
      },
      houseway: {
        line1: "La mano con 2 cartas es referida como 'atrás' y la mano de 5 cartas es 'adelante'.",
        houseway1Label: 'No par',
        houseway1Line1: 'Poner la carta más alta atrás y las siguientes 2 cartas más altas adelante.',
        houseway2Label: 'Un par',
        houseway2Line1: 'Pone el par atrás y las siguientes 2 cartas más altas adelante.',
        houseway3Label: 'Dos pares',
        houseway3Line1: 'Usa los siguientes grupos para definir como jugar con dos pares:',
        houseway3Line2: '2 al 6: Par bajo',
        houseway3Line3: '7 al 10: Par medio',
        houseway3Line4: 'Jota al Rey: Par alto',
        houseway3Line5:
          'Par bajo y par bajo: Separarlos a menos que se tenga un rey o mejor, en ese caso poner los dos pares atrás.',
        houseway3Line6:
          'Par bajo y par medio: Separarlos a menos que se tenga un rey o mejor, en ese caso poner los dos pares atrás.',
        houseway3Line7:
          'Par bajo y par alto: Separarlos a menos que se tenga un as, en ese caso poner los dos pares atrás.',
        houseway3Line8:
          'Par medio y par medio: Separarlos a menos que se tenga un as, en ese caso poner los dos pares atrás.',
        houseway3Line9: 'Par medio y par alto: Siempre separarlos.',
        houseway3Line10: 'Par alto y par alto: Siempre separarlos.',
        houseway3Line11: 'Par de aces y cualquier otro par: Siempre separarlos.',
        houseway4Label: 'Tres pares',
        houseway4Line1: 'Siempre poner el par más alto adelante.',
        houseway5Label: 'Tres de un tipo',
        houseway5Line1:
          'Siempre poner los 3 de un tipo atrás a menos que sean aces, en ese caso poner par de aces atrás y un as adelante.',
        houseway6Label: 'Doble tres de un tipo',
        houseway6Line1: 'Siempre poner el par más alto adelante.',
        houseway7Label: 'Escalera, color, escalera de color y escalera real',
        houseway7Line1:
          'No par: Al elejir si jugar un escalera, color o escalera de color, juega la categoria que permita las dos cartas más altas adelante.',
        houseway7Line2:
          'Con 6ta o 7ma carta: Poner la escalera o color más bajo atrás para poner las cartas más altas adelante.',
        houseway7Line3:
          'Con un par: Poner el par adelante solo si una escalera, color o escalera de color pueden ser puestas atrás.',
        houseway7Line4: 'Con dos pares: Usa la regla para dos pares.',
        houseway7Line5: 'Con tres pares: Usa la regla para tres pares.',
        houseway7Line6: 'Con un trío: Poner un par adelante.',
        houseway7Line7: 'Con full: Usa la regla para full. ',
        houseway8Label: 'Full',
        houseway8Line1: 'Separar a menos que tenga un par de dos y tenga un as o un rey para poner adelante.',
        houseway9Label: 'Full con tres de un tipo y dos pares',
        houseway9Line1: 'Poner el par más alto adelante.',
        houseway10Label: 'Full con doble tres de un tipo',
        houseway10Line1: 'Poner el par más alto adelante.',
        houseway11Label: 'Cuatro de un tipo',
        houseway11Line1: 'Jugar de acuerdo al ranking del cuatro de un tipo:',
        houseway11Line2: '2 al 6: Mantenrlos juntos.',
        houseway11Line3: '7 al 10: Separarlos a menos que un rey o mejor pueda ser puesto adelante.',
        houseway11Line4: 'Jota al Rey: Separarlos a menos que un as pueda ser puesto adelante.',
        houseway11Line5: 'Ases: Separarlos.',
        houseway12Label: 'Cuatro de un tipo y un par',
        houseway12Line1: 'Poner el par adelante.',
        houseway13Label: 'Cuatro de un tipo y tres de un tipo',
        houseway13Line1: ' Del tres de un tipo poner un par adelante.',
        houseway14Label: 'Cinco Aces',
        houseway14Line1:
          'Separar los aces y poner tres aces atrás y dos aces adelante a menos que tenga un par de reyes, en ese caso poner los cinco aces atrás y el par de reyes adelante.',
        title: 'House Way',
      },
      ranking: {
        highStraight: 'Escalera alta (10, J, Q, K, ACE)',
        secondHighStraight: '2da escalera alta (ACE, 2, 3, 4, 5)',
        title: 'Calificación de mazo',
      },
    },
    gameControls: {
      button1Label: 'Nuevo juego',
      button1Description: 'Iniciar un nuevo juego.',
      button2Label: 'Limpiar',
      button2Description: 'Poner en cero el monto a jugar.',
      button3Label: 'Mismo monto',
      button3Description: 'Repite el juego con el monto anterior.',
      button4Label: 'Revelar',
      button4Description: 'Pedirle al croupier que distribuya las cartas.',
      button5Label: 'Llamar',
      button5Description: 'Pídele al croupier que enseñe sus cartas.',
      button6Label: 'House way',
      button6Description: 'Permítele al ordenador que elija la baza mejor.',
    },
  },
  pokerThree: {
    gameName: 'Poker Three',
    game: {
      introduction: {
        line1:
          'Poker Three es un juego del estilo del póquer, rápido y divertido que requiere poca estrategia para tener éxito. La premisa es sencilla: ganarle al banco haciendo una baza de póquer mejor con una sola distribución de 3 cartas.',
        line2:
          "Poker Three es en efecto dos juegos en uno. Tanto el jugador como la banca toman 3 cartas. Hay dos tipos de apuesta: Ante (Antes), con la que el jugador compite con la banca; y 2's N Better (más de una pareja) en la que el jugador apuesta que la baza tendrá un resultado superior al de la pareja.",
        line3: "Un jugador puede apostar tanto sobre la apuesta Ante como sobre la de 2's N Better.",
        title: 'Introducción',
      },
      basicRules: {
        line1:
          'Poker Three se juega con una baraja de 52 cartas. Las cartas se reparten 3 por cada baza. La escalera tiene un valor superior al color (mismo símbolo), ya que hay menos posibilidades de escalera con 3 cartas. Los ases pueden ser la carta más alta o la más baja según la necesidad, como en el póquer.',
        title: 'Reglas Principales',
      },
      anteAndAnteBonus: {
        line1:
          "Se empieza apostando con una apuesta inicial (ANTE). El Ante como en el póquer tradicional, es en practica el coste para jugar la baza.  Para jugar el Ante es necesario colocar una apuesta en el espacio Ante de la mesa. En el momento de la apuesta del Ante se puede decidir si apostar también en el 2's N Better (ver Pair Plus más abajo).",
        line2:
          "Banco y jugador reciben 3 cartas (Deal), las del jugador están descubiertas. Entonces, según el valor de la baza, se puede decidir si apostar contra la banca (Call) o retirarse (Fold). Si uno se retira pierde lo que había apostado sobre el Ante, mientras el 2's N Better será pagado solo si se había apostado y la baza terminará.",
        line3:
          'Si se decide seguir y por lo tanto ver las cartas de la banca (pulsando sobre el botón Call) se colocará una apuesta igual al Ante en el área Bet de la mesa.',
        line4:
          "Se descubrirán entonces las cartas de la banca. La banca tiene que tener por lo menos una pareja de damas o superior para calificarse. Cualquier valor inferior a las dos damas será automáticamente una baza perdida por la banca y el jugador ganará si no se había retirado antes (Fold). Si la banca no se califica se pagará 1 a 1 sobre la apuesta inicial (Ante) y también sobre el 2's N Better si se ha apostado, mientras que la apuesta será solo devuelta; no se gana ni se pierde.",
        line5:
          'En el Poker Three existe el bonus de la apuesta inicial (Bonus Ante). Este se paga si se consigue 1 de las 3 combinaciones de cartas lo más altas posibles: escalera real, trío o escalera. Este bonus se paga también si la banca no se califica y también si la baza de la banca gana a la baza del jugador. Consultar la tabla de los premios de aquí abajo para saber cómo se paga el Bonus Ante. Sin embargo si el jugador se retira (Fold), el Bonus Ante no se paga. ',
        line6:
          'Si la baza de la banca se califica, la baza más alta gana. Si la baza de la banca es más alta, el jugador pierde tanto la apuesta inicial (Ante) como la apuesta Bet pero puede todavía ganar con el Bonus de la apuesta inicial (Bonus Ante) si posee una escalera o un juego más alto. A los que puedan ganar al banco se les paga 1 a 1 tanto sobre la apuesta inicial (Ante), como sobre la apuesta Bet, más el bonus sobre la apuesta inicial (Bonus Ante), si aplicable. Si ambos, tanto la banca como el jugador, tienen el mismo valor de juego, entonces el valor de las cartas determinará al ganador (Por ejemplo: pareja de reyes y pareja de diez gana la pareja de reyes). Finalmente, si la baza de la banca es igual a la del jugador, ambas apuestas son iguales y por lo tanto les son devueltas al jugador.',
        title: 'Ante y Bonus Ante',
      },
      twosNBetter: {
        line1:
          "Una simple pareja o más (2's N Better), apuesta sobre el hecho de que vosotros tengáis una pareja de 10 o superior. Esta apuesta opcional se hace en el momento de colocar la apuesta inicial (Ante), colocando la apuesta en el área 2's N Better de la mesa. No se juega contra la banca. Se gana si se tiene una pareja de 10 o superior sin tener en cuanta de lo que tiene la banca. Se pueden consultar los premios de las apuestas en la tabla de los premios para saber como se paga el 2's N Better.",
        line2:
          "Cuando se calcula un premio, hay 3 elementos a considerar: el resultado del 2's N Better, el resultado del bonus ante y el resultado de la partida (Ante).",
        payout: 'Premio',
        push: 'es devuelto',
        lose: 'pierde',
        anteWins: 'Ante gana',
        antePushes: 'Ante empata',
        anteLoses: 'Ante pierde',
        entryWins: 'Entry gana',
        entryPushes: 'Entry empata',
        entryLoses: 'Entry pierde',
        twosNBetterResult: "Resultado del 2's N Better",
        twosNBetterResult1Label: 'Par',
        twosNBetterResult2Label: 'Color',
        twosNBetterResult3Label: 'Escalera',
        twosNBetterResult4Label: 'Trío',
        twosNBetterResult5Label: 'Escalera de Color',
        anteBonusResult: 'Resultado del Ante Bonus',
        anteBonusResult1Label: 'Escalera',
        anteBonusResult2Label: 'Trío',
        anteBonusResult3Label: 'Escalera de Color',
        playResult: 'Resultado de la partida',
        playResult1Label: 'La banca no se califica:',
        playResult2Label: 'La banca se califica y el jugador le gana:',
        playResult3Label: 'La banca se califica y empata el resultado:',
        playResult4Label: 'La banca se califica y gana:',
        title: "2's N Better",
      },
    },
    gameControls: {
      button1Label: 'Nuevo juego',
      button1Description: 'Iniciar un nuevo juego.',
      button2Label: 'Limpiar',
      button2Description: 'Poner en cero el monto a jugar.',
      button3Label: 'Mismo monto',
      button3Description: 'Repite el juego con el monto anterior.',
      button4Label: 'Revelar',
      button4Description: 'Pedirle al croupier que distribuya las cartas.',
      button5Label: 'Llamar',
      button5Description: 'Aumentar con un monto igual al monto original',
      button6Label: 'Abandonar',
      button6Description: 'Retirarse de la apuesta del juego.',
    },
  },
  redDog: {
    gameName: 'Red Dog',
    game: {
      introduction: {
        line1:
          'Red dog a veces conocido como ’Acey-Deucey‘ o ‘Between the Sheets’ es un juego sencillo . Si recordáis el número siete y sabéis cómo restar podéis jugar a Red Dog como cualquier persona del mundo.',
        line2:
          'Se exponen 2 cartas. El objeto del juego es el de apostar sobre la probabilidad de que el valor de la tercera carta caiga entre le valor de las primeras dos. Si cae entre las primeras dos se gana, si no es así se pierde. Cuanto más reducido sea el espacio entre las dos primeras cartas tanto más alta es la potencialidad de premio.',
        title: 'Introducción',
      },
      howToPlay: {
        line1:
          'En Red Dog se usa una baraja de 52 cartas . Todas las cartas tienen el mismo valor que las cartas del póquer, los palos no tienen importancia y los ases son la carta más alta.',
        line2:
          'La diferencia es el valor numérico que hay entre las 2 primeras cartas. El valor de cada carta de 2 a 10 cuenta por el valor expuesto, el jack vale 11, la dama 12, el rey 13 y el as 14.',
        line3:
          'Se comienza colocando una apuesta inicial en el área llamada apuesta (bet) sobre la mesa. Se tomarán 2 cartas descubiertas.',
        line4: 'Si las dos cartas son consecutivas la baza es empate y la apuesta original es devuelta.',
        line5:
          'Si las dos cartas son iguales se coge una tercera carta. Si la tercera carta es igual a las dos primeras, el jugador recibirá 11 a 1 la apuesta inicial. Si la tercera carta es distinta entonces se empata y la apuesta inicial le es devuelta al jugador.',
        line6:
          'Si las dos cartas no son ni consecutivas ni iguales se verá la diferencia entre las dos cartas. El jugador tendrá la opción de decidir si hacer una apuesta o apuesta adicional del mismo importe que la inicial y por lo tanto se arriesga (Raise) y se coge una tercera carta. Si la tercera carta tiene un valor entre las dos primeras el jugador gana (véanse los pagos más abajo). Si la tercera carta es igual o fuera de los números comprendidos entre las dos primeras cartas, el jugador pierde.',
        title: 'Cómo se juega',
      },
      payouts: {
        line1: 'Tres cartas iguales se pagan 11 a 1.',
        line2: 'La apuesta inicial se paga siempre 1 a 1 .',
        line3:
          'El Aumentar se basa sobre la diferencia entre las dos primeras cartas y por lo tanto será pagado de la manera siguiente :',
        through: 'al',
        numbers: 'Números',
        payout: 'Premio',
        title: 'Premios',
      },
    },
    gameControls: {
      button1Label: 'Nuevo juego',
      button1Description: 'Iniciar un nuevo juego.',
      button2Label: 'Limpiar',
      button2Description: 'Poner en cero el monto a jugar.',
      button3Label: 'Mismo monto',
      button3Description: 'Repite el juego con el monto anterior.',
      button4Label: 'Revelar',
      button4Description: 'Pedirle al croupier que distribuya las cartas.',
      button5Label: 'Aumenta',
      button5Description: 'Aumentar con un monto igual al monto original',
      button6Label: 'Llamar',
      button6Description: 'Ver las cartas de la banca.',
    },
  },
  roulette: {
    gameName: 'Ruleta',
    game: {
      introduction: {
        line1:
          'El juego de la ruleta remonta su origen a Inglaterra a principios de 1700 en un juego llamado Rolly Poly. Presentaba manchas en blanco y negro sin números.',
        line2:
          'Los franceses lo rediseñaron a finales de 1700 para incluir números. Las ruedas originales americanas tenían 31 números más un 0, 00, y un American Eagle para el tercer número cero (000). Las ruedas de ruleta europeas de hoy en día tienen solo un cero mientras la mayoría de las ruedas americanas tienen cero y doble cero.',
        line3:
          'Tanto las ruedas modernas estadounidenses como las europeas tienen 36 números. La ruleta es el juego de casino más popular en Europa.',
        line4:
          'Los jugadores, generalmente hasta ocho, juegan contra la casa representada por el croupier, que hace girar la rueda de la ruleta y maneja las apuestas y los pagos.',
        line5: 'La rueda americana tiene 38 ranuras que representan 36 números, un solo cero y un doble cero.',
        title: 'Introducción',
      },
      basicRules: {
        line1:
          "Para jugar a la ruleta, coloque su entrada en los números (cualquier número incluyendo el cero y el doble) en el diseño de la mesa o en el exterior, y cuando todos en la mesa hayan colocado sus montos, el croupier iniciará el giro y lanzará el bola. Solo unos momentos antes de que el balón esté a punto de caer sobre las ranuras de los números, el croupier dice 'no más montos sobre la mesa'. A partir de ese momento, nadie tiene permitido colocar - o cambiar - sus montos hasta que la bola caiga en una ranura. El crupier coloca el carro en el número ganador en la mesa de la ruleta y retira todos los montos que pierden, a continuación, puede empezar a colocar sus nuevas recompensas, mientras que el croupier paga a los ganadores. Los ganadores son los premios que están en o alrededor del número que aparece. También las áreas en el exterior del diseño ganan si se representan al número ganador.",
        line2:
          "Hay muchas maneras diferentes de colocar los montons para juagar a la ruleta. Cada entrada cubre un conjunto diferente de números y tiene una distribución diferente, como se muestra en la sección 'Tabla de ganancias'.",
        title: 'Reglas Principales',
      },
      straightUp: {
        line1:
          'Se puede apostar sobre cualquier número, comprendido el 0, poniendo una ficha en el centro de un número de la mesa.',
        title: 'Apuesta Plena, sobre el número',
      },
      splitEntry: {
        line1:
          'Se puede apostar sobre dos números poniendo una ficha sobre la línea que divide los dos números (caballo).',
        title: 'Apuesta Dividida o Caballo',
      },
      streetEntry: {
        line1:
          'Se puede apostar sobre 3 números poniendo una ficha a caballo sobre la línea izquierda del borde de la mesa de la ruleta junto a la línea correspondiente de los tres números.',
        title: 'Apuesta Tercio',
      },
      cornerEntry: {
        line1:
          'Se puede apostar sobre cuatro números poniendo la ficha sobre el ángulo donde se encuentran los cuatro números.',
        title: 'Apuesta Esquina',
      },
      fiveEntry: {
        line1:
          'Se puede apostar sobre los números 0, 1, 2, y 3 poniendo la ficha sobre la línea interior del borde izquierdo entre 0 y 1.',
        title: 'Apuesta Cinco',
      },
      lineEntry: {
        line1:
          'Se puede apostar sobre dos tercios ‘street bets’ (ejemplo: los seis números distintos en dos filas de tres números), poniendo la ficha sobre la línea interior izquierda del borde y a caballo de las dos líneas.',
        title: 'Apuesta Línea',
      },
      columnEntry: {
        line1:
          "Hay tres casillas indicadas  '2 a 1' debajo de la columna de números. Se pueden colocar las apuestas para todos estos números poniendo la ficha en una de estas casillas. Si sale uno o cualquiera de los números de vuestra columna se os pagará 2 a 1; con el 0 se pierde.",
        title: 'Apuesta Columna',
      },
      dozenEntry: {
        line1:
          "Se puede apostar sobre un grupo de doce números poniendo la ficha en una de las tres casillas que llevan escrito: '1st 12', '2nd 12', o '3rd 12'. Si sale uno de vuestros doce números se os pagará 2 a 1; con el 0 se pierde.",
        title: 'Apuesta Docena',
      },
      moreEntries: {
        line1:
          'Se puede hacer una apuesta en una de las casillas sobre el lado largo de la izquierda de la mesa que cubre la mitad de los números de la ruleta (con exclusión del 0). Cada casilla cubre 18 números. El premio es de 1 a 1 sobre todas estas apuestas; con el 0 y 00 se pierde.',
        title: 'Apuestas: Rojo/Negro, Pares/Impares, Altas/Bajas',
      },
      winningNumberDisplay: {
        line1: 'En el display de números ganadores se ven los resultados de las 7 partidas anteriores.',
        title: 'Display Número Ganador',
      },
      winningsTable: {
        line1: 'NOTA: Se pueden colocar apuestas que tengan dos, tres, cuatro o incluso cinco números.',
        line2: 'Por ejemplo utilizando el cero se puede apostar : 1+0, 1+2+0, 3+0, o 1+2+3+0.',
        chipCovers: 'Número cubierto',
        playType: 'Tipo de apuesta',
        pays: 'Premio',
        number: 'número',
        numbers: 'números',
        win1Label: 'Plena, sobre el número',
        win2Label: 'Dividida o caballo',
        win3Label: 'Tercio',
        win4Label: 'Esquina',
        win5Label: 'Cuatro',
        win6Label: 'Línea',
        win7Label: 'Columna o Docena',
        win8Label: 'Rojo/Negro, Pares/Impares, Altas/Bajas',
        win9Label: 'Cinco',
        title: 'Tabla de pagos',
      },
    },
    gameControls: {
      button1Label: 'Nuevo juego',
      button1Description: 'Iniciar un nuevo juego.',
      button2Label: 'Limpiar',
      button2Description: 'Clear the plays.',
      button3Label: 'Mismo monto',
      button3Description: 'Repite el juego con el monto anterior.',
      button4Label: 'Revelar',
      button4Description: 'Indica al croupier que gire la rueda de la ruleta.',
      button5Label: 'Revelar Rápido',
      button5Description: 'El sistema elegirá un número al azar sin la acción de la rueda girando.',
    },
  },
  sicBo: {
    gameName: 'Sic Bo',
    game: {
      introduction: {
        line1:
          'Sic Bo a veces llamado ‘Tai Sai’ es un antiguo juego de azar chino. Tres dados se hacen girar dentro de una pequeña jaula. La finalidad de Sic Bo es la de adivinar los números o las combinaciones que aparecerán sobre los dados cuando se pare la jaula. Hay 50 maneras distintas de apostar y por consiguiente existen infinitas elecciones. Hay una amplia gama de premios que a veces llegan incluso a un porcentaje de 180 a 1.',
        title: 'Introducción',
      },
      howToPlay: {
        line1:
          'Pulsar sobre la ficha del valor que se desea apostar, luego pulsar sobre el área “apuestas” en la mesa para colocar vuestra apuesta (se puede ver una lista de apuestas disponibles en la tabla “Tipos de apuestas del Sic Bo”). Cada vez que se pulsa sobre el área de apuesta se añade una ficha a la apuesta. Seleccionar una ficha distinta si se desea incrementar la apuesta con un valor diferente. Se puede apostar en diferentes áreas contemporáneamente.',
        line2:
          'Pulsar el botón Roll para echar los dados. Una vez que han rodado aparecerá el resultado. Pulsar sobre New Game para poder continuar con otro juego. Colocar nuevamente vuestras apuestas como ya se ha descrito y así sucesivamente. Con el botón Rebet se puede volver a apostar con la misma apuesta de la vez anterior.',
        title: 'Cómo se juega',
      },
      betTable: {
        type: 'Tipo',
        definition: 'Definición',
        bet1Label: 'Un solo número',
        bet1Description:
          'Hay seis áreas en la parte inferior de la mesa del Sic Bo donde se encuentran evidenciados los seis números del dado. Se apuesta en una de estas áreas de puntos sobre un número del dado que pensáis que vaya a salir. Si uno o dos o los tres dados tiene el número del área elegida por vosotros, ganáis una o varias veces el pago de esa área.',
        bet2Label: 'Combinación de dos números',
        bet2Description:
          'Esta es una apuesta que usa una de las 15 combinaciones posibles de dos números con los dados. Si la combinación del área seleccionada aparece sobre dos de los tres dados, se gana. Si la combinación aparece dos veces en los tres dados (por ejemplo elegís la combinación 3-5 y sale 3-5-5, ganáis dos veces).',
        bet3Label: 'Par',
        bet3Description:
          'Se juega sobre un número especifico que tiene que aparecer por lo menos sobre dos de los tres dados. Si sale la pareja de dados que habéis elegido sobre dos de los dados o sobre los tres, se gana.',
        bet4Label: 'Trío',
        bet4Description:
          'Se juega sobre una apuesta que prevé la salida del mismo número en los tres dados, o bien cualquiera de los seis tríos posibles con los tres dados (cualquiera de los tríos).',
        bet5Label: 'Suma de los tres dados',
        bet5Description:
          'Se apuesta sobre la suma del valor de cualquiera de los tres dados. Seleccionar un total de 4 a 17 en el área (14 combinaciones posibles). Cuanto menores sean las probabilidades del total seleccionado, más alto será el premio. Con un total de 3 o 18 se pierde la apuesta.',
        bet6Label: 'Números pequeños o Números grandes',
        bet6Description:
          "Los 'Números pequeños' parten de 4 a 10 mientras que los 'Números grandes' son de 11 a 17. Se puede apostar que el resultado de la suma de los 3 dados esté entre 4 y 10 o bien entre 11 y 17.  Si los tres dados tienen en mismo número, por lo tanto un trío se pierde automáticamente la apuesta.",
        title: 'Tipos de apuestas',
      },
      payoutTable: {
        line1: 'Las apuestas mencionadas se pagan según la tabla siguiente:',
        line2:
          'Las siguientes apuestas ganan según la suma de los dados. Los premios se pagan como se indica en la tabla siguiente:',
        type: 'Tipo',
        youWin: 'El jugador gana si',
        youWinIf: 'El jugador gana si la sunma es igual a',
        examples: 'Ejemplos',
        exceptions: 'Excepciones',
        payout: 'Premio',
        and: 'y',
        or: 'o',
        to: 'a',
        anyOtherTotal: 'Cualquier otro total',
        payout1Label: 'Un solo número',
        payout1Description: 'El premio cambia según las veces que aparezca el número elegido.',
        payout2Label: 'Combinación de dos números',
        payout2Description: 'La combinación aparece por lo menos una vez en los dados.',
        payout3Label: 'Par',
        payout3Description: 'El número elegido aparece por lo menos 2 o 3 veces en los dados.',
        payout4Label: 'Cualquier trío',
        payout4Description: 'El número elegido aparece en los 3 dados.',
        payout5Label: 'Trío especifico',
        payout5Description: 'Los 3 números específicos aparecen en los 3 dados.',
        payout6Label: 'Números pequeños',
        payout7Label: 'Números grandes',
        payout8Label: 'Suma de los tres dados',
        title: 'Premios',
      },
    },
    gameControls: {
      button1Label: 'Nuevo juego',
      button1Description: 'Iniciar un nuevo juego.',
      button2Label: 'Limpiar',
      button2Description: 'Poner en cero el monto a jugar.',
      button3Label: 'Mismo monto',
      button3Description: 'Repite el juego con el monto anterior.',
      button4Label: 'Revelar',
      button4Description: 'Pedirle al croupier que gire la jaula de los dados.',
    },
  },
  slotOneLineMagic: {
    gameName: 'Mix pics Slot Machine Style',
    game: {
      description: {
        line1:
          'Los juegos Tragamonedas tienen diferentes variaciones y diseños. El tragamonedas de 1 línea es muy fácil de jugar y solo se ve como una máquina tragamonedas, sin embargo, el jugador no necesita tener iconos específicos en una fila. premio ya que no todos los símbolos pagarán un premio. Estos íconos pueden mostrarse en cualquiera de las 5 ventanas de visualización.',
        line2:
          'Si el jugador obtiene el icono de giros gratis, el pago del icono del premio durante los giros gratis se multiplica como se muestra en la tabla de pagos',
        title: 'Introducción',
      },
      howTo: {
        line1:
          'Elige el valor de la moneda que es el número de recompensas que deseas usar haciendo clic en una de las monedas. Luego haz clic en el botón de revelar.',
        autoOffLabel: 'Auto Apagado',
        autoOff: "Pulsar este botón para deshabilitar la acción 'Auto Prendido'.",
        autoOnLabel: 'Auto Prendido',
        autoOn: 'Pulsar este botón para repetir automáticamente la apuesta anterior y revelar juegos automáticamente.',
        clearLabel: 'Limpiar',
        clear: 'Pulsar este botón para quitar el monto a jugar.',
        revealLabel: 'Girar',
        reveal: 'Pulsar este botón para empezar el juego.',
        paytableLabel: 'Tabla de pagos',
        title: 'Cómo entrar y jugar',
      },
      bonusRound: {
        line1:
          'Cada juego tiene su propia tabla de premios y se indicará en la mesa de premios si un juego tiene un juego de bonificación, premios mayores o tiradas gratis (giros gratis).',
        line2:
          'Los símbolos especiales (como se ve en la Tabla de premios) te darán la posibilidad, dependiendo de qué juego, de ingresar a la ronda de bonificación. La ronda de bonificación se cargará automáticamente. Cada juego de bonificación es diferente. En la ronda de bonificación puedes aumentar sus ganancias sin usar ninguna de sus recompensas ',
        title: 'Ronda de Bonus',
      },
      prizeTable: {
        line1:
          'Este botón se encuentra en la parte inferior izquierda de la máquina. Dentro de la tabla de premios real se muestra el importe de pago para cada premio y/o el número del mismo icono.',
        title: 'Tabla de Pagos',
      },
    },
    gameControls: {
      button1Description:
        'El selector de la apuesta te permite incrementar o disminuir el monton de la entrada/moneda con la que jugará.',
      button2Label: 'Girar',
      button2Description: 'Pulsar este botón para empezar el juego.',
      button3Label: 'Auto Prendido',
      button3Description:
        'Pulsar este botón para repetir automáticamente la apuesta anterior y revelar juegos automáticamente.',
      button4Label: 'Reset',
      button4Description: 'Pulsar este botón para quitar el monto a jugar.',
      description_1: 'Spin button. click to start the game',
      description_2:
        'Click to open the auto spin panel. There you can chose the number of auto spins you would like to program',
      description_3: 'Start auto spin play',
      description_4: 'Stop auto spin play',
      description_5: 'Pause auto spin play',
      description_6: 'Click to collect while in the bonus game',
    },
  },
  slots: {
    gameName: 'Tragamonedas',
    game: {
      bonusScatterWild: {
        line1:
          'Cada juego tiene su propia tabla de premios y se indicará en la tabla de premios si un juego tiene un juego de bonificación, jackpots o tiradas gratis (giros gratis).',
        line2:
          'Los símbolos de Scatter (como se ven en la tabla de premios) te dan la posibilidad, dependiendo de qué juego, de ingresar en la ronda de bonificación. ¡Con un número de símbolos scatter igual o superior a 2, la ronda de bonificación se cargará automáticamente!. Cada juego de bonificación es diferente. En la ronda de bonificación puede aumentar sus ganancias sin utilizar ninguna de sus entradas. ',
        line3:
          'Los símbolos comodín se usan como un símbolo especial para completar una fila. Ejemplo: Si la línea muestra los símbolos A, A, A, B, la línea ganaría como si fuera 4 del símbolo A. (A, A, A , A, B) Los comodines también pueden ganar en una línea exactamente como otros símbolos en la mesa de premios, comenzando en la 1ª posición en una fila. Algunos juegos te dan varias cantidades de tiradas gratis (giros gratis) dependiendo de cuántos de los símbolos Wild que ves después de revelar. ',
        line4:
          'También hay varios juegos que te permitirán elegir ir al juego de bonificación cada vez que ganes en el juego principal o que se revele el símbolo comodín.',
        title: 'Juego de bonificación, símbolos Scatter y Wild',
      },
      description: {
        line1:
          'Los juegos de estilo de máquinas tragamonedas tienen diferentes variaciones y diseños. Estos pueden tener un número máximo de líneas de entrada de 1, 1 a 5 líneas, 1 a 15 líneas, 1 a 21 líneas y de 1 a 42 líneas están disponibles para su disfrute. La línea 42 es en realidad 21 líneas que comienzan con una fila de símbolos que coinciden de izquierda a derecha y lo mismo de derecha a izquierda. ',
        line2:
          'En los juegos de 15, 21 o 42 líneas, hay 5 rodillos con 3 símbolos en cada carrete, con un total de 15 símbolos que muestran cuándo el juego completa una revelación.',
        line3:
          'Las máquinas tragamonedas de una sola línea tienen 3 rodillos y posiblemente solo muestren 3 símbolos completos cuando se complete la revelación. La línea ganadora se encuentra en el medio del área de juego de izquierda a derecha.',
        line4:
          'El juego de 5 líneas también es de 3 tambores, sin embargo, las líneas ganadoras son 3 horizontales y 2 cruces cruzados.',
        title: 'Todo sobre la máquina tragamonedas',
      },
      howTo: {
        autoOffLabel: 'Auto Apagado',
        autoOff: "Pulsar este botón para deshabilitar la acción 'Auto Prendido'.",
        autoOnLabel: 'Auto Prendido',
        autoOn: 'Pulsar este botón para repetir automáticamente la apuesta anterior y revelar juegos automáticamente.',
        clearLabel: 'Retirar Apuesta',
        clear: 'Pulsar este botón para quitar el monto a jugar.',
        linesXLabel: 'Apuesta Uno',
        linesX:
          'Pulsar este botón para aumenta el número de líneas que desea jugar. Cuantas más líneas juegue, mayor es la oportunidad de ganar.',
        maxLabel: 'Apuesta Máxima',
        max:
          'Si está habilitado, al pulsar este botón seleccionrá el número máximo de líneas y revelará el juego. El juego cargará con el máximo número de líneas seleccionadas para su uso.',
        minusPlus:
          'El selector de la apuesta te permite incrementar o disminuir el monton de la entrada/moneda con la que jugará.',
        revealLabel: 'Girar',
        reveal: 'Pulsar este botón para empezar el juego.',
        paytableLabel: 'Tabla de pagos',
        title: 'Cómo entrar y jugar',
      },
      jackpot: {
        line1:
          'Si 5 de los mismos símbolos de jackpot exactos (sin incluir comodines) aparecen en una línea ganadora, solo se paga el premio mayor. El premio para esa línea como se muestra en la tabla de premios no se paga por esa línea. Las líneas y las entradas máximas usando el selector [-] y [+] pagarán la cantidad del premio mayor que se muestra en el marcador de desplazamiento. Si se usan menos de las entradas máximas usando el selector de [-] y [+], se pagará un premio proporcional.',
        line2:
          'Si hay 2 o más líneas con los 5 símbolos exactos, solo se otorga un premio por jugada. Sin embargo, el premio para la línea como se muestra en la tabla de pagos se otorgará en las líneas ganadoras adicionales.',
        title: 'Pago de Jackpot',
      },
      payoutLines: {
        line1:
          'Los juegos de estilo de máquinas tragamonedas tienen diferentes variaciones y diseños. Estos pueden tener un número máximo de líneas de entrada de 1, 1 a 5 líneas, 1 a 15 líneas, 1 a 21 líneas y de 1 a 42 líneas están disponibles para su disfrute. línea es en realidad 21 líneas que comienzan con una fila de símbolos que coinciden de izquierda a derecha y lo mismo de derecha a izquierda. ',
        line2:
          'Este no es MOBILE H5.',
        title: 'Pago de líneas',
      },
      prizeTable: {
        line1:
          'Este botón se encuentra dentro del juego para ver los premios ganadores. En el lado izquierdo de la tabla de premios hay filas que muestran el número de símbolos consecutivos que necesita para recibir el premio indicado para cada símbolo específico (que se muestra en la parte superior) de la tabla de premios). En la tabla de premios reales hay columnas que muestran su premio por los símbolos consecutivos obtenidos. Estos premios cambian según el número de entradas que eligió con las flechas [-] o [+].',
        line2:
          'La única manera de obtener símbolos consecutivos en el juego es tener una secuencia del mismo símbolo sin interrupciones comenzando desde el lado izquierdo de los rodillos. La secuencia se resaltará con las líneas de colores que se muestran en el juego, la animación de los símbolos. o cuadrados de colores que describen los símbolos ganadores. Hay otros indicadores que le indican cuánto ha ganado. El área de información principal se encuentra en la parte inferior central del juego.',
        title: 'Tabla de pagos',
      },
    },
    gameControls: {
      button1Description:
        'El selector de la apuesta te permite incrementar o disminuir el monton de la entrada/moneda con la que jugará.',
      button2Label: 'Apuesta Uno',
      button2Description:
        'Pulsar este botón para aumenta el número de líneas que desea jugar. Cuantas más líneas juegue, mayor es la oportunidad de ganar.',
      button3Label: 'Girar',
      button3Description: 'Pulsar este botón para empezar el juego.',
      button4Label: 'Apuesta Máxima',
      button4Description:
        'Si está habilitado, al pulsar este botón seleccionrá el número máximo de líneas y revelará el juego. (El juego cargará con el máximo número de líneas seleccionadas para su uso)',
      button5Label: 'Auto Prendido',
      button5Description:
        'Pulsar este botón para repetir automáticamente la apuesta anterior y revelar juegos automáticamente.',
      button6Label: 'Retirar Apuesta',
      button6Description: 'Pulsar este botón para quitar el monto a jugar.',
      buttonH5PlayValues: 'Abre un Panel para seleccionar la cantidad de líneas y monedas que desea jugar.',
      buttonH5TwoWay:
        "El botón 'Ambos sentidos' está disponible en tragamonedas especiales. Presiónelo para habilitar/deshabilitar la apuesta en líneas de derecha a izquierda.",
      buttonH5Play: 'Presione para iniciar / continuar la secuencia de giro automático o giro gratis.',
      buttonH5Stop: 'Presione para detener la secuencia de giro automático.',
      buttonH5Pause: 'Presione para pausar el giro automático o la secuencia de giro gratis.',
    },
  },
  stud: {
    gameName: 'Caribbean Stud Poker',
    game: {
      introduction: {
        line1:
          'Caribbean Stud Poker es una variación del póquer de 5 cartas añadiendo un eventual jackpot progresivo que lo hace más excitante. Se juega la baza recibida y no hay cambio de cartas. La baza del jugador se compara con la baza de la banca y el que tiene la baza mejor gana.',
        title: 'Introducción',
      },
      howToPlay: {
        line1:
          'Se comienza colocando la apuesta en el área reservada para la apuesta. Al jugador se le dan 5 cartas descubiertas, mientras que la banca descubre solo una.',
        line2: 'Entonces el jugador tiene que elegir una de las dos opciones disponibles:',
        title: 'Cómo se juega',
      },
      surrender: {
        line1: 'El jugador puede rendirse perdiendo su apuesta inicial y terminando la baza.',
        title: 'Rendirse',
      },
      raise: {
        line1:
          'El jugador coloca el doble de la apuesta inicial en el área de “riesgo” (raise) presente en la mesa. Entonces la banca recibe las otras 4 cartas descubiertas.',
        line2:
          'La banca se califica si tiene por lo menos un as y un rey o un punto más alto. Si la banca no se califica al jugador se le paga 1 a 1 sobre su apuesta inicial y la apuesta “riesgo” (raise) se devuelve como empate.',
        line3:
          'Si la banca se califica el juego avanza hasta el final en que la baza de la banca y del jugador se comparan y la baza de póquer que sea mejor gana.',
        title: 'Aumentar la apuesta',
      },
      showdownTable: {
        if: 'Si...',
        then: 'Entonces...',
        showdown1Label: 'La banca gana',
        showdown1Then: 'El jugador pierde ambas apuestas.',
        showdown2Label: 'La banca y el jugador empatan',
        showdown2Then: 'Ambas apuestas le son devueltas al jugador.',
        showdown3Label: 'El jugador gana',
        showdown3Then:
          'Al jugador se le paga 1 a 1 sobre la primera apuesta y la segunda apuesta es pagada según la tabla que sigue.',
        title: 'Comparación',
      },
      raisePayTable: {
        line1: 'Si el jugador gana, el premio por la segunda apuesta se calcula siguiendo la tabla que sigue:',
        playerHand: 'Baza del jugador',
        payout: 'Premio',
        raisePay1Label: 'Escalera Real',
        raisePay2Label: 'Escalera de Color',
        raisePay3Label: 'Póquer',
        raisePay4Label: 'Full',
        raisePay5Label: 'Color',
        raisePay6Label: 'Escalera',
        raisePay7Label: 'Trío',
        raisePay8Label: '2 Pares',
        raisePay9Label: 'Par',
        raisePay10Label: 'As-Rey',
        raisePay11Label: 'Otro',
        raisePay11Payout: 'Devolución de la apuesta',
        title: 'Premio de Aumentar',
      },
    },
    gameControls: {
      button1Label: 'Nuevo juego',
      button1Description: 'Iniciar un nuevo juego.',
      button2Label: 'Limpiar',
      button2Description: 'Poner en cero el monto a jugar.',
      button3Label: 'Mismo monto',
      button3Description: 'Repite el juego con el monto anterior.',
      button4Label: 'Revelar',
      button4Description: 'Pedirle al croupier que distribuya las cartas.',
      button5Label: 'Rendirse',
      button5Description: 'Deja la baza y pierde la apuesta inicial.',
      button6Label: 'Aumenta',
      button6Description: 'Añade a la mesa una apuesta igual al doble del valor de la apuesta inicial.',
    },
  },
  videoPoker: {
    gameName: 'Video Poker',
    game: {
      rulesOfTheGame: {
        line1:
          'El juego de una línea/fila utiliza una baraja estándar de 52 cartas, que se baraja después de cada mano. El juego multilínea usa una baraja de 52 cartas por línea/fila. ',
        line2:
          'El jugador está tratando de tener la mejor combinación de cartas que se mostrarán en la mesa de premios, que puede ser diferente para cada juego',
        line3:
          'Para obtener detalles específicos sobre cada combinación ganadora, consulte la Tabla de premios que se muestra en la pantalla.',
        deuceWild: 'Deuce & Wild Video Poker',
        deuceWildRule:
          "es una de las versiones más divertidas de Poker. Se juega de manera similar a un juego de Póquer, pero los Deuces (las cartas con el número '2') son Comodín, lo que significa que la carta Deuce puede convertirse en la carta que falta para poder consiguir la mano más alta posible. Con la carta '2-Comodín', puedes transformar cualquier Par en un Trío, o completar una Escalera.",
        changePrizeColumn: 'Cambiar columna de premios',
        draw: 'Tirar',
        title: 'Reglas del juego',
      },
      howToPlayReveal: {
        line1:
          'Elija el número de moneda que desea jugar haciendo clic en las flechas en la parte inferior derecha de la máquina. [-] o [+].',
        line2:
          "Haga clic en el botón 'Cambiar columna de premios' para aumentar su monto a jugar y cambie la columna del premio que se utilizará en la mesa de premios.",
        line3:
          "Después de elegir una de las columnas de premios en la mesa de premios, haga clic en el botón 'Revelar', recibirá 5 cartas boca arriba. (Si el jugador utiliza el botón 'Máx' para jugar, la revelación ocurrirá automáticamente) . ",
        line4:
          "Después del primer sorteo, haz clic en las cartas, si las hay, que deseas conservar. 'Retener' se mostrará en las cartas que deseas conservar",
        line5: "Haz clic en 'Tirar' y las cartas serán reemplazadas. Solo las cartas con 'Retener' no serán cambiadas.",
        line6:
          'Tus cartas se comparan con la tabla de combinaciones ganadoras en la mesa de premios del juego al que juegas. Si el valor de tu mano es menor que la combinación ganadora mínima, pierdes el monto jugado. ¡Si su mano is igual a cualquiera de los que se muestran en la mesa de premios usted gana el premio!. En la línea múltiple puede ganar con la combinación de cada línea/fila.',
        title: 'Cómo se juega',
      },
      doublePlayoffRound: {
        line1:
          "Cuando se produce una baza ganadora se tiene la posibilidad de jugar una ronda 'Doble', en el que se puede doblar (Doble) el premio. Si no se quiere participar con el Double Playoff Round, simplemente se pulsa sobre el botón 'Recoger'.",
        double: 'Doble',
        collect: 'Recoger',
        title: 'Double Playoff Round',
      },
      howToPlayDoublePlayoffRound: {
        line1: "Haga clic en el botón 'Doble'.",
        line2: 'Las cartas se mostrarán boca abajo, y luego una carta en el lado izquierdo se volverá boca arriba',
        line3: 'Haz clic en una de las cuatro cartas que permanecen boca abajo',
        line4:
          'Si la tarjeta que eliges tiene un valor mayor que la que le entregó la computadora: ganas, y la ronda Doble continúa, si lo deseas',
        line5:
          "Por favor, tenga en cuenta: si no desea continuar participando en la ronda Doble, simplemente haga clic en 'Recoger'.",
        line6: "Puede volver a hacer clic en el botón 'Doble' y se producirá el mismo ciclo.",
        line7:
          'Si la carta que eliges tiene el mismo valor que la carta entregada por la computadora, es un empate. La ronda de doble ronda continúa, si lo deseas.',
        line8:
          'Si la tarjeta que eliges tiene un valor inferior al de la tarjeta entregada por la computadora, pierdes tu juego y la ronda Doble finaliza.',
        line9:
          'Tenga en cuenta: la ronda de desempate doble termina automáticamente después de un total de cinco rondas.',
        title: 'Cómo se juega la ronda Doble',
      },
      paytable: {
        line1: 'La tabla de pagos cambiará dinámicamente los montos de los premios en función de los montos jugados',
        title: 'Paytable',
      },
      winningTable: {
        winning1Label: '2 Pares',
        winning1Description: '2 juegos de pares de la misma denominación de carta.',
        winning2Label: 'Trío',
        winning2Description: '3 cartas de la misma denominación.',
        winning3Label: '4 Ases o Ochos',
        winning3Description: 'Cuatro cartas de Ases u Ochos.',
        winning4Label: '4 Dos',
        winning4Description: 'Cuatro cartas de Dos.',
        winning5Label: '4 Sietes',
        winning5Description: 'Cuatro cartas de Sietes.',
        winning6Label: 'Póquer',
        winning6Description: '4 cartas de la misma denominación.',
        winning7Label: 'Poquerísimo',
        winning7Description: '4 cartas de la misma denominación y un Wild o el Joker.',
        winning8Label: '10s o mejor',
        winning8Description:
          'Un par paga si las cartas del par son Dieces, Jotas, Reinas, Reyes o Ases. Los pares inferiores no pagan.',
        winning9Label: 'Color',
        winning9Description: '5 cartas de denominación no consecutivas, todas del mismo palo.',
        winning10Label: 'Full',
        winning10Description:
          'Un conjunto de 3 cartas de la misma denominación, más un conjunto de 2 cartas de la misma denominación.',
        winning11Label: 'HI Escalera Real',
        winning11Description:
          '5 cartas de denominación consecutiva, todas del mismo palo, comenzando desde 10 y terminando con un as.',
        winning12Label: 'Jotas o mejor',
        winning12Description:
          'Un par paga si las cartas del par son Jotas, Reinas, Reyes o Ases. Los pares inferiores no pagan.',
        winning13Label: 'Escalera Real con Joker',
        winning13Description:
          '5 cartas de denominación consecutiva, todas del mismo palo, comenzando desde 10 y terminando con un as, completada por una carta Joker',
        winning14Label: 'LO Escalera Real',
        winning14Description:
          '5 cartas de denominación consecutiva, todas del mismo palo, comenzando desde 2 y terminando con un 6.',
        winning15Label: 'Escalera Real',
        winning15Description:
          '5 cartas de denominación consecutiva, todas del mismo palo, comenzando desde 10 y terminando con un as, sin cartas Wild o Joker',
        winning16Label: 'Escalera',
        winning16Description: '5 cartas de denominación consecutiva, no del mismo palo.',
        winning17Label: 'Escalera de Color',
        winning17Description: '5 cartas de denominación consecutiva, todas del mismo palo.',
        winning18Label: 'Escalera Real con Wild',
        winning18Description:
          '5 cartas de denominación consecutiva, todas del mismo palo, comenzando desde 10 y terminando con un as, completada por una o más cartas Wild',
        title:
          'Combinaciones ganadoras como se muestra en las tablas de premios para cada juego diferente de Video Poker',
      },
    },
    gameControls: {
      button1Description: 'Selector de la apuesta. Te permite incrementar o disminuir el monto a jugar.',
      button2Label: 'Cambiar columna de premios',
      button2Description:
        'Presione una o más veces para seleccionar una de las 5 columnas de la tabla de premios. Cada vez que haga clic, el número jugado de las recompensas se multiplicará por 1,2,3,4,5.',
      button3Label: 'Reveal',
      button3Description: 'Después de que hayas colocado el monto a jugar, presiona para iniciar el juego!',
      button4Label: 'Doble',
      button4Description: 'Presione para tratar de ganar el doble de la ganancia que acaba de recibir.',
      button5Label: 'Recoger',
      button5Description: 'Presione para recoger sus ganancias.',
      description_1: 'Play button. Press to start a game',
      description_2: 'Draw button. After holding your chose cards, press to reveal new ones',
      description_3: 'Increase coin value',
      description_4: 'Decrease coin value',
      description_5: 'Double button. Click to enter the double playoff round',
      description_6: 'Collect button. Click to collect your winnings, and pass your chance to double your winnings',
      description_7: 'Clear button. Click to clear the game in order to start a new one',
      description_8: 'Show prize table. This button is only available in Multi, 18 and 50 games',
    },
  },
  zipTabs: {
    gameName: 'Zip Tabs',
    game: {
      introduction: {
        line1:
          'Easy, fun and colorful. This game has to be one of our easiest to play. The objective of these games is to get three of the same symbol in one row of the card.',
        title: 'Introducción',
      },
      howToPlay: {
        line1:
          'Selecciona el monto de Costo de Tarjeta mediante los pulsantes de flecha más o flecha menos. Nota que la mesa de pago irá cambiando en consecuencia.',
        line2: 'Haz clic en Compra Tarjeta.',
        line3: 'Haz clic en Pelar Todas, o haz clic en Pelar cada línea por separado.',
        line4: 'Para jugar de nuevo, es suficiente hacer clic en Compra Tarjeta de nuevo.',
        line5: 'El monto del Costo de Tarjeta es el mismo hasta que lo cambies.',
        title: 'Cómo se juega',
      },
    },
    gameControls: {
      button1Label: 'Pelar todas',
      button1Description: "Presiona el botón 'Pelar todas' para abrir cada línea.",
      button2Label: 'Comprar una tarjeta',
      button2Description: "Luego de escoger el monto, presione el botón 'Comprar una tarjeta' para empezar un juego!.",
      button3Label: 'Selector',
      button3Description: 'Selector de la apuesta. Te permite incrementar o disminuir  el monto a jugar.',
    },
  },
  generalInfo: {
    introduction: {
      title: 'Introducción',
      line1:
        'Nuestros juegos están diseñados para ser fáciles de aprender y jugar. ¡Solo unos pocos conceptos simples lo tendrán en funcionamiento en muy poco tiempo!',
      line2:
        'Esta página de ayuda que está leyendo ahora le mostrará cómo jugar nuestros juegos, cómo personalizar los juegos según sus preferencias personales y cómo obtener información sobre su historial de juego. Estas páginas de ayuda solo cubren cosas que se incluyen en todos los juegos, y no entra en detalles en ningún juego específico.',
    },
    gameInterface: {
      title: 'La interfaz del juego',
      line1: 'Todos los juegos contienen las siguientes áreas y/o botones:',
      balance: {
        title: 'Displays de saldo y monto jugado',
        line1:
          'En la parte inferior izquierda hay dos pantallas que muestran el saldo actual disponible para usar: el dinero disponible para jugar. También muestra la cantidad de entradas que acaba de usar en un juego o que está a punto de usar en el juego actual.',
      },
      message: {
        title: 'Display de mensaje',
        line1:
          'En el centro de la ventana en la parte inferior se muestran los mensajes del juego, como si has ganado y cuánto has ganado, etc.',
      },
      options: {
        title: 'Botón de opciones',
        line1:
          'Al hacer clic en el botón Opciones en el área inferior derecha de la pantalla, se mostrará la ventana Opciones del juego. Puede personalizar el juego de las siguientes maneras:',
        speed: {
          title: 'Velocidad del juego',
          line1:
            'Los juegos tienen una configuración de velocidad entre 1 y 3. La configuración predeterminada es 2. Las velocidades más rápidas hacen que las animaciones se aceleren para que puedas jugar más rápido.',
        },
        color: {
          title: 'Color de mesa',
          line1: 'Solo aplicable a los juegos de mesa, esta opción te permite elegir el aspecto de tu juego.',
        },
        sounds: {
          title: 'Sonidos',
          line1: 'Usted puede activar las voces y los efectos de sonido por separado.',
        },
        soundFx: {
          audio: 'Audio',
          title: 'Sonido',
          line1: 'Puede alternar los efectos de sonido.',
        },
        voices: {
          title: 'Voces',
          line1: 'Puede alternar las voces.',
        },
        yes: 'Si',
        no: 'No',
        options: 'opciones',
        settings: 'ajustes',
      },
      history: {
        title: 'Botón de historial',
        line1:
          'Al hacer clic en este botón se mostrará una ventana emergente que contiene una lista completa de todas tus jugadas y resultados para el juego actual.',
      },
      help: {
        title: 'Botón de ayuda',
        line1:
          'Al hacer clic en el botón Ayuda se muestra una ventana emergente que contiene la Ayuda para el juego actual que se carga.',
      },
    },
    playSequence: {
      title: 'La secuencia del juego',
      line1:
        'Los juegos de mesa se juegan abriendo juegos, eligiendo límites de mesas, colocando fichas en la mesa, jugando y observando las elecciones de los resultados del juego.',
      line2:
        'Los juegos de tragamonedas / Keno se juegan al abrir y elegir el valor de la moneda, el número de líneas, hacer clic en revelar y observar el resultado de las elecciones de juego.',
    },
  },
}
