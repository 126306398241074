export default {
  menu: {
    theGame: 'The game',
    theControls: 'The controls',
    generalInfo: 'General info',
    buttonControl: 'Button/Control',
    description: 'Miêu tả',
  },
  baccarat: {
    gameName: 'Baccarat',
    game: {
      introduction: {
        line1:
          "Baccarat is considered a high-roller's game, and is usually played behind closed doors or in roped-off sections of a casino.",
        line2:
          "Recently, a variation called 'Mini Baccarat' was developed to popularize the game in the United States and online. Mini-Baccarat is played on a standard Blackjack-sized table.This is the version that is offered here and it uses 8 decks.",
        title: 'Introduction',
      },
      basicRules: {
        line1:
          'The goal of Baccarat is to have a hand with a score total closest to 9. Aces count as 1; cards 2 to 9 count as their face value; tens and picture cards count as zero (see Table on the right). If the total value of the hand is equal or greater than 10, then subtract 10 and count the remainder as the value of the hand. For example, the hand 8,6 would have a Baccarat value of 4 (8+6=14, 14-10=4); the hand 3,7 would have a Baccarat value of 0 (3+7=10, 10-10=0).',
        line2:
          "Place your coins on the Banker's area and/or on the Player's area and/or on the Tie area. The Player and the Banker are each shown a two-card hand. In certain cases, a third card is given to the Player or the Banker or both (Third Card Rules).",
        line3: 'The Player closest to 9, wins.',
        line4:
          'If you place any coins on the Player and the Player wins, you will receive double of the value of your intial coin/coins value',
        line5:
          'If you place any coins on the Banker and the Banker wins, you will recieve double of the value of your intial coin/coins value minus 5% bank commission. ( example : winning with a play of 10 you will get back your 10 plus the 10 for the win, minus 5% ( equals 9.5 ) based on the following calculation 10 + 9.5 = 19.5).',
        line6:
          "If both the Player's cards and the Banker's cards have equal totals, the game result is a Tie. If you have placed any coins on the Tie field, your win is 8 times your intial coin/coins. (playing 10 the win is 10 + 80 = 90).",
        title: 'Basic Rules',
        cardTable: {
          card: 'Card',
          value: 'Value',
          faceValue: 'Face Value',
          card1Label: 'Aces',
          card2Label: 'Cards 2-9',
          card3Label: '10s',
          card4Label: 'Face Cards',
        },
      },
      thirdCardRules: {
        line1:
          'In some cases, after two cards are shown to the the Player and Banker, a Third Card will be presented to the Player and/or Banker. The following two tables shows you when this will happen:',
        line2:
          "After the Player's third card is displayed, the Banker may be given a third card based on the following table:",
        line3:
          'NOTE: If either the Player or Banker has a total of 8 or 9, then neither player receives any cards. This rule overrides any other rule.',
        title: 'Third Card Rules',
        from: 'from',
        to: 'to',
        or: 'or',
        playerHandTable: {
          value: "If Player's Hand Value is:",
          then: 'Then the Player:',
          playerHand1Then: 'A third card is given.',
          playerHand2Then: 'Stands.',
          playerHand3Then: "Has a 'Natural Hand' - no further cards.",
        },
        bankerHandTable: {
          value: "Banker's Hand Value:",
          if: "Banker draws if Player's 3rd Card is:",
          then: "Banker does not draw if Player's 3rd Card is:",
          bankerHand1If: 'Any card',
          bankerHand6If: 'No cards given',
          bankerHand7If: 'Natural hand (No further cards)',
        },
      },
    },
    gameControls: {
      button1Label: 'New game',
      button1Description: 'Begins a new game.',
      button2Label: 'Clear',
      button2Description: 'Clear the amount to play.',
      button3Label: 'Rebet',
      button3Description: 'Repeats your previous wager.',
      button4Label: 'Deal',
      button4Description: 'Instructs the dealer to deal the cards.',
    },
  },
  blackjack: {
    gameName: 'Blackjack',
    game: {
      introduction: {
        line1:
          "The history of the BlackJack card game itself is still disputed but was probably spawned from other French games such as 'chemin de fer' and 'French Ferme'. BlackJack originated in French casinos around 1700 where it was called 'vingt-et-un' ('twenty-and-one') and has been played in the U.S. since the 1800's. BlackJack is named as such because if a player got a Jack of Spades and an Ace of Spades as the first two cards (Spade being the color black of course), the player was additionally remunerated. Blackjack also can have many variations such as when player can double, payout of a Blackjack, when dealer will hit or stand as well as others.",
        title: 'Introduction',
      },
      basicRules: {
        line1:
          'The object of BlackJack is for the total of your cards to be closer to 21 than the cards shown at the top of the table, without exceeding 21.',
        line2: 'In BlackJack, Aces count as either 1 or 11, face cards as 10, and number cards as their face value.',
        line3:
          'If you receive an Ace and a ten-value card as your first two cards, you have Blackjack and win one and a half times your wager (Example:if your play was 10, you receive 10 plus 15 for a total of 25). If the total value of your cards is closer to 21 than the cards shown, you win as much as you had played (Example:if your play was 10, you receive 10 plus 10 for a total of 20). If the total of your cards are more than 21, you bust and lose your points. If you and the cards that are displayed have the same card total (17 and up) neither of you win and your points are returned to you in a push. BlackJack (made up of two hands) beats a score of 21.',
        title: 'Basic Rules',
      },
      split: {
        line1:
          'If your first two cards have the same value, you may split them into two separate hands by clicking the split button, more coins equal to the original play will be placed on the table.',
        line2:
          'You then proceed to get cards. If you split two aces and one of your hands totals 21, it is counted as 21 and not BlackJack. If splitting aces, only one additional card is dealt. Only one split per hand is allowed in these games, no resplitting.',
        title: 'Split',
      },
      double: {
        line1:
          'After you are given two cards and you think that one additional card will allow you to beat the dealers displayed cards, you will need to increase your initial played coin value by the same amount as your original wager. You will receive only one more card. There may be additional variations of when you are able to double. Example; any time after the first 2 cards, total of the cards is between 8 and 11, or total of cards is 10 or 11.',
        line2: 'Please check with the game information parameters when mousing over a game icon.',
        title: 'Double',
      },
      insurance: {
        line1:
          'Insurance is offered only when the first card presented is an Ace. Insurance is considered to be a game of its own within BlackJack. It allows you to try and protect yourself when you think that the cards of the dealer shown on the table may be a BlackJack. When buying insurance, you place half of your initial coin value by pressing the Insurance button. If the dealers cards displayed show BlackJack, you are paid 2 to 1 on your insurance play.',
        line2:
          'Example: if you play 10 and insure 5, if the cards shown reveal BlackJack whether you tie or lose with your cards, you will win 5 plus 10 for a total of 15. If the cards shown at the top of table don’t reveal a BlackJack, you lose your Insurance wager amount.',
        title: 'Insurance',
      },
      checkingForBlackJack: {
        line1:
          'If the first of the 2 dealer cards presented at the top of table is an ace, the second card will be checked or peeked at when face down. If the second card presented is a face card or a 10 (BlackJack) the card will turn over, display the value and game the will finish. However if the second card shown at the top of the table does not complete a BlackJack the card remains face down and the game continues.',
        line2:
          'If the first card displayed at the top of the table is a 10 or a face card, no insurance will be offered, the second card will be checked (peek) and if it is an Ace the card will turn over, display the value and game will finish. Those cards win. However, if the second card shown at the top of the table does not complete a BlackJack the card remains face down and the game continues.',
        title: 'Checking for BlackJack',
      },
      variations: {
        line1:
          'There are many variations to Blackjack and please note the following to the games offered for your enjoyment here:',
        title: 'Variations',
        blackjackSwitch: {
          title: 'Blackjack Switch',
          line1:
            'Pays 1:1 when player gets a Blackjack, Must play 2 hands, 2nd card of each hand can be switched with other hand. If dealers cards total 22 and player has not busted the game is a push and the player receives the original wager.',
        },
        blackjackDoubleExposure: {
          title: 'Blackjack Double Exposure',
          line1:
            'Blackjacks pay 1:1, all cards are dealt face up, Dealer will win on all ties except if both hands are a Blackjack then it is a push.',
        },
        winningsTable: {
          winnings: 'Winnings',
          pays: 'Pays',
          win1Label: 'Winning Hand',
          win2Label: 'Insurance',
          win3Label: 'Blackjack',
        },
      },
    },
    gameControls: {
      button1Label: 'New game',
      button1Description: 'Begins a new game.',
      button2Label: 'Clear',
      button2Description: 'Clear the amount to play.',
      button3Label: 'Rebet',
      button3Description: 'Repeats your previous wager.',
      button4Label: 'Deal',
      button4Description: 'Instructs the dealer to deal the cards.',
      button5Label: 'Insurance',
      button5Description: 'Activates Insurance for half your original entry.',
      button6Label: 'Split',
      button6Description: 'Splits your hand.',
      button7Label: 'Hit',
      button7Description: 'Instructs the dealer to deal you another card.',
      button8Label: 'Stand',
      button8Description: 'Indicates to the dealer that you do not want any more cards.',
    },
  },
  casinoWar: {
    gameName: 'Casino War',
    game: {
      introduction: {
        line1:
          "Casino War, also known as Battle Royale, is a simple game based on the children's card game, War. The player and the hand on the table across from you each receive one face-up card. The high card wins and when there is a tie, the player may surrender or go to war for the chance to win.",
        title: 'Introduction',
      },
      howToPlay: {
        line1: 'Casino War uses one 52-card deck.',
        line2: 'Cards are ranked as in poker, except that aces are always high.',
        line3: 'Suits do not matter in Casino War.',
        line4:
          "Begin by placing coins on the designated area on the table. Then, click on the ‘Play’ button, the Player and the hand on the table across from you will each be given one card face-up. If the Player's card is higher than the card on the table across from you, the player wins even coin value (1:1). If the card on the table across from you is higher, the player loses the coin amount played.",
        line5: 'When the cards are of equal rank, the player has two choices:',
        title: 'How to Play',
      },
      surrender: {
        line1: 'Player surrenders and forfeits half the original coin value wagered.',
        title: 'Surrender',
      },
      goToWar: {
        line1:
          'If the player chooses to go to war, additional coins are placed on the table which are equal to the original coins wagered. The player is given another card face-up.',
        line2:
          "The position on the table across from you is given three 'scratch' cards facedown, and another card face up.",
        line3:
          "If the player's second card beats the card which is face up on the table across from you, the player is paid even (1:1) on the Raise only and the Original coin value waged is pushed (returned to the player).",
        title: 'Go to War',
      },
      tiePlays: {
        line1:
          'An optional Tie play is also available, which pays 10 to 1 if the first card of the player and the first card of the hand on the table across from you have the same value. Anything other than a tie results in a loss of the coins.',
        title: 'Tie Plays',
      },
    },
    gameControls: {
      button1Label: 'New game',
      button1Description: 'Begins a new game.',
      button2Label: 'Clear',
      button2Description: 'Clear the amount to play.',
      button3Label: 'Rebet',
      button3Description: 'Repeats your previous wager.',
      button4Label: 'Deal',
      button4Description: 'Instructs the dealer to deal the cards.',
      button5Label: 'Surrender',
      button5Description: 'Surrenders when a tie results.',
      button6Label: 'War',
      button6Description: 'Goes to war when a tie results.',
    },
  },
  craps: {
    gameName: 'Craps',
    game: {
      introduction: {
        line1: 'Craps, or Dice game, is a game of luck with ancient origins.',
        title: 'Introduction',
      },
      comeOut: {
        line1:
          "A new game in Craps always begins with what is called a come out roll, which is the shooter's first throw. The most basic and common bet in Craps is the pass line bet (or its opposite, the don't pass bet).",
        line2:
          "On the come out roll, a pass line bet wins if the shooter rolls a 7 or an 11 (called a natural ), and loses his or her bet if the roll is a 2, 3, or 12 (called craps). If the shooter rolls a 4, 5, 6, 8, 9, or 10 on the come out roll, this number becomes the shooter's point which the dealer marks on the table with a puck -- a black and white marker placed white side up in that numbered space. The shooter's new goal is to roll this same number again to win (called a pass ) before rolling the number 7 (which would lose the bet).",
        line3:
          "The don't pass bet, being opposite to the pass line bet, loses on a 7 or 11, and wins on a 2 or 3. A 12 is considered a 'push' (tie) for a don't pass so the bet is neither won nor lost. If the shooter rolls a 4, 5, 6, 8, 9, or 10, this becomes the new point and the shooter must now roll a 7 (to win) before rolling the point number again (which would lose the bet).",
        title: "Come Out Roll / Pass Line and Don't Pass Bets",
      },
      come: {
        line1:
          "A come bet is practically the same bet as a pass line bet. Likewise, the don't come bet is similar to the don't pass bet. The only difference is that pass line and don't pass bets can only be made on the come out roll, while come and don't come bets can only be made after a point has been established. If the shooter rolls a 4, 5, 6, 8, 9 or 10 on the come out roll, play continues but the shooter can no longer place any pass line or don't pass bets. This is when come bets and don't come bets can made.",
        line2:
          "A come bet wins if the next roll is a 7 or 11, just as on the first roll for a pass line bet. The bet loses if the shooter rolls a 2, 3, or 12. Any other number causes the bet to be moved from the large 'come' area on the table to the smaller box containing that number located just above the 'come' area. Once a bet has been moved to one of these smaller boxes, the bet wins when the shooter rolls that number again or loses if a 7 is rolled first. The don't come bet is opposite to the come bet, losing on a 7 or 11, and winning on a 2 or 3. Just like the don't pass bet, a 12 is considered a push (tie) and the don't come bet is neither won nor lost. Any other number causes the bet to be moved behind the box containing that number into the don't come box. This bet will now win if you roll a seven before rolling this number again, or lose the bet if the number turns up first.",
        title: "Come and Don't Come Bets",
      },
      oddsBets: {
        line1:
          "The odds bet can only be made after you've made a pass line bet, don't pass bet, come bet, or don't bet and a point is established. Once the point is established for your pass line or come bet, you can place an odds bet up to the maximum odds bet for that number (see below). The odds bet is won or lost whenever the associated pass line, don't pass, come or don't come bet wins or loses. The difference is that player is paid true odds on the odds bet when it wins. For example, the true odds for a 4 and 10 are 2:1 (read 2 to 1). Suppose that the shooter has a point of 4 established with a $5 passline bet and a $10 odds bet on the pass line. If the point is made by rolling another 4, he or she would win even money (1:1) on the pass line bet ($5) but would win true 2:1 on the odds bet ($20).",
        point: 'Point',
        trueOdds: 'True Odds',
        and: 'and',
        title: 'Odds Bets',
      },
      maximumOdds: {
        line1: 'The maximum odds bet that can be placed on a pass line or come bet depend on the point they are.',
        line2: 'The maximum odds bet for the don’t pass line or don’t come bet is 6 times the base bet.',
        point: 'Point',
        maximumOdds: 'Maximum Odds',
        and: 'and',
        times: 'Times',
        title: 'Maximum Odds',
      },
      oddsOnTheDontCome: {
        line1:
          "When a player wants odds on a don't come or don't pass bet, it is called laying odds as opposed to taking odds with a come or pass line bet. However, because don't come and don't pass bets want a seven to roll before the point, the true odds for don't come and don't pass bets are opposite those of come and pass line bets. For example, the true odds for a 4 and 10 are 2:1, which means the odds on don't come and don't pass bets will pay 1:2. Suppose that the shooter has a point of 4 established with a $5 don't pass bet and a $10 odds bet on the don't pass. If the shooter rolls a seven before rolling another 4, he or she would win even money (1:1) on the don't pass bet ($5) and would win 1:2 on the odds bet ($5).",
        title: "Odds on the Don't Come or Don't Pass Bets",
      },
      placeBets: {
        line1:
          'Place bets allow the player to bet that the number will be rolled before a 7. If a 7 is rolled first, the bet is lost. This is similar to a come bet or pass line bet except the number is chosen by the player (as opposed to the outcome of the following roll) and there is no chance to win on a 7 or 11 with the next roll, or lose it on a 2, 3, or 12. The only numbers that the player can place are the possible point numbers: 4, 5, 6, 8, 9 and 10. Place bets are made in the box just below the box marked with the number you want to place. Place bets must be made in increments of $5 on the 4, 5, 9 and 10, and $6 increments on the 6 and 8. The reason for this is that the odds are 9:5 for the 4 and 10, 7:5 for the 5 and 9, and 7:6 for the 6 and 8.',
        title: "Place Bets and Don't Place",
      },
      buyBets: {
        line1:
          'A buy bet is similar to a place bet. The buy bet says that the number bet on will be rolled before a 7. However, the buy bet offers the player true odds on his or her bet by having the player pay a 5%t vigorish (a commission). The commission is paid at the time the bet is made. For example, if the player wants to make a buy bet for $20 on the 10, he or she must actually make the bet for $21 -- $20 plus $1 (5% of $21). If the roll wins, he or she is paid true odds (2:1 for the 10) on $20 which would equal $40. This version of craps assumes that every buy bet made includes the 5% commission, which his calculated by dividing the amount bet by 1.05 ($21 / 1.05 = $20).',
        title: 'Buy Bets',
      },
      layBets: {
        line1:
          'A lay bet is the opposite of a buy bet. With a lay bet, the player is hoping that a seven will be rolled before the number bet on. If a 7 is rolled before that number turns up again, the bet is won. If the number bet on rolls before a 7, the bet is lost. If any other number is rolled, nothing happens to the bet. However, just like a buy bet, a lay bet pays true odds, and the player must pay a 5% vigorish (commission) to make this bet. One difference is that the vigorish for a lay bet is 5 percent of the amount the player wins if the lay bet is successful. Remember that true odds when betting against the number are opposite to the true odds when betting for the number. For example, assume the player has a lay bet of $40 on the ten. If the player rolls a ten before a 7, he or she wins true odds (1:2) on the bet for a total of $20. The 5% commission is then charged on the winning amount (5% of $20 = $1) so the player is given $19.',
        title: 'Lay Bets',
      },
      Big6OrBig8: {
        line1:
          'The Big 6 and Big 8 bets are made in lower right hand corner of the table marked with a big red 6 and 8. The bet works the same as a place bet on the 6 or 8 except that the minimum bet on the Big 6 and Big 8 is simply the table minimum, and the Big 6 and Big 8 pay even money. Just like a place bet, this bet wins if whichever of the two the player selects is rolled before a 7. If a 7 is rolled first, the bet is lost.',
        title: 'Big 6 or Big 8',
      },
      fieldBet: {
        line1:
          "This is a bet that on the next roll, one of the following seven numbers with turn up: 2, 3, 4, 9, 10, 11, or 12. The bet is lost if a 5, 6, 7, or 8 is rolled. If the winning roll is a 2 or 12, the bet pays 2:1. All other winning rolls pay even money (1:1). The bet is made by simply placing an amount in the area marked 'field' between the don't pass bar and the come area.",
        title: 'Field Bet',
      },
      anySeven: {
        line1:
          'This is a one-roll bet that the shooter will roll a 7 on the next roll. If the next roll is a 7, the player wins 4:1 on his or her bet. If any other number is rolled, the bet is lost.',
        title: 'Any Seven',
      },
      anyCraps: {
        line1:
          'This is a one-roll bet that the shooter will roll a 2, 3, or 12 on the next roll. If the next roll is a 2, 3 or 12, the player wins 7:1 on his or her bet. If any other number is rolled, the bet is lost.',
        title: 'Any Craps',
      },
      hornTwelve: {
        line1:
          'This is a one-roll bet that the shooter will roll a 12 on the next roll. If the next roll is a 12, the player wins an exciting 30:1 on his or her bet. If any other number is rolled, the bet is lost.',
        title: 'Horn Twelve',
      },
      hornTwo: {
        line1:
          'This is a one-roll bet that the shooter will roll a 2 on the next roll. If the next roll is a 2, the player wins an exciting 30:1 on his or her bet. If any other number is rolled, the bet is lost.',
        title: 'Horn Two',
      },
      hornEleven: {
        line1:
          "This is a one-roll bet that the shooter will roll an 11 (nicknamed 'yo' to limit the confusion of yelling 'seven' and 'eleven') on the next roll. If the next roll is an 11, the player wins a 15:1 on his or her bet. If any other number is rolled, the bet is lost.",
        title: 'Horn Eleven',
      },
      hornThree: {
        line1:
          'This is a one-roll bet that the shooter will roll a 3 on the next roll. If the next roll is a 3, the player wins 15:1 on his or her bet. If any other number is rolled, the bet is lost.',
        title: 'Horn Three',
      },
      hardWays: {
        line1:
          "When the shooter rolls and both dice turn up with the same number, it's called a hard way . Rolls of 2 and 12 are not considered hard ways because either total can only be rolled by one combination (two ones or two sixes). In the case of other hard way bets, the total can be rolled by other combinations of the dice. For example, a hard way 10 (or hard 10) means two 5's were rolled on the dice for a total of 10. However, a 10 can also be achieved by a 6 and 4.",
        title: 'Hard Ways',
      },
      hard4: {
        line1:
          'When the player bets a hard 4, he or she is hoping for a 2 to come up on both dice before any other 4 combination or a 7 turns up. If the shooter rolls a 1 and 3, or a 7, the bet is lost. The payout for a hard 4 is 7:1.',
        title: 'Hard 4',
      },
      hard10: {
        line1:
          'When the player bets a hard 10, he or she is hoping for a 5 to come up on both dice before any other 10 combination or a 7 turns up. If the shooter rolls a 6 and 4, the bet is lost. The payout for a hard 10 is 7:1.',
        title: 'Hard 10',
      },
      hard6: {
        line1:
          'When the player bets a hard 6, he or she is hoping for a 3 to come up on both dice before any other 6 combination or a 7 turns up. If the shooter rolls a 1 and 5, 2 and 4, or a 7, the bet is lost. The payout for a hard 6 is 9:1.',
        title: 'Hard 6',
      },
      hard8: {
        line1:
          'When the player bets a hard 8, he or she is hoping for a 4 to come up on both dice before any other 8 combination or a 7 turns up. If the shooter rolls a 2 and 6, 3 and 5, or a 7, the bet is lost. The payout for a hard 8 is 9:1.',
        title: 'Hard 8',
      },
      payouts: {
        line1:
          'The payout in craps often varies depending on what point has been established or what total the player is trying to roll. The table below lists all the payouts available in craps. In some cases, the payout lists the numbers for which this payout applies.',
        line2:
          "For example, 'Pass Line Odds - 4 and 10' is the payout for a successful odds bet on the Pass Line when the point is 4 or 10. 'Come Odds - 6 and 8' is the payout for a successful odds bet on a Come in the 6 or 8 position.",
        bet: 'Bet',
        payout: 'Payout',
        and: 'and',
        title: 'Payouts',
      },
    },
    gameControls: {
      button1Label: 'Roll',
      button1Description: 'Initiates the throwing of the dice.',
      gameTips: {
        title: 'Game Tips',
        tip1Label: 'CONTROL+CLICK',
        tip1Description: 'To remove a bet',
        tip2Label: 'SHIFT+CLICK over a bet',
        tip2Description: "To switch between 'on', 'off'.",
      },
    },
  },
  crazyPoker: {
    gameName: 'Crazy Poker',
    game: {
      introduction: {
        line1:
          'Crazy Poker is a table game played against the dealer. It is similar to Three Card Poker but, as the name states there is a crazy difference.',
        title: 'Introduction',
      },
      basicRules: {
        line1:
          'Crazy Poker is based on four cards. The player and the dealer each receive five cards to make their best four-card poker hand. A four card straight is a straight, a four card flush is a flush, and a four card straight flush is a straight flush.',
        title: 'Basic Rules',
      },
      start: {
        line1:
          'Place the equal amount/value of coins on both the Ante and Super Bonus circles. Optionally, you can place coins on the Queens Up circle.',
        line2:
          'Once the coins are placed, click the button at the bottom of the table, you and the dealer each receive 5 cards.',
        title: 'Start',
      },
      playAndAnteWagers: {
        line1:
          'After seeing your cards, you either click Fold or Call. By clicking Call, coins equal to the ante will be placed on the Play circle.',
        title: 'Play and Ante wagers',
      },
      tripleDownOption: {
        line1:
          "If you have a pair of Aces or better in your hand, you will be presented with the option to triple your PLAY AMOUNT. By clicking on the TRIPLE button, you can reveal three times your Ante ('Triple Down').",
        title: 'Triple Down option',
      },
      howToWin: {
        line1:
          "There are two ways to win: (1) when the dealer does not qualify or (2) when a player's hand beats the dealer's qualifying hand. The dealer will qualify with a King-high or better. You will receive 1:1 payout  on your Play wager when the dealer fails to qualify and the Ante amount is a push. You will receive 1:1 payout on both your Play and Ante wagers when you beat the dealer's qualifying hand. You lose all your Play and Ante amounts when your hand loses to a dealer's qualifying hand.",
        title: 'How to Win',
      },
      superBonus: {
        line1:
          'The Super Bonus must be the same amount of coins as the Ante. You lose the coins in two ways: (1) If you fold your hand or (2) You lose to the dealer AND your hand is less than a straight. Your Super Bonus wins whenever you have a straight or better. It pays based on the super bonus paytable.',
        title: 'Super Bonus',
      },
      queensUp: {
        line1:
          "The optional Queens Up play is the player's hand versus the posted paytable. It takes a pair of Queens or better to win. These payout when played are not affected by what the dealer's hand is.",
        title: 'Queens Up',
      },
    },
    gameControls: {
      button1Label: 'New game',
      button1Description: 'Begins a new game.',
      button2Label: 'Clear',
      button2Description: 'Clear the amount to play.',
      button3Label: 'Rebet',
      button3Description: 'Repeats your previous wager.',
      button4Label: 'Deal',
      button4Description: 'Instructs the dealer to deal the cards.',
      button5Label: 'Call',
      button5Description: 'Turns the dealer’s cards.',
      button6Label: 'Fold',
      button6Description: 'Folds your hand.',
      button7Label: 'Triple Down',
      button7Description: 'Triples your Ante.',
    },
  },
  hooHeyHow: {
    gameName: 'Hoo Hey How',
    game: {
      introduction: {
        line1: 'This is an old, simple East-Asian variant of the lottery dice game of Crown & Anchor.',
        line2:
          'There are 3 dice and each side has a different picture which matches with the game table. 6 pictures in total include a fish, shrimp, rooster, crab, bottle and a coin.',
        title: 'Introduction',
      },
      rulesOfGame: {
        line1:
          'Choose a coin value and place it on one or more of the pictures on the table. The only limit of how much you can play is the overall table limit. When completed placing the coins, click on the roll button and the dice cage will spin. When it stops the dice will display showing final pictures. If any of the dice match to one or more of the pictures where you placed coins....you win!.',
        title: 'Rules of Game',
        dice: 'dice',
        dicePlural: 'dice',
        match: 'Match',
        payout: 'Payout',
      },
    },
    gameControls: {
      button1Label: 'New game',
      button1Description: 'Begins a new game.',
      button2Label: 'Clear',
      button2Description: 'Clear the amount to play.',
      button3Label: 'Rebet',
      button3Description: 'Repeats your previous wager.',
      button4Label: 'Roll',
      button4Description: 'Initiates the cage with the dice to turn.',
    },
  },
  keepEmPoker: {
    gameName: "Keep 'em Poker",
    game: {
      introduction: {
        line1:
          "Keep 'em Poker is based upon five-card Stud Poker. In Keep 'em, the player is not competing with other players or the hand on the table across from you. The objective is to make the best possible poker hand from the initial three cards given and the two 'community cards' which are subsequently exposed.",
        title: 'Introduction',
      },
      howToPlay: {
        line1: "The player places three coins of equal value in the circles marked '1', '2' and '3'.",
        line2:
          'The player is given three cards face up and the two community cards are placed in the center of the table, face down.',
        line3:
          "After looking at the first three cards, the player may withdraw the first coin or may 'Keep Em', keeping it in play.",
        line4: "The first of the two community cards is then revealed, becoming the player's fourth card.",
        line5: "The player may now choose to Pull Out the second coin played or 'Keep em'.",
        line6:
          'Finally, the second community card is revealed and the resulting five-card poker hand is used to determine the result. If the player ends up with at least a pair of tens or higher, the player will receive a payout (see PAYOUTS).',
        line7:
          'Please note that withdrawing the second coin is independent of what was done with the first. Regardless of the decision made concerning the first or second plays, the player may not withdraw the third coin played.',
        title: 'How to Play',
      },
      bonusGame: {
        line1:
          'When placing the 3 initial wagers, the player may make an optional 1 coin play ( a side play). Qualifying poker hands could win up to 20,000 (see below).',
        title: 'Bonus Game',
      },
      payoutTable: {
        playerHand: 'Player Hand',
        basicPayout: 'Basic payout',
        sideBetPayout: 'Side Bet payout',
        payout1Label: 'Royal Flush',
        payout2Label: 'Straight Flush',
        payout3Label: '4 of a Kind',
        payout4Label: 'Full House',
        payout5Label: 'Flush',
        payout6Label: 'Straight',
        payout7Label: '3 of a Kind',
        payout8Label: '2 Pair',
        payout9Label: '10s or better',
        title: 'Payout Table',
      },
    },
    gameControls: {
      button1Label: 'New game',
      button1Description: 'Begins a new game.',
      button2Label: 'Clear',
      button2Description: 'Clear the amount to play.',
      button3Label: 'Rebet',
      button3Description: 'Repeats your previous wager.',
      button4Label: 'Deal',
      button4Description: 'Instructs the dealer to deal the cards.',
      button5Label: 'Keep em',
      button5Description: 'Instructs the dealer to reveal the community cards.',
      button6Label: 'Pull out',
      button6Description: 'Refunds the first and second bets.',
    },
  },
  keno: {
    gameName: 'Keno',
    game: {
      introduction: {
        line1:
          'Keno is an exciting game that was first introduced in China around 200 B.C. It came to the United States with the immigrants and over time evolved into the game you now see before you.',
        title: 'Introduction',
      },
      rules: {
        line1:
          'The Keno game is played with a traditional 80-numbered ticket and 20 balls whose function is the same as in a standard lottery. The player may choose from 1 to 15 numbers or 1 to 10 numbers depending on which Keno game is selected.',
        line2:
          'The prize amounts are displayed on the pay table depending on how many numbers match. Matching numbers are the ones chosen by the player and the one the game chooses and displays.',
        line3:
          'After each game you may choose to stay with the same numbers you previously chose (by clicking on PLAY button) or click on new numbers within the game.',
        title: 'Rules of the game',
      },
      superKeno: {
        line1:
          'On a Super Keno game, if the final number displayed from the game matches with a number chosen by the player the winnings are multiplied by 4.',
        title: 'Super Keno',
      },
    },
    gameControls: {
      button1Description: 'Click on the grid to choose the numbers.',
      button2Description: 'Select the number of rewards.',
      button3Label: 'Play',
      button3Description: 'Reveal the winning numbers.',
    },
  },
  paigow: {
    gameName: 'Pai Gow Poker',
    game: {
      introduction: {
        line1: 'Pai Gow Poker is a combination of Poker and an ancient Chinese game Pai Gow.',
        line2:
          'Player receives seven cards, which he splits into two hands, a five card hand and a two card hand. The object is to beat the hand on the table across from you.',
        title: 'Introduction',
      },
      howToPlay: {
        line1:
          'First, click a coin value and click on the Play area in the middle of the table. Place multiple coins if you wish. To remove a coin from the table, click on the Clear button.',
        line2:
          'You receive seven cards. Leave five of them in the Highest hand and select two that go to the 2nd Highest hand. To send a card to the 2nd Highest hand, just click on it. To send it back to the Highest hand, click on it again. The five-card hand (or the Highest) must always outrank the two-card hand (or the 2nd Highest). If you are undecided about which cards to choose you can also click the House Way button to let the computer select your hands in the best way for you.',
        line3:
          'The cards of the hand on the table across from you are revealed. If both the cards of hands on the table across from you are higher than yours, you lose. If one of your hands is higher than the hand on the table across from yours and the other is lower, the round results as a push and your wagered amount is returned. If both your hands are higher than the cards in the hand on the table across from you, you win 1:1 on your wager minus 5% bank commission. In case of an exact match between hands (called “a copy”) the tie goes to the banker.',
        title: 'How to Play',
      },
      houseway: {
        line1: "The 'front' refers to the two card hand and the 'back' refers to the five card hand.",
        houseway1Label: 'No pair',
        houseway1Line1: 'Place the highest card in the back and the next two highest cards in the front.',
        houseway2Label: 'One pair',
        houseway2Line1: 'Place the pair in back and the next two highest cards in the front.',
        houseway3Label: 'Two pair',
        houseway3Line1: 'Use the following groups to determine how to play a two pair:',
        houseway3Line2: '2 through 6: Low pair',
        houseway3Line3: '7 through 10: Medium pairs',
        houseway3Line4: 'Jack through king: High pairs',
        houseway3Line5: 'Low pair and low pair: Split unless holding a king or better, then play two pair in the back.',
        houseway3Line6:
          'Low pair and medium pair: Split unless holding a king or better, then play two pair in the back.',
        houseway3Line7: 'Low pair and high pair: Split unless holding an ace, then play two pair in the back.',
        houseway3Line8: 'Medium pair and medium pair: Split unless holding an ace, then play two pair in the back.',
        houseway3Line9: 'Medium pair and high pair: Always split.',
        houseway3Line10: 'High pair and high pair: Always split.',
        houseway3Line11: 'Pair of aces and any other pair: Always split.',
        houseway4Label: 'Three pair',
        houseway4Line1: 'Always play highest pair in front.',
        houseway5Label: 'Three of a kind',
        houseway5Line1:
          'Always play three of a kind in back unless they are aces, then play a pair of aces in back and one ace in front.',
        houseway6Label: 'Three of a kind twice',
        houseway6Line1: 'Always play highest pair in front.',
        houseway7Label: 'Straights, flushes, straight flushes, and royal flush',
        houseway7Line1:
          'With no pair: When choosing whether to play a straight, flush, or straight flush play the category which will allow the highest two cards in front.',
        houseway7Line2:
          'With 6th or 7th card: Play the lower straight or flush in the back to place the highest cards in front.',
        houseway7Line3:
          'With one pair: Play pair in the front only if a straight, flush, or straight can be played in the back.',
        houseway7Line4: 'With two pair: Use two pair rule.',
        houseway7Line5: 'With three pair: Use three pair rule.',
        houseway7Line6: 'With three of a kind: Play pair in front.',
        houseway7Line7: 'With full house: Use full house rule.',
        houseway8Label: 'Full house',
        houseway8Line1: 'Always split unless the pair is twos and you have an ace and a king to play in the front.',
        houseway9Label: 'Full house with three of a kind and two pairs',
        houseway9Line1: 'Play the highest pair in front.',
        houseway10Label: 'Full house with three of a kind twice',
        houseway10Line1: 'Always play the highest pair in front.',
        houseway11Label: 'Four of a kind',
        houseway11Line1: 'Play according to the rank of the four of a kind:',
        houseway11Line2: '2 through 6: Always keep together.',
        houseway11Line3: '7 through 10: Split unless a king or better can be played in front.',
        houseway11Line4: 'Jack through king: Split unless an ace can be played in front.',
        houseway11Line5: 'Aces: Always split.',
        houseway12Label: 'Four of a kind and a pair',
        houseway12Line1: 'Play pair in front.',
        houseway13Label: 'Four of a kind and three of a kind',
        houseway13Line1: 'Play pair in front from the three of a kind.',
        houseway14Label: 'Five aces',
        houseway14Line1:
          'Split aces and play three aces in back and two aces in front unless you have a pair of kings, then play five aces in back and kings in front.',
        title: 'House Way',
      },
      ranking: {
        highStraight: 'High straight (10, J, Q, K, ACE)',
        secondHighStraight: '2nd high straight (ACE, 2, 3, 4, 5)',
        title: 'Hand Ranking',
      },
    },
    gameControls: {
      button1Label: 'New game',
      button1Description: 'Begins a new game.',
      button2Label: 'Clear',
      button2Description: 'Clear the amount to play.',
      button3Label: 'Rebet',
      button3Description: 'Repeats your previous wager.',
      button4Label: 'Deal',
      button4Description: 'Instructs the dealer to deal the cards.',
      button5Label: 'Call',
      button5Description: 'Turns the dealer’s cards.',
      button6Label: 'House way',
      button6Description: 'Lets the computer to select your hands in the best way for you.',
    },
  },
  pokerThree: {
    gameName: 'Poker Three',
    game: {
      introduction: {
        line1:
          'Poker Three is a fast and fun poker-style game that requires little strategy for a player to be successful. The premise is simple: beat the dealer by making the best poker hand possible with a single draw of three cards!.',
        line2:
          "Poker 3 is actually two games in one. Both the player and the dealer get three cards. There are two types of wagers: Ante, where the player competes with the dealer, and 2's N Better, where the player wins if his hand has a pair or better.",
        line3: "A player may place coins on both the Ante and the 2's N Better areas or only on the Ante area only.",
        title: 'Introduction',
      },
      basicRules: {
        line1:
          'Poker 3 games are played with a standard 52-card deck. The cards are dealt in hands of three. Straights have a higher ranking than flushes, as there are fewer ways to make a three-card straight. Aces can be either high or low, depending on what you need, as in Poker.',
        title: 'Basic Rules',
      },
      anteAndAnteBonus: {
        line1:
          "Begin by placing coins on the 'ANTE' spot on the table. The Ante wager works as it does in conventional poker. This is the cost of playing the hand. You may also make the 2's N Better wager at this time (see 2's N Better below).",
        line2:
          "The dealer receives 3 cards face down and player is dealt three cards face up. You can then determine whether your cards are good enough to raise against the dealer (Call) or fold. If you fold, you simply lose the coins placed on the Ante. 2's N Better wagers, if applicable, are paid and the hand is over.",
        line3:
          "If you decide to challenge the dealer, click the 'Call' button, coins equal to the Ante will appear in the bottom circle on the table.",
        line4:
          "The dealer's cards are then revealed. The dealer must have at least Queen or better to qualify. Anything lower is an automatic win for you (if you have not folded). If the dealer's hand does not qualify, you are paid the 1:1 of the original Ante wager and an Ante Bonus, if applicable. The coins on the bottom circle of the table are a 'push' (you don't win or lose).",
        line5:
          "In Poker 3, you have the bonus of the initial coins, the Ante Bonus. This is paid if you have one of the three highest card combinations: straight flush, three of a kind, or straight. This bonus is paid even if the dealer doesn't qualify and even if the dealer's hand beats the players hand. See PAYOFFS for the payout for the Ante Bonus. The Ante Bonus is not paid if you fold.",
        line6:
          "If the dealer's hand does qualify the highest-ranking hand wins. If the dealer's hand ranks higher, the player loses both the Ante coins and the coins in the bottom circle on the table, but can still win the Ante Bonus with a straight or higher. If the dealer does qualify and those who can beat the dealer are paid as seen in the pay table below plus the Ante Bonus, if applicable. If both the dealer's hand and the player's hand have the same rank, the highest ranking card determines the winner (for example: a Pair of Kings beats a Pair of 10s).If the dealer's hand ties the player's hand both the Ante coins and the coins in the botom circle on the table, they are pushed (the wagers are returned to the play.",
        title: 'Ante and Ante Bonus',
      },
      twosNBetter: {
        line1:
          "The 2's N Better is an play type hoping that you will be dealt a pair or better. This optional play is made at the same time you place coins in the Ante. To Play this option place coins in the 2's N Better spot on the table. You are not playing against the dealer. You win if you are dealt a pair or better, regardless of what the dealer's hand contains. See PAYOFFS for the payout for a 2's N Better in the Winnings Table below:",
        line2:
          "When calculating a win, there are 4 things to consider: the 2's N Better result, the Ante Bonus result, the (Ante) result and the Bet amount.",
        payout: 'Payout',
        push: 'push',
        lose: 'lose',
        anteWins: 'Ante wins',
        antePushes: 'Ante pushes',
        anteLoses: 'Ante loses',
        entryWins: 'Bet wins',
        entryPushes: 'Bet pushes',
        entryLoses: 'Bet loses',
        twosNBetterResult: "2's N Better Result",
        twosNBetterResult1Label: 'Pair',
        twosNBetterResult2Label: 'Flush',
        twosNBetterResult3Label: 'Straight',
        twosNBetterResult4Label: '3 of a Kind',
        twosNBetterResult5Label: 'Straight Flush',
        anteBonusResult: 'Ante Bonus Result',
        anteBonusResult1Label: 'Straight',
        anteBonusResult2Label: '3 of a Kind',
        anteBonusResult3Label: 'Straight Flush',
        playResult: 'Bet Result',
        playResult1Label: 'Dealer does not qualify:',
        playResult2Label: 'Dealer qualifies, and player beats the dealer:',
        playResult3Label: 'Dealer qualifies, and player ties the dealer:',
        playResult4Label: 'Dealer qualifies, and dealer beats player:',
        title: "2's N Better",
      },
    },
    gameControls: {
      button1Label: 'New game',
      button1Description: 'Begins a new game.',
      button2Label: 'Clear',
      button2Description: 'Clear the amount to play.',
      button3Label: 'Rebet',
      button3Description: 'Repeats your previous wager.',
      button4Label: 'Deal',
      button4Description: 'Instructs the dealer to deal the cards.',
      button5Label: 'Call',
      button5Description: 'Increases the points by the amount of the original played points.',
      button6Label: 'Fold',
      button6Description: 'Folds your hand.',
    },
  },
  redDog: {
    gameName: 'Red Dog',
    game: {
      introduction: {
        line1:
          'Red Dog, sometimes known as Acey-Deucey or Between the Sheets, is a simple game to play. If you can remember the number seven and know how to subtract, you can play Red Dog as well as anyone in the world.',
        line2:
          "Two cards are drawn. The object of the game is to play your coins on the likelihood that the rank of a third card is going to fall between the first two. If it does fall between, you win. If it doesn't, you lose. The tighter the spread between the first two cards, the higher the potential payoff if the third card given ranks between the first two cards.",
        title: 'Introduction',
      },
      howToPlay: {
        line1:
          'Red Dog uses a single 52-card deck. All cards are ranked as in poker, the suit is irrelevant, and aces are always high.',
        line2:
          'Spread is the number of card values that lie between the two initial cards. The value of any card from 2 to 10 counts at face value, a jack counts as 11, a queen as 12, a king as 13, and an ace counts as 14.',
        line3: 'Begin by placing a coin on the table. Two cards are shown face up.',
        line4: 'If the two cards are consecutive then the hand is a push and the original coin played is returned.',
        line5:
          'If the two cards are equal a third card is given. If the third card matches the first two, the player receives 11:1 odds on the original wager. If the third card does not match the first two, the hand is a push and the played coins are returned.',
        line6:
          'If the two cards are neither consecutive nor equal the amount of the spread is displayed. The player then has the option to make an additional wager (Raise) equal to the original wager. A third card is then given. If the rank of the third card is between that of the first two cards the player wins (see PAYOFFS). If the third card matches or is ranked outside the first two cards the player loses.',
        title: 'How to Play',
      },
      payouts: {
        line1: 'Three matching cards pays 11:1.',
        line2: 'Bet wagers are always paid off at even money.',
        line3: 'The payout for a Raise is based on the spread as follows:',
        through: 'through',
        numbers: 'Numbers',
        payout: 'Payout',
        title: 'Payouts',
      },
    },
    gameControls: {
      button1Label: 'New game',
      button1Description: 'Begins a new game.',
      button2Label: 'Clear',
      button2Description: 'Clear the amount to play.',
      button3Label: 'Rebet',
      button3Description: 'Repeats your previous wager.',
      button4Label: 'Deal',
      button4Description: 'Instructs the dealer to deal the cards.',
      button5Label: 'Raise',
      button5Description: 'Increases the coin value by the amount of the original played coins.',
      button6Label: 'Call',
      button6Description: 'Turns the dealer’s cards.',
    },
  },
  roulette: {
    gameName: 'Roulette',
    game: {
      introduction: {
        line1:
          "The game of Roulette traces it's origin to England in the early 1700s in a game called Rolly Poly. It featured black and white spots with no numbers.",
        line2:
          "The French redesigned it in the late 1700s to include numbers. Original American wheels had 31 numbers plus a 0, 00, and an American Eagle for the third zero number (000). Today's European roulette wheels usually have only one zero while most American wheels have a zero and a double zero.",
        line3:
          'Both the American and European modern wheels have 36 numbers. Roulette is the most popular casino game in Europe.',
        line4:
          'Players, usually up to eight, play against the house represented by the croupier, who spins the roulette wheel and handles the funds and payouts.',
        line5: 'The American the wheel has 38 slots representing 36 numbers, a single zero and a double zero.',
        title: 'Introduction',
      },
      basicRules: {
        line1:
          'To play roulette, place your coins on numbers (any number including the zero and double) in the table layout. One you have completed plaing your coins click the spin button and the wheel will start to turn.  After a few seconds the ball on the roulette wheel will drop into a numbered spot and depending where you plaed your coin, the player could win.',
        line2:
          "There are many different location on the table to place coins. Each coin covers a different set of numbers and has a different distribution, as shown in the 'Winnings Table' section.",
        title: 'Basic Rules',
      },
      straightUp: {
        line1: 'You can place a coin on any number, including 0, by placing the coin on the center of a number.',
        title: 'Straight Up',
      },
      splitEntry: {
        line1: 'You can place a coin on two numbers by placing a coin on the line that divides the two numbers.',
        title: 'Split',
      },
      streetEntry: {
        line1:
          'You can place a coin on three numbers by placing a coin on the inner left boundary line of the roulette table that is next to the corresponding row of three numbers.',
        title: 'Street',
      },
      cornerEntry: {
        line1: 'You can place a coin four numbers by placing a coin at the corner where the four numbers meet.',
        title: 'Corner',
      },
      fiveEntry: {
        line1:
          'You can place a coin on the numbers 0, 00, 1, 2, and 3 by placing your coin on the inner left boundary line between 0 and 1.',
        title: 'Five',
      },
      lineEntry: {
        line1:
          'You can place a coin on two street rewards (i.e., the six different numbers in two rows of three numbers), by placing your coin on the inner left boundary line where the line dividing the two rows intersects it.',
        title: 'Line',
      },
      columnEntry: {
        line1:
          "There are three boxes labeled '2 to 1' at the bottom of a column of numbers. You can place coins for all of these numbers by placing your coin in one of these boxes. If any of the numbers in your column is hit you get paid 2:1; 0 and 00 are losing numbers.",
        title: 'Column',
      },
      dozenEntry: {
        line1:
          "You can place a coin on a group of twelve numbers by placing your coin in one of the three boxes marked '1st 12,' '2nd 12,' or '3rd 12.' If one of your 12 numbers is hit, you get paid 2:1; 0 and 00 are losing numbers.",
        title: 'Dozen',
      },
      moreEntries: {
        line1:
          'You can place a coin in one of the boxes on the left, long side of the table that covers half of the roulette table numbers (excluding the 0 and 00). Each box covers 18 numbers. You win even money (1 to 1) on all of these plays; 0 and 00 are losing numbers.',
        title: 'Red/Black, Even/Odd, Low/High',
      },
      winningNumberDisplay: {
        line1: 'The winning number display shows the results of the previous 7 plays.',
        title: 'Winning Number Display',
      },
      winningsTable: {
        line1: 'NOTE: You can place coins that have two, three or also four numbers.',
        line2: 'For example, using the 0, you can place : 1+0, 1+2+0,3+0, 1+2+3+0',
        chipCovers: 'Chip Covers',
        playType: 'Play Type',
        pays: 'Pays',
        number: 'number',
        numbers: 'numbers',
        win1Label: 'Straight Up',
        win2Label: 'Split Play',
        win3Label: 'Street Play',
        win4Label: 'Corner Play',
        win5Label: 'Four Play',
        win6Label: 'Line Play',
        win7Label: 'Dozen Play or Column Play',
        win8Label: 'Red/Black, Even/Odd or Low/High Play',
        win9Label: 'Five Play',
        title: 'Paytable',
      },
    },
    gameControls: {
      button1Label: 'New game',
      button1Description: 'Begins a new game.',
      button2Label: 'Clear',
      button2Description: 'Clear the coins.',
      button3Label: 'Rebet',
      button3Description: 'Repeats your previous coin placement.',
      button4Label: 'Spin',
      button4Description: 'Instructs the croupier to start the roulette wheel.',
      button5Label: 'Quick Spin',
      button5Description: 'System will choose a number randomly without the action of the wheel spinning.',
    },
  },
  sicBo: {
    gameName: 'Sic Bo',
    game: {
      introduction: {
        line1:
          'Sic Bo, sometimes known as Tai Sai, is an ancient Chinese game of chance. Three dice are tumbled in a small cage. The object of Sic Bo is to pick the numbers or combinations that will appear on the dice when they come to rest after tumbling. There are 50 different ways to place bets, so you have plenty of choices with a range of payouts - some as high as 180-to-1.',
        title: 'Introduction',
      },
      howToPlay: {
        line1:
          'Click on a chip of desired value. Then click on any bet area on the table to place your bet. (a list of the type of bets available is shown in the Sic Bo Bets section). Every click on the bet area adds one chip to the bet. Select another chip if you wish to increase the bet by some other amount. You can place several chips into several areas at the same time.',
        line2:
          'Click the Roll button. Dice are now rolled and you will see the game result. Click New Game if you wish to play again. Place your coins as described above and click Roll. You can also click Repeat placing the same coins in the same spots as in the previous round.',
        title: 'How to Play',
      },
      betTable: {
        type: 'Type',
        definition: 'Definition',
        bet1Label: 'Single Number',
        bet1Description:
          'The bottom row of the Sic Bo table features six numbered boxes. These are Single Number bets. If one, two or all three of the dice match the number bet, you win on or more times the win of that area.',
        bet2Label: 'Two Number Combinations',
        bet2Description:
          'This is a combination wager using one of the fifteen possible 2-number combinations. If the combination selected appears on two of the dice, you win. If the combination appears twice on the three dice (for example: you choose the combination 3-5, and the combination 3-5-5 comes up, you win twice).',
        bet3Label: 'Pair',
        bet3Description:
          'This is a wager on a specific number to appear on at least two of the three dice. If the number you select comes up on two of the dice or all three dice, you win.',
        bet4Label: 'Triple',
        bet4Description:
          'A bet that the dice roll will result in all three dice displaying the specific number selected (Specific Triple). You can also wager on all six possible Triples at once. This is called Any Triple.',
        bet5Label: 'Three Number Totals',
        bet5Description:
          'Three Number Totals are bets on the sum total of all three dice. Select a specific total from 4 to 17 (fourteen possible wagers). The less frequent the odds of the total selected, the higher the payout. A result of 3 or 18 is always a loss.',
        bet6Label: 'Small or Big',
        bet6Description:
          'You can wager on whether the sum total of the three dice rolled will be between 4-10 (Small) or 11-17 (Big). All Small and Big wagers lose if the result of the roll is a triple (for instance, three 5s).',
        title: 'Bet Types',
      },
      payoutTable: {
        line1:
          'The following bet types are based on the specific numbers rolled and are paid out according to the following odds:',
        line2:
          'The following bet types win based on the sum total of the three dice and payout according to the following odds:',
        type: 'Type',
        youWin: 'You Win',
        youWinIf: 'You Win if the Sum Equals',
        examples: 'Examples',
        exceptions: 'Exceptions',
        payout: 'Payout',
        and: 'and',
        or: 'or',
        to: 'to',
        anyOtherTotal: 'Any other total',
        payout1Label: 'Single Number',
        payout1Description:
          'The win amount changes based on the number of times the number you chose appears on the dice.',
        payout2Label: 'Two Number Combinations',
        payout2Description: 'The combination appears on at least two of the dice.',
        payout3Label: 'Pair',
        payout3Description: 'The number you choose appears on two of the dice or all three dice.',
        payout4Label: 'Any Triple',
        payout4Description: 'Any one of the numbers chosen appears on all three of dice.',
        payout5Label: 'Specific Triple',
        payout5Description: 'Three specific numbers chosen appear on the three dice.',
        payout6Label: 'Small Wager',
        payout7Label: 'Big Wager',
        payout8Label: 'Three Number Totals',
        title: 'Payouts',
      },
    },
    gameControls: {
      button1Label: 'New game',
      button1Description: 'Begins a new game.',
      button2Label: 'Clear',
      button2Description: 'Clear the amount to play.',
      button3Label: 'Rebet',
      button3Description: 'Repeats your previous coins and placement.',
      button4Label: 'Roll',
      button4Description: 'Initiates the cage with the dice to turn.',
    },
  },
  slotOneLineMagic: {
    gameName: 'Mix pics Slot Machine Style',
    game: {
      description: {
        line1:
          'Slot Machine Style games have different variations and designs. Mix Pics is very easy to play and only looks like a slot machine, however the player does not need to have specific icons in a row. The pay table shows which icons award a prize as not every symbol will pay a prize. These icons can show in any of the 5 display windows.',
        line2:
          'If the player gets the free spins icon, the payout of the prize icon during the free spins is multiplied as shown on the pay table.',
        title: 'Introduction',
      },
      howTo: {
        line1: 'Choose the coin value by clicking on one of the coins. Then click the Spin button.',
        autoOffLabel: 'Auto Off',
        autoOff: "Click to disable 'Auto On'.",
        autoOnLabel: 'Auto On',
        autoOn: 'Click on this button to repeat your previous games coin value, and spin the game automatically.',
        clearLabel: 'Reset',
        clear: 'Press this button to clear the amount in the Played indicator.',
        revealLabel: 'Spin',
        reveal: 'Click on this button to start the game.',
        paytableLabel: 'Paytable',
        title: 'How to Enter',
      },
      bonusRound: {
        line1:
          "Each game has it's own prize table and it will be indicated on the prize table if a game has a bonus game, jackpots or free reveals (free spins).",
        line2:
          'Special symbols (as seen in the Prize Table) will give you the possibility, depending on which game, to enter the Bonus round. The bonus round will load automatically! Every bonus game is different. In the bonus round you can increase your winnings without using any of your rewards.',
        title: 'Bonus Round',
      },
      prizeTable: {
        line1:
          'This button is located at the bottom left of the machine. Inside the actual prize table the payout amount for each prize and/or the number of the same icon is displayed.',
        title: 'Prize Table',
      },
    },
    gameControls: {
      button1Description: 'This play selector allows you to increase or decrease the coin.',
      button2Label: 'Spin',
      button2Description: "After you've set your coin value you wish to use, press the 'Spin' button to play!.",
      button3Label: 'Auto On',
      button3Description:
        "Press the 'Auto' button to repeat your coin value played on the previous game and spin automatically.",
      button4Label: 'Reset',
      button4Description: "Press the 'Reset' button to clear the coins in the coin value indicator.",
      description_1: 'Spin button. click to start the game',
      description_2:
        'Click to open the auto spin panel. There you can chose the number of auto spins you would like to program',
      description_3: 'Start auto spin play',
      description_4: 'Stop auto spin play',
      description_5: 'Pause auto spin play',
      description_6: 'Click to collect while in the bonus game',
    },
  },
  slots: {
    gameName: 'Slot machines',
    game: {
      bonusScatterWild: {
        line1:
          'Mỗi trò chơi có các giải thưởng riêng được quy định trên bảng giải thưởng nếu trò chơi đó có thêm một lượt chơi, trúng giải đặc biệt hoặc được quay miễn phí.',
        line2:
          'Các kí hiệu tán xạ-Scatter (như trong Bảng giải thưởng) cung cấp cho bạn khả năng, tùy thuộc vào trò chơi, để vào vòng Bonus. Với số lượng kí hiệu tán xạ bằng hoặc cao hơn 2, vòng Bonus sẽ tự động tải! Mỗi vòng Bonus có số tiền thưởng khác nhau. Trong vòng Bonus, bạn có thể tăng số tiền thắng mà không sử dụng bất kỳ tiền cược nào của bạn.',
        line3:
          'Biểu tượng Wild-W được sử dụng như một biểu tượng đặc biệt để hoàn thành một hàng. Ví dụ: Nếu dòng hiển thị các ký hiệu A, A, W, A, B sẽ giành chiến thắng như có 4 của ký hiệu A. (A, A, A, A, B) Biểu tượng Wild-W cũng có thể thắng trên một dòng nếu giống chính xác như các biểu tượng khác trên bảng giải thưởng, bắt đầu từ vị trí thứ nhất trong dòng. Một số trò chơi cho bạn số lượt quay miễn phí khác nhau tùy thuộc vào số lượng biểu tượng Wild-W mà bạn nhìn thấy sau khi có kết quả.',
        line4:
          'Ngoài ra còn có một số trò chơi cho phép bạn chọn đi đến vòng Bonus khi bạn giành chiến thắng trong trò chơi chính hoặc có được biểu tượng Wild-W. Lược quay trong các vòng Bonus là miễn phí và không yêu cầu mục nhập.',
        title: 'Vòng Bonus, Kí hiệu tán xạ và Wild',
      },
      description: {
        line1:
          'Quay Hũ có nhiều biến thể và thiết kế khác nhau. Chúng có thể có tối đa các dòng cược đầu vào từ 1, 1 đến 5 dòng, 1 đến 15 dòng, 1 đến 21 dòng và 1 đến 42 dòng có sẵn cho bạn. Loại 42 dòng thực sự là 21 dòng bắt đầu với một hàng các biểu tượng phù hợp từ trái sang phải và giống nhau từ phải sang trái.',
        line2:
          'Trong trò chơi 15, 21 hoặc 42 dòng có 5 cuộn với 3 biểu tượng hiển thị trên mỗi cuộn cho tổng số 15 biểu tượng hiển thị khi trò chơi hoàn thành một lược quay.',
        line3:
          'Các trò quay hủ đơn có 3 cuộn và chỉ có thể hiển thị 3 biểu tượng hoàn chỉnh khi lược quay được hoàn thành. Đường thắng là đường ở giữa tính từ trái sang phải.',
        line4: 'The 5 line game is also 3 reels however the win lines are 3 horizontal and 2 criss cross.',
        title: 'Các kiểu Quay Hủ',
      },
      howTo: {
        autoOffLabel: 'Tự Động Tắt',
        autoOff: "Click to disable 'Auto On'.",
        autoOnLabel: 'Tự Động Mở',
        autoOn: 'Nhấp vào nút này để lặp lại các lượt cược của trò chơi trước đó của bạn và tự động quay.',
        clearLabel: 'Rút tiền',
        clear: 'Nhấp vào nút này để xóa số tiền trong hiển thị.',
        linesXLabel: 'Đặt Một',
        linesX:
          'Click on the this button to increase the number of entry lines you wish to play. The more lines you use, the greater the chance of winning.',
        maxLabel: 'Đặt Tối Đa',
        max:
          'Nút này, nếu được bật sẽ chọn tất cả các dòng và trò chơi sẽ tự quay. (Trò chơi sẽ tự động chọn số dòng tối đa cho phép)',
        minusPlus: 'Bộ chọn phát này cho phép bạn tăng hoặc giảm giá trị tiền cược mà bạn mong muốn.',
        revealLabel: 'Quay',
        reveal: 'Nhấp vào nút này để khởi động trò chơi',
        paytableLabel: 'Bảng Chi Trả',
        title: 'Cách nhập và quay hủ',
      },
      jackpot: {
        line1:
          'Nếu 5 biểu tượng cùng giống một biểu tượng Jackpot (không bao gồm các biểu tượng Wild-W) xuất hiện trên một dòng thắng thì chỉ có jackpot được trả tiền. Giải thưởng cho dòng đó được hiển thị trong bảng giải thưởng không được trả cho dòng đó. Số lượng dòng tối đa và tiền cược tối đa được chọn bằng cách sử dụng công cụ chọn [-] và [+] sẽ trả số tiền jackpot được hiển thị. Nếu số lượng dòng tối đa và tiền cược tối đa ít hơn  bằng cách sử dụng giá trị bộ chọn [-] và [+] sẽ trả một giải thưởng tỷ lệ độc đắc.',
        line2:
          'Nếu có 2 hoặc nhiều dòng với 5 biểu tượng chính xác thì chỉ có một giải độc đắc Jackpot được thưởng cho mỗi lần chơi. Tuy nhiên, giải thưởng cho dòng được hiển thị trong bảng thanh toán sẽ được trao trên các dòng chiến thắng bổ sung.',
        title: 'Rút tiền Jackpot',
      },
      payoutLines: {
        line1:
          'Quay Hũ có nhiều biến thể và thiết kế khác nhau. Chúng có thể có tối đa các dòng cược đầu vào từ 1, 1 đến 5 dòng, 1 đến 15 dòng, 1 đến 21 dòng và 1 đến 42 dòng có sẵn cho bạn. Loại 42 dòng thực sự là 21 dòng bắt đầu với một hàng các biểu tượng phù hợp từ trái sang phải và giống nhau từ phải sang trái.',
        line2:
          'Đây là KHÔNG DI ĐỘNG H5.',
        title: 'Rút tiền',
      },
      prizeTable: {
        line1:
          'Nút này nằm bên trong trò chơi để xem các giải thưởng. Ở phía bên trái của bảng giải thưởng có các hàng hiển thị số lượng HITS bạn cần phải đạt được để nhận giải thưởng được chỉ định cho từng biểu tượng cụ thể (hiển thị ở đầu bảng giải thưởng). Trong bảng giải thưởng có các cột hiển thị giải thưởng của bạn cho các lần trúng biểu tượng. Những giải thưởng này thay đổi dựa trên số lượng mục bạn đã chọn bằng cách lực chọn [-] hoặc [+].',
        line2:
          'Cách duy nhất để có được HITS trong game là có một chuỗi biểu tượng giống nhau mà không bị gián đoạn bắt đầu từ phía bên trái sang phải. Trình tự sẽ được đánh dấu bằng các đường màu, các kí hiệu hoạt hình hoặc các hình vuông màu để phác thảo các biểu tượng chiến thắng. Có nhiều chỉ số khác nhau cho biết số tiền bạn đã thắng. Vùng thông tin chính nằm ở trung tâm phía dưới cùng của trò chơi.',
        title: 'Bảng giải thưởng',
      },
    },
    gameControls: {
      button1Description: 'Bộ chọn phát này cho phép bạn tăng hoặc giảm giá trị tiền cược mà bạn mong muốn.',
      button2Label: 'Đặt Một',
      button2Description: "Press the 'Line X' button one or more times to set the number of lines you wish to play on.",
      button3Label: 'Quay',
      button3Description: 'Nhấp vào nút này để khởi động trò chơi',
      button4Label: 'Đặt Tối Đa',
      button4Description:
        'Nút này, nếu được bật sẽ chọn tất cả các dòng và trò chơi sẽ tự quay. (Trò chơi sẽ tự động chọn số dòng tối đa cho phép)',
      button5Label: 'Tự Động Mở',
      button5Description: 'Nhấp vào nút này để lặp lại các lượt cược của trò chơi trước đó của bạn và tự động quay.',
      button6Label: 'Rút tiền',
      button6Description: 'Nhấp vào nút này để xóa số tiền trong hiển thị.',
      buttonH5PlayValues: 'Mở Bảng điều khiển để chọn số lượng dòng và tiền bạn muốn chơi.',
      buttonH5TwoWay:
        "Nút 'Two Way' có sẵn trong các khe đặc biệt. Nhấn vào nó để bật / tắt đặt cược trong các dòng từ phải sang trái.",
      buttonH5Play: 'Nhấn để bắt đầu / liên tục trình tự quay tự động hoặc quay tự do.',
      buttonH5Stop: 'Nhấn để dừng trình tự quay tự động.',
      buttonH5Pause: 'Nhấn để tạm dừng trình tự quay tự động hoặc quay tự do.',
    },
  },
  stud: {
    gameName: 'Caribbean Stud Poker',
    game: {
      introduction: {
        line1:
          "Caribbean Stud Poker is a variation of five-card stud poker which may have the added excitement of a progressive jackpot. You play the hand you're given: there is no draw in Caribbean Stud so you don't have to decide which cards to hold. The player's hand is pitted against the hand on the table across from you with the best poker hand winning.",
        title: 'Introduction',
      },
      howToPlay: {
        line1:
          'Begin by placing a coin/coins in the designated space. Players are then given five cards face up while the hand on the table across from you is given one card face up.',
        line2: 'At this point the player must select from two options available:',
        title: 'How to Play',
      },
      surrender: {
        line1: 'If Surrender is selected, all coins are forfeited and the hand is over.',
        title: 'Surrender',
      },
      raise: {
        line1:
          'An additional amount of twice the amount of coins which were wagered at the begining of the game are placed in the Raise spot on the table. The hand on the table across from you receives four more cards face up.',
        line2:
          'The hand on the table across from you must then qualify, by holding at least an Ace and a King or better. If the hand on the table across from you does not qualify, the player is paid even money (1:1) on the initial wager and the Raise wager is returned to the player as a push.',
        line3:
          "If the hand on the table across from you does qualify, where the hand on the table across from you and the player's hand are compared and the best poker hand wins.",
        title: 'Raise',
      },
      showdownTable: {
        if: 'If...',
        then: 'then...',
        showdown1Label: 'The Dealer wins',
        showdown1Then: 'The player loses both the initial wager and Raise played.',
        showdown2Label: 'Table Hand and Player Tie',
        showdown2Then: 'Initial and Raise best are pushed.',
        showdown3Label: 'The Player wins',
        showdown3Then:
          'The Player is paid  (1:1) on the initial play and the Raise is paid according to the Raise Pay Table below.',
        title: 'Showdown',
      },
      raisePayTable: {
        line1: 'If the Player wins, the payout for the Raise is calculated in this way:',
        playerHand: 'Player Hand',
        payout: 'Payout',
        raisePay1Label: 'Royal Flush',
        raisePay2Label: 'Straight Flush',
        raisePay3Label: '4 of a Kind',
        raisePay4Label: 'Full House',
        raisePay5Label: 'Flush',
        raisePay6Label: 'Straight',
        raisePay7Label: '3 of a Kind',
        raisePay8Label: '2 Pair',
        raisePay9Label: 'Pair',
        raisePay10Label: 'Ace-King',
        raisePay11Label: 'Other',
        raisePay11Payout: 'Push',
        title: 'Raise Pay Table',
      },
    },
    gameControls: {
      button1Label: 'New game',
      button1Description: 'Begins a new game.',
      button2Label: 'Clear',
      button2Description: 'Clear the amount to play.',
      button3Label: 'Rebet',
      button3Description: 'Repeats your previous wager.',
      button4Label: 'Deal',
      button4Description: 'Instructs the dealer to deal the cards.',
      button5Label: 'Surrender',
      button5Description: 'Folds your hand and forfeits initial coins played.',
      button6Label: 'Raise',
      button6Description: 'Adds an amount equal to twice your initial coins placed on the table.',
    },
  },
  videoPoker: {
    gameName: 'Video Poker',
    game: {
      rulesOfTheGame: {
        line1:
          'The single line/row game uses a standard 52-card deck, which is shuffled after each hand. The multiline game uses one 52-card deck per line/row.',
        line2:
          'The player is trying to have the best combination of cards which will pay as shown in the prize table which can be different for each unique game.',
        line3:
          'For specific details regarding each winning combination, please check the Prize Table which shows on the screen.',
        deuceWild: 'Deuce & Wild Video Poker',
        deuceWildRule:
          'is one of the most fun versions of Poker. It is played similar to a Poker game, but the Deuces (the cards with the number “2”) are Wild, which means that the Deuce card can become the missing card in order to get the highest hand possible. With the “2-Wild” card, you can transform any pair into Three of a Kind, or complete a Straight.',
        changePrizeColumn: 'Change prize column',
        draw: 'Draw',
        title: 'Rules of the game',
      },
      howToPlay: {
        line1:
          'Choose the amount you want to play by clicking on the arrows at the bottom right of the machine. [-] or [+].',
        line2:
          "Click on the 'Change Prize Column' button to increase your play amount and change the prize column to be used in the prize table.",
        line3:
          "After choosing one of the prize columns in the prize table, click on the 'Deal' button, you will receive 5 cards face up.",
        line4:
          "After the first draw, click on the cards, if any, that you wish to keep. 'HOLD' will be displayed on the cards you wish to keep.",
        line5: "Click on 'Draw' and the cards will be replaced. Only the cards with hold will not be changed.",
        line6:
          'Your cards are then compared to the table of winning combinations in the Prize table of the game you are playing. If the value of your hand is lower than the minimum winning combination, you lose. If your card combination is any of the ones shown in the prize table you win the prize!!!. In the multiline you can win on the combination of each line/row.',
        title: 'How to Play and Reveal',
      },
      doublePlayoffRound: {
        line1:
          "When you have a winning hand you are offered a double playoff round in which you are given the opportunity to 'Double' your winnings. If you do not wish to participate in the double playoff round, simply click on 'Collect'.",
        double: 'Double',
        collect: 'Collect',
        title: 'Double Playoff Round',
      },
      howToPlayDoublePlayoffRound: {
        line1: "Click on the 'Double' button.",
        line2: 'The cards will be shown face down, and then one card on the left side will turn face up.',
        line3: 'Click on one of the four cards that remain face down.',
        line4:
          'If the card that you choose is of a higher value than the card turned over by the computer - you win, and the double playoff round continues, if you wish.',
        line5:
          "Please Note: If you do not wish to continue participating in the double playoff round, simply click on 'Collect'.",
        line6: "You may once again click on the 'Double' button and the same cycle will occur.",
        line7:
          'If the card that you choose is of an equal value to the card turned over by the computer it is a Tie. The double playoff round continues, if you wish.',
        line8:
          'If the card that you choose is of a lower value than the card turned over by the computer, you lose your play and the double playoff round is over.',
        line9: 'Please Note: The double playoff round automatically ends after a total of five rounds.',
        title: 'How to play the Double Playoff Round',
      },
      paytable: {
        line1: 'The pay table will dynamically change the prize amounts depending on your intial played amount.',
        title: 'Paytable',
      },
      winningTable: {
        winning1Label: '2 Pair',
        winning1Description: '2 sets of pairs of the same card denomination.',
        winning2Label: '3 of a Kind',
        winning2Description: '3 cards of the same denomination.',
        winning3Label: '4 aces or Eights',
        winning3Description: 'Four cards of Aces or Eights.',
        winning4Label: '4 Deuces',
        winning4Description: 'Four cards of Deuces.',
        winning5Label: '4 Sevens',
        winning5Description: 'Four cards of Sevens.',
        winning6Label: '4 of a Kind',
        winning6Description: '4 cards of the same denomination.',
        winning7Label: '5 of a Kind',
        winning7Description: '4 cards of the same denomination and the Wild or Joker card.',
        winning8Label: '10s or Better',
        winning8Description:
          'One Pair pays off only if the cards in the pair are Tens, Jacks, Queens, Kings, or Aces. Lower pairs do not pay off.',
        winning9Label: 'Flush',
        winning9Description: '5 non-consecutive denomination cards, all of the same suit.',
        winning10Label: 'Full House',
        winning10Description:
          'A set of 3 cards of the same denomination, plus a set of 2 cards of the same denomination.',
        winning11Label: 'HI Royal Flush',
        winning11Description:
          '5 consecutive denomination cards, all of the same suit, starting from 10 and ending with an ace.',
        winning12Label: 'Jacks or Better',
        winning12Description:
          'One Pair pays off only if the cards in the pair are Jacks, Queens, Kings, or Aces. Lower pairs do not pay off.',
        winning13Label: 'Joker Royal Flush',
        winning13Description:
          '5 consecutive denomination cards, all of the same suit, starting from 10 and ending with an ace, completed by the Joker card.',
        winning14Label: 'LO Royal Flush',
        winning14Description:
          '5 consecutive denomination cards, all of the same suit, starting from 2 and ending with 6.',
        winning15Label: 'Royal Flush',
        winning15Description:
          '5 consecutive denomination cards, all of the same suit, starting from 10 and ending with an ace without the Wild card or Joker.',
        winning16Label: 'Straight',
        winning16Description: '5 consecutive denomination cards, not of the same suit.',
        winning17Label: 'Straight Flush',
        winning17Description: '5 consecutive denomination cards, all of the same suit.',
        winning18Label: 'Wild Royal Flush',
        winning18Description:
          '5 consecutive denomination cards, all of the same suit, starting from 10 and ending with an ace, completed by the Wild card.',
        title: 'Winning Combinations as Shown in the prize Tables for each Different Video Poker Game',
      },
    },
    gameControls: {
      buttonControl: 'Button/Control',
      description: 'Description',
      button1Description: 'This play selector allows you to increase or decrease the coin value of your play.',
      button2Label: 'Change prize column',
      button2Description:
        'Click one or more times to select from one of the 5 prize table columns. Each time you click, the coin value played will be multiplied by 1,2,3,4,5.',
      button3Label: 'Deal',
      button3Description: "After you've cosen your coin value and prize table column,click to start the game!.",
      button4Label: 'Double',
      button4Description: "Press 'Double' to try to win double of the win you just recieved.",
      button5Label: 'Collect',
      button5Description: "Press 'Collect' to collect your winnings.",
      description_1: 'Play button. Press to start a game',
      description_2: 'Draw button. After holding your chose cards, press to reveal new ones',
      description_3: 'Increase coin value',
      description_4: 'Decrease coin value',
      description_5: 'Double button. Click to enter the double playoff round',
      description_6: 'Collect button. Click to collect your winnings, and pass your chance to double your winnings',
      description_7: 'Clear button. Click to clear the game in order to start a new one',
      description_8: 'Show prize table. This button is only available in Multi, 18 and 50 games',
    },
  },
  zipTabs: {
    gameName: 'Zip Tabs',
    game: {
      introduction: {
        line1:
          'Easy, fun and colorful. This game has to be one of our easiest to play. The objective of these games is to get three of the same symbol in one row of the card.',
        title: 'Introduction',
      },
      howToPlay: {
        line1:
          'Select the Cost of the Card amount using the plus or minus arrow buttons. Note that the pay table will change accordingly.',
        line2: 'Click on Buy Card.',
        line3: 'Click on Peel All, or click Peel on each row individually.',
        line4: 'To play again, just click on Buy Card again.',
        line5: 'The Cards Cost amount remains the same until you change it.',
        title: 'How to Play',
      },
    },
    gameControls: {
      button1Label: 'Peel all',
      button1Description: "Press the 'Peel all' button to open the tabs.",
      button2Label: 'Buy card',
      button2Description: "After you've set your coin value, press the 'Buy Card' button to start the game!.",
      button3Label: 'Selector',
      button3Description: 'This play increment selector allows you to set the coin value of your play.',
    },
  },
  generalInfo: {
    introduction: {
      title: 'Introduction',
      line1:
        'Our games are designed to be easy to learn and play. Just a few simple concepts will have you up and running in no time!',
      line2:
        'This Help Page you are reading now will show you how to play our games, how to customize the games to suit your personal preferences, and how to get information about your play history. These help pages covers only things that are included in all games, and does not go into detail on any specific game.',
    },
    gameInterface: {
      title: 'The Game Interface',
      line1: 'Every game contains the following areas and/or buttons:',
      balance: {
        title: 'Balance and Played Amount Displays',
        line1:
          'At the bottom left are two displays which show your current balance available to use - the money that is available to play with. Played is the amount of money you have just used in one game or are about to use in the current game.',
      },
      message: {
        title: 'Message Display',
        line1:
          "At the center of the window at the bottom are displayed game messages, such as if you've won and how much you've won etc.",
      },
      options: {
        title: 'Options Button',
        line1:
          'Clicking on the Options button in the lower right area of the screen will show you the Game Options window. You may customize the game in the following ways:',
        speed: {
          title: 'Game Speed',
          line1:
            'The games have a speed setting between 1 and 3. The default setting is 2. Faster speeds cause the animations to speed up so that you may play quicker.',
        },
        color: {
          title: 'Table Color',
          line1: 'Only applicable to the table games, this option allows you to choose the look of your game.',
        },
        sounds: {
          title: 'Sounds',
          line1: 'You may turn voices and sound effects separately.',
        },
        soundFx: {
          audio: 'Audio',
          title: 'Sound FX',
          line1: 'You may toggle sound effects.',
        },
        voices: {
          title: 'Voices',
          line1: 'You may toggle voices.',
        },
        yes: 'Yes',
        no: 'No',
        options: 'options',
        settings: 'settings',
      },
      history: {
        title: 'History Button',
        line1:
          'Clicking this button will cause a popup window to be displayed that contains a comprehensive account of all your plays and results for the current game.',
      },
      help: {
        title: 'Help Button',
        line1:
          'Clicking on the Help button causes a pop-up window to be displayed that contains the Help for the current game which is loaded.',
      },
    },
    playSequence: {
      title: 'The Game play Sequence',
      line1:
        'Table games are played by opening games, choosing table limits, placing chips on the table, playing and observing the result game play choices.',
      line2:
        'Slot/Keno games are played by opening and choosing the coin value, numbers of lines, clicking reveal and observing the result of your game play choices.',
    },
  },
}